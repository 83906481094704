import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, IconButton, TextField } from "@mui/material";
import { useState } from "react";

interface IncrementDecrementProps {
  value: number;
  onChange: (newValue: number) => void;
  disableDecrement: boolean;
}

const IncrementDecrement: React.FC<IncrementDecrementProps> = ({
  value,
  onChange,
  disableDecrement,
}) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());

  const handleIncrement = () => {
    const newValue = value + 1;
    onChange(newValue);
    setInputValue(newValue.toString());
  };

  const handleDecrement = () => {
    const newValue = value - 1;
    onChange(newValue);
    setInputValue(newValue.toString());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue === "" || inputValue === "-") {
      setInputValue(inputValue);
      return;
    }

    const parsedValue = parseInt(inputValue, 10);

    if (!isNaN(parsedValue)) {
      onChange(parsedValue);
      setInputValue(inputValue);
    }
  };

  const handleBlur = () => {
    const parsedValue = parseInt(inputValue, 10);
    if (isNaN(parsedValue)) {
      setInputValue(value.toString());
    } else {
      setInputValue(parsedValue.toString());
    }
  };

  return (
    <Box
      gap={1}
      display="flex"
      alignItems="center"
    >
      <IconButton
        size="small"
        onClick={handleDecrement}
        disabled={disableDecrement}
        data-testid="remove_credit"
      >
        <RemoveIcon />
      </IconButton>

      <TextField
        fullWidth
        sx={{ width: "100%" }}
        value={inputValue}
        onBlur={handleBlur}
        onChange={handleInputChange}
        inputProps={{ style: { textAlign: "center" } }}
        data-testid="input_credit"
      />

      <IconButton
        size="small"
        onClick={handleIncrement}
        data-testid="add_credit"
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default IncrementDecrement;
