import client from "@/api/client";
import useProductApi from "@/api/useProductApi";
import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import {
  CRMData,
  ProductsData,
} from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import {
  CrmUpsellingList,
  defaultButtonCup,
  defaultFormMeetingDone,
  INButtonCup
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import useAuth from '@/hooks/useAuth';
import { Theme, useTheme } from "@mui/material";
import { GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface CrmState {
  theme: Theme;
  queryResult: UseQueryResult<CRMData, unknown>;
  KEY_QUERY: (string | number | { [p: string]: string })[];
  productsQueryResult: UseQueryResult<ProductsData, unknown>;
  rowState: {
    rows: GridRowsProp;
    setRows: Dispatch<SetStateAction<GridRowsProp>>;
  };
  paginationState: {
    page: string | number;
    setPage: Dispatch<SetStateAction<string | number>>;
    perPage: number;
  };
  sortState: {
    sortModel: GridSortModel;
    onSortModelChange: (model: GridSortModel) => void;
  };
  filtersState: {
    filters: URLSearchParams;
    setFilters: ReturnType<typeof useSearchParams>[1];
    filterData: { [p: string]: string };
  };
  commentsState: {
    comments: string;
    setComments: Dispatch<SetStateAction<string>>;
  };
  formMeetingState: {
    formMeetingDone: IFormMeetingDone;
    setFormMeetingDone: Dispatch<SetStateAction<IFormMeetingDone>>;
  };
  buttonCupState: {
    buttonCup: INButtonCup;
    setButtonCup: Dispatch<SetStateAction<INButtonCup>>;
  };
  crmUpsellingState: {
    crmUpselling: any;
    setCrmUpselling: Dispatch<SetStateAction<any>>;
  };
  rowSelectedState: {
    rowSelected: any;
    setRowSelected: Dispatch<SetStateAction<any>>;
  };

  newStartingMonthState: {
    newStartingMonth: Array<string> | undefined;
    setNewStartingMonth: Dispatch<SetStateAction<Array<string> | undefined>>;
  };
  meetingComponentState: {
    status: string;
    setStatus: Dispatch<SetStateAction<string>>;
    eventId: string;
    setEventId: Dispatch<SetStateAction<string>>;
    formUser: any;
    setFormUser: Dispatch<SetStateAction<any>>;
    openCalendarEvents: boolean;
    setOpenCalendarEvents: Dispatch<SetStateAction<boolean>>;
  };
  confirmDialogDataState: {
    confirmDialogData: boolean;
    setConfirmDialogData: Dispatch<SetStateAction<boolean>>;
  };
  englishLevelState: {
    EnglishLevel: any;
    setEnglishLevel: Dispatch<SetStateAction<any>>;
  };
  codeValuesState: {
    codeValues: any;
    setCodeValues: Dispatch<SetStateAction<any>>;
  };
  onGenerateEmailState: {
    onGenerateEmail: any;
    setOnGenerateEmail: Dispatch<SetStateAction<any>>;
  };
}

const CrmContext = React.createContext<CrmState | undefined>(undefined);

export const CrmContextProvider = ({ children }: React.PropsWithChildren) => {
  const theme = useTheme();
  const productsQueryResult = useProductApi().callGetProducts();
  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());
  const { perPage } = useScreen();
  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });
  const { page, setPage } = usePagination({ setFilters, filters });
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [comments, setComments] = useState<string>("");
  const [crmUpselling, setCrmUpselling] = useState<any>();
  const { userAdmin } = useAuth();
  const [formMeetingDone, setFormMeetingDone] = useState<IFormMeetingDone>(
    defaultFormMeetingDone
  );
  const [rowSelected, setRowSelected] = useState<any>({});
  const [newStartingMonth, setNewStartingMonth] = useState<
    Array<string> | undefined
  >();
  const [buttonCup, setButtonCup] = useState<INButtonCup>(defaultButtonCup);
  const [status, setStatus] = useState<string>("");
  const [eventId, setEventId] = useState<string>("");
  const [formUser, setFormUser] = useState<any>({});
  const [openCalendarEvents, setOpenCalendarEvents] = useState<boolean>(false);
  const sorts = filterData?.sorts;
  delete filterData?.page;
  delete filterData?.sorts;
  const KEY_QUERY = ["upselling", "crm", page, filterData, perPage, sorts];

  const queryResult = useQuery(
    KEY_QUERY,
    () =>
      client.crm_upselling.list({
        page,
        sorts,
        filters: filterData,
        paginate: perPage,
      }) as Promise<CrmUpsellingList>,
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  useEffect(() => {
    if (!queryResult) return;
    setRows(queryResult.data?.crmUpselling?.data || []);

    setCrmUpselling(queryResult.data?.crmUpselling || {});

    setFormMeetingDone((p: IFormMeetingDone) => ({
      ...p,
      interviewer: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
    }));
    
    setNewStartingMonth(queryResult.data?.newStartingMonth);
  }, [queryResult.data, queryResult.isLoading]);

  const contextValue: CrmState = {
    theme,
    queryResult,
    KEY_QUERY,
    productsQueryResult,
    rowState: {
      rows,
      setRows,
    },
    paginationState: {
      page,
      setPage,
      perPage,
    },
    sortState: {
      sortModel,
      onSortModelChange,
    },
    filtersState: {
      filters,
      setFilters,
      filterData,
    },
    commentsState: {
      comments,
      setComments,
    },
    formMeetingState: {
      formMeetingDone,
      setFormMeetingDone,
    },
    buttonCupState: {
      buttonCup,
      setButtonCup,
    },
    crmUpsellingState: {
      crmUpselling,
      setCrmUpselling,
    },
    newStartingMonthState: {
      newStartingMonth,
      setNewStartingMonth,
    },
    meetingComponentState: {
      status,
      setStatus,
      eventId,
      setEventId,
      formUser,
      setFormUser,
      openCalendarEvents,
      setOpenCalendarEvents,
    },
    confirmDialogDataState: {
      confirmDialogData: false,
      setConfirmDialogData: () => { },
    },
    englishLevelState: {
      EnglishLevel: "",
      setEnglishLevel: () => { },
    },
    codeValuesState: {
      codeValues: {},
      setCodeValues: () => { },
    },
    onGenerateEmailState: {
      onGenerateEmail: () => { },
      setOnGenerateEmail: () => { },
    },
    rowSelectedState: {
      rowSelected,
      setRowSelected,
    },
  };

  return (
    <CrmContext.Provider value={contextValue}>{children}</CrmContext.Provider>
  );
};

export function useCrmContext() {
  const context = React.useContext(CrmContext);

  if (!context)
    throw new Error("useCrmContext must be used within an CrmContextProvider");

  return context;
}
