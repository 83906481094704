import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAuth from "@/hooks/useAuth";
import { IAction } from "@/pages/AdsPage";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, MenuItem, TextField } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { fUpper } from "../useHelpers";

export interface IForm {
  utm_ads?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_campaign?: string;
}

interface IQuery {
  ads: boolean;
  query: IForm;
  token?: string;
  influencer: string;
  tracking_name: string;
  associated_link: string;
}

const FORM_URL = "https://edusogno.com/form/edusogno-inglese";
const LANDING_URL = "https://edusogno.com/acceleratore-di-inglese/ads/";
const LANDING_URL_ADMISSION = "https://edusogno.com/edusogno-admissions/ads/";

const defaultInQuery: IQuery = {
  ads: false,
  query: {},
  influencer: "",
  tracking_name: "",
  associated_link: FORM_URL,
};

interface IProps {
  open: boolean;
  params: any;
  action: IAction;
  onClose: () => void;
  oldForm?: any;
  onCreateLink: any;
}

const CreateLinkDialog = ({
  open,
  action,
  params,
  onClose,
  oldForm,
  onCreateLink,
}: IProps) => {
  const { isAdmission } = useAuth();

  const [form, setForm] = useState<IQuery>(defaultInQuery);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await onCreateLink(form);
    setIsLoading(false);
    setForm(defaultInQuery);
  };

  useEffect(() => {
    if (oldForm?.token) {
      setForm({
        ads: oldForm?.ads ?? false,
        token: oldForm?.token ?? "",
        query: {
          utm_ads: oldForm?.utm_ads ?? "",
          utm_source: oldForm?.utm_source ?? "",
          utm_medium: oldForm?.utm_medium ?? "",
          utm_content: oldForm?.utm_content ?? "",
          utm_campaign: oldForm?.utm_campaign ?? "",
        },
        influencer: oldForm?.influencer ?? "",
        tracking_name: oldForm?.tracking_name ?? "",
        associated_link: oldForm?.associated_link ?? "",
      });
    }
  }, [open, oldForm, oldForm?.token]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={addIcon}
          className="icon"
        />
        {["create", "clone"].includes(action || "") ? "Create a new" : "Edit"}{" "}
        link {action == "clone" ? "from clone" : ""}
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="flex flex-col p-8 gap-4"
        >
          <Box className="flex flex-col gap-4">
            <TextField
              select
              name="ads"
              label="Type"
              value={Number(form.ads)}
              onChange={(e) =>
                setForm((p: IQuery) => ({
                  ...p,
                  ads: Boolean(e.target.value),
                  associated_link: Boolean(e.target.value)
                    ? isAdmission
                      ? LANDING_URL_ADMISSION
                      : LANDING_URL
                    : FORM_URL,
                }))
              }
            >
              {(
                Object.entries({ form: false, landing: true }) as [
                  string,
                  boolean
                ][]
              ).map(([key, value], i: number) => (
                <MenuItem
                  key={key + i}
                  value={Number(value)}
                >
                  {fUpper(key)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              name="tracking_name"
              color="primary"
              label="Tracking name"
              value={form.tracking_name ?? ""}
              onChange={(e) =>
                setForm((p: IQuery) => ({
                  ...p,
                  tracking_name: e.target.value,
                }))
              }
              placeholder="edusogno-ig"
            />

            {form.ads && (
              <TextField
                name="influencer"
                color="primary"
                label="Influencer"
                value={form.influencer ?? ""}
                onChange={(e) =>
                  setForm((p: IQuery) => ({
                    ...p,
                    influencer: e.target.value,
                  }))
                }
                placeholder="Name..."
              />
            )}

            {Boolean(params) &&
              (Object.entries(params || {}) as [string, string[]][]).map(
                ([key, value], i: number) => (
                  <Autocomplete
                    freeSolo
                    key={key + i}
                    color="primary"
                    value={form?.query?.[key] ?? ""}
                    options={value as Array<string>}
                    onInputChange={(_, newVal) =>
                      setForm((p: IQuery) => ({
                        ...p,
                        query: { ...p.query, [key]: newVal },
                      }))
                    }
                    inputValue={form?.query?.[key]}
                    renderInput={(params) => (
                      <TextField
                        required
                        name={key}
                        label={fUpper(key)}
                        {...params}
                      />
                    )}
                  />
                )
              )}
          </Box>

          <Box className="flex justify-end">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isLoading}
              disabled={isLoading}
            >
              <img
                src={saveIcon}
                className="icon"
              />
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default CreateLinkDialog;
