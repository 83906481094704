export interface PaginatorInfo<T> {
  current_page: number;
  data: T[];
  eventStatus: Array<string>;
  first_page_url: string;
  from: number;
  last_page: number;
  // last_page_url: string;
  // links: any[];
  // next_page_url: string | null;
  // path: string;
  per_page: number;
  // prev_page_url: string | null;
  to: number;
  total: number;
}

export interface MappedPaginatorInfo {
  currentPage: number;
  firstPageUrl: string;
  from: number;
  lastPage: number;
  lastPageUrl: string;
  links: any[];
  nextPageUrl: string | null;
  path: string;
  perPage: number;
  prevPageUrl: string | null;
  to: number;
  total: number;
  hasMorePages: boolean;
}

export interface PageParams {
  per_page?: number;
  page?: number;
}

export interface EventQueryParam extends PageParams {
  // search?: string
  // type?: string
  // english_lvl?: string
  // level?: string
  // tutor_token?: string
  // start_date?: string
  // end_date?: string
  filters?: {};
  weekStart?: string | Date;
  weekEnd?: string | Date;
  page?: number;
  per_page?: number;
}
export interface EventStatisticsQueryParam extends PageParams {
  start_date?: string;
  end_date?: string;
}

export interface Materials {
  for: string | null;
  name: string;
  type: string;
  token: string;
  material_id: number;
  pivot: {
    event_id: number;
    material_id: number;
  };
}

interface Exercises {
  token: string;
  name: string;
  type: string;
  pivot: {
    event_id: number;
    exercise_token: string;
  };
}

interface Tutor {
  fullName: string;
  token: string;
}

export interface UserEvents {
  access_date: string | null;
  first_name: string;
  last_name: string;
  email: string;
  is_logged_in: boolean;
  token: string;
  pivot: {
    event_id: number;
    user_token: string;
  };
}
export interface EventItem {
  id: number;
  lang: string;
  data: string;
  ora: string;
  ora_fine: string;
  titolo: string;
  tutor_token: string;
  tutor_access: string;
  link_meet: string;
  exercise_token: string | null;
  type: EVENT_TYPES;
  english_lvl: string;
  descrizione: string;
  descrizione_argomento: string | null;
  link_video: string | null;
  nome_visualizzato: string | null;
  livello: string | null;
  materials: Materials[];
  tutors: Tutor;
  user_events: UserEvents[];
  exercises: Exercises[];
  isLoadingVideoRecording?: boolean;
  errorVideoRecording?: boolean;
  technical_problem: number;
  status: string;
  slot_available: number | boolean;
  tutor_slot_available: number | boolean;
  status_calc: string;
  duplicate_event?: number;
  exercises_correction?: null | string;
  eventDelayedStatus: string;
  eventDelayedTime: string;
}

export interface MeetingLinkRequest {
  eventId: number;
  role: 'tutor' | 'student';
}

export interface MeetingLinkResponse {
  status: boolean;
  meeting_url?: string;
  message?: string;
}

export interface EventsPaginator extends PaginatorInfo<EventItem> {}

export interface EventsData extends Array<EventItem> {}

export enum EVENT_TYPES {
  GRAMMAR = "Grammar",
  IELTS = "IELTS",
  SPEAKING = "Speaking",
  CORRECTION = "Correction",
  ONE_TO_ONE = "One to One",
  MOCK_TEST = "Mock test",
}

export interface TablePagination {
  setPage: (page: number) => void;
  paginatorInfo: MappedPaginatorInfo | null;
}

export interface EventTutors {
  first_name?: string;
  token: string;
  email: string;
  last_name?: string;
  exercises_mock_test: Array<any>;
  exercises_corrections: Array<any>;
}

export interface EventUsers extends EventTutors {}

export interface CreateEventInput {
  id?: number;
  ora: string;
  lang: string;
  data: string | Date;
  type: EVENT_TYPES | "";
  titolo: string;
  livello: string;
  ora_fine: string;
  fromAdmin?: boolean;
  tutor_token: string;
  descrizione: string;
  english_lvl: string;
  exercise_token?: string[];
  material_token?: string[];
  titolo_argomento?: string;
  nome_visualizzato: string;
  id_utente: string[];
  id_utente_presences: string[];
  exercises_correction?: "";
  descrizione_argomento: string;
  slot_available: number | boolean;
  user_slot_available: number | boolean;
  exercise_type?:string;
  // material_token_users?: string[];
  // material_token_tutors?: string[];
}

export interface MaterialExercise {
  for: null | string;
  material_id: number;
  id: number;
  type: string;
  name: string;
  token: string;
  lang: string[];
  language: string[];
  category: string[];
  lesson: string;
}

export interface MaterialExerciseResponse {
  materials: MaterialExercise[];
  exercises: MaterialExercise[];
}

export interface DefaultResponse {
  status: number;
  message: string;
}
export interface CreateEventResponse extends DefaultResponse {
  event_id: number;
}

export interface EditClassStudentsInput {
  events: number[];
  isRemove: boolean;
  students: string[];
}

export interface bulkLoadMaterialExercise {
  id: string;
  isRemove: boolean;
  students?: Array<string>;
  materials?: number[];
  exercises?: number[];
}

export interface bulkLoadMaterialExerciseInput {
  events: bulkLoadMaterialExercise[];
}

export interface EventStatisticsItem {
  id: string;
  presences: string;
  percentage: string;
  combination: string;
  participants: number;
}

export interface EventStatisticsPaginator
  extends PaginatorInfo<EventStatisticsItem> {}

export interface Order {
  id: number;
  token: string;
  payment_id: null;
  user_tk: string;
  product_tk: string;
  user_model: string;
  status: string;
  enabled_payments: string[];
  is_enabled_discount_code: number;
  default_discount_amount: number;
  left_time: string;
  typeof: string;
  start_month: string;
  metadata: null;
  request: Request;
  errors: null;
  created_at: string;
  updated_at: string;
}

export interface Request {
  amount: number;
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  country: string;
  zipcode: string;
  city: string;
  phone: string;
  start_month: string;
  english_level: null;
  lang: string;
  token: string;
  ini_type: string;
}

export interface OrdersResponseList {
  current_page: number;
  data: OrdersData[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: null;
  to: number;
  total: number;
}

export interface OrdersData {
  id: number;
  token: string;
  payment_id: number | null;
  user_tk: string;
  product_tk: string;
  user_model: UserModel;
  status: Status;
  enabled_payments: EnabledPayments[];
  is_enabled_discount_code: number;
  default_discount_amount: number;
  left_time: string;
  typeof: UserTypeof;
  start_month: null | string;
  metadata: null;
  request: any;
  errors: null;
  created_at: string;
  updated_at: string;
  user: UserOrders;
  payment: Payment | null;
  name?: string;
  lname?: string;
  payment_option?: string;
}

export interface EnabledPayments {
  payment_type: EnabledPayment.Alma | EnabledPayment.Klarna | EnabledPayment.Stripe | EnabledPayment.Pagolight;
  installments: number | null;
}

export enum EnabledPayment {
  Stripe = "stripe",
  Alma = "alma",
  Klarna = "klarna",
  Pagolight = "pagolight",
}

export interface Payment {
  id: number;
  token_user: string;
  amount: string;
  type: string;
  data: Date;
}

export enum Lang {
  En = "en",
  It = "it",
}

export enum Status {
  Completed = "completed",
  Initialized = "initialized",
}

export enum UserTypeof {
  Lead = "lead",
  User = "user",
  Tutoring = "tutoring",
  Upselling = "upselling",
}

export interface UserOrders {
  lang: Lang;
  phone: string;
  email: string;
  tutoring?: number | string | boolean;
  last_name: string;
  first_name: string;
  is_tutoring?: number | string;
}

export enum UserModel {
  AppModelsAPIV1CRM = "App\\Models\\Api\\V1\\Crm",
  AppModelsAPIV2User = "App\\Models\\Api\\V2\\User",
  AppModelsAPIV1Tutoring = "App\\Models\\Api\\V1\\Tutoring",
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}
