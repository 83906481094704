import { useEffect } from "react";
import useAuth from "@/hooks/useAuth";
import { useTheme } from "@mui/material";
import { useContextCrm } from "./Context";
import apis from "./apis";
import Toolbar from "./Toolbar";
import getColumns from "./Columns";
import BaseDataGrid from "@/components/BaseDataGrid";
import useProductApi from "@/api/useProductApi";

const Table = () => {
  const theme = useTheme();
  const context = useContextCrm();
  const getApis = apis(context);
  const { isAdmission } = useAuth();

  const dataAPI = getApis.get().data;
  const utmItems = getApis.getUtmItems().data;
  const products = useProductApi().callGetProducts().data;
  const imgsCalendars = getApis.getImgCalendars().data;

  useEffect(() => {
    if (!dataAPI?.data?.length) {
      context.setRows([]);
      context.setDataAPI((p: any) => ({ ...(p ?? {}), data: [] }));
      return;
    }

    context.setDataAPI(dataAPI);
    context.setRows(dataAPI.data);
  }, [dataAPI?.data, dataAPI?.data?.length]);

  useEffect(() => {
    if (!imgsCalendars) return;
    context.setImgsCalendars(imgsCalendars);
  }, [imgsCalendars]);

  useEffect(() => {
    if (!utmItems) return;
    context.setUtmItems(utmItems);
  }, [utmItems]);

  useEffect(() => {
    if (!products) return;
    context.setProducts(products);
  }, [products]);

  return (
    <BaseDataGrid
      hideTotalPagination
      page={context.page}
      rows={context.rows}
      Toolbar={Toolbar}
      columns={getColumns()}
      loading={Boolean(!dataAPI)}
      sortModel={context.sorts}
      sortingMode="server"
      onChangePage={context.setPage}
      totalPagination={context.pagination.total}
      getRowClassName={({ row }) =>
        row?.tutoring && !isAdmission
          ? theme.palette.mode == "dark"
            ? "bg-[#e0f7fa]/[.15]"
            : "bg-[#e0f7fa]"
          : ""
      }
      onSortModelChange={context.setSorts}
    />
  );
};

export default Table;
