import saveIcon from "@/assets/icons/save.svg";
import videoIcon from "@/assets/icons/video.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import CalendarEvents from "@/components/CalendarEvents/";
import useAuth from "@/hooks/useAuth";
import {
  INStatus,
  INUpdateRow,
} from "@/pages/Upselling/v1/Crm/interfacesAndDefaults";
import { Box, Button, Drawer, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import MeetingEditVideoCallInfo from "./MeetingEditVideoCallInfo";

export interface UserRecruitingMeeting {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  token: string;
  status: string;
  updates: undefined | Array<any>;
  meeting: { meet: Array<any>; summary: string };
}

export interface INFormMeetingDone {
  id?: string | null;
  status?: string | null;
  personality?: string | null;
  availability?: string | null;
  salary?: string | null;
  languages?: string | null;
  interviewer?: string | null;
}

export interface INButtons {
  NoShow?: string;
  Reschedule?: string;
  ToBeCalled?: string;
}

interface INProps {
  open: boolean;
  onClose: () => void;
  data: {
    user: UserRecruitingMeeting;
    userAdmin: { user: { first_name: string; last_name: string } };
    buttons?: {
      noShow?: { icon: any; name?: string; status: string };
      onHold?: { icon: any; name?: string; status: string };
      rejected?: {
        icon: any;
        name?: string;
        status: string;
        form: {
          id?: string | null;
          personality?: string | null;
          availability?: string | null;
          salary?: string | null;
          languages?: string | null;
          interviewer?: string | null;
        };
        setForm: (key: string, value: string) => void;
      };
      notInterested?: {
        icon: any;
        name?: string;
        status: string;
      };
      scheduled?: {
        icon: any;
        name?: string;
        status: string;
        eventMeetingType?: string;
        form: {
          id?: string | null;
          personality?: string | null;
          availability?: string | null;
          salary?: string | null;
          languages?: string | null;
          interviewer?: string | null;
        };
        setForm: (key: string, value: string) => void;
      };
    };
    versionStatus?: INStatus;
  };
  functions?: {
    onUpdateRow?: (param: INUpdateRow) => void;
    onMeetingDone?: (...params: any) => void;
    onSendFormUser?: (...params: any) => void;
    onMeetingDoneOther?: (...params: any) => void;
  };
  dataGetToogle?: any;
}

const MeetingComponent = ({ open, data, onClose, functions }: INProps) => {
  const { userAdmin } = useAuth();

  const [status, setStatus] = useState<string>("");
  const [eventId, setEventId] = useState<string>("");
  const [formUser, setFormUser] = useState<any>({});
  const [openCalendarEvents, setOpenCalendarEvents] = useState<boolean>(false);

  const meetingsSorted = data.user?.meeting?.meet?.length
    ? data.user?.meeting?.meet?.sort((a: any, b: any) =>
        a.start.localeCompare(b.start)
      )
    : [];

  const selectedEvent = meetingsSorted?.length
    ? meetingsSorted?.find((e: any) => e.id === eventId)
    : {};

  const handleSetFormMeetingScheduled = (event: {
    target: { name: string; value: string };
  }) => {
    const name = event.target.name;
    const value = event.target.value;

    if (
      !Object.hasOwnProperty.call(
        data.buttons?.scheduled?.form,
        "interviewer"
      ) ||
      data.buttons?.scheduled?.form?.interviewer === "" ||
      !data.buttons?.scheduled?.form?.interviewer ||
      data.buttons?.scheduled?.form?.interviewer !==
        userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name
    ) {
      data.buttons?.scheduled?.setForm?.(
        "interviewer",
        userAdmin?.user?.first_name + " " + userAdmin?.user?.last_name
      );
    }

    data.buttons?.scheduled?.setForm?.(name, value);
  };

  const resetFormMeetingDone = () => {
    const map = new Set(Object.keys(data?.buttons?.scheduled?.form || {}));

    map.forEach((key: string) => {
      data.buttons?.scheduled?.setForm?.(key, "");
    });
  };

  useEffect(() => {
    const meetings = data.user.meeting?.meet;
    if (Array.isArray(meetings) && meetings.length) {
      const first = meetings.find(
        (meet: any) => new Date() < new Date(meet.start)
      );

      if (!first) {
        setEventId(meetings[meetings.length - 1].id);
        data.buttons?.scheduled?.setForm(
          "id",
          meetings[meetings.length - 1].id
        );
        return;
      }

      setEventId(first.id);
      data.buttons?.scheduled?.setForm("id", first.id);
    }
  }, []);

  useEffect(() => {
    const selectedMeet = meetingsSorted?.find((e: any) => e.id === eventId);

    if (selectedMeet) {
      for (const key in selectedMeet) {
        data.buttons?.scheduled?.setForm?.(key, selectedMeet[key]);
      }
    }
  }, [eventId, meetingsSorted]);

  useEffect(() => {
    return () => {
      resetFormMeetingDone();
    };
  }, [open]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <EduModalTitle onClose={onClose}>
        <img src={videoIcon} />
        MODIFY VIDEO CALL INFORMATION . {data?.user?.first_name}{" "}
        {data?.user?.last_name}
      </EduModalTitle>

      <EduModalBody>
        <Box className="p-5 md:p-10 grid md:grid-cols-2 gap-10">
          <Box
            onSubmit={(e) => {
              e.preventDefault();
              functions?.onSendFormUser?.({ id: data.user.id, formUser });
            }}
            component="form"
            className="flex flex-col w-full gap-5"
          >
            <Typography
              color="primary"
              fontWeight="bold"
            >
              Modify contact information
            </Typography>

            <TextField
              required
              size="small"
              name="first_name"
              label="Name"
              value={formUser?.first_name ?? data.user?.first_name ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormUser((p: any) => ({ ...p, first_name: e.target.value }))
              }
              inputProps={{ style: { textTransform: "capitalize" } }}
            />

            <TextField
              required
              size="small"
              name="last_name"
              label="Last name"
              value={formUser?.last_name ?? data.user?.last_name ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormUser((p: any) => ({ ...p, last_name: e.target.value }))
              }
              inputProps={{ style: { textTransform: "capitalize" } }}
            />

            <TextField
              required
              size="small"
              name="email"
              label="Email"
              value={formUser?.email ?? data.user?.email ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormUser((p: any) => ({ ...p, email: e.target.value }))
              }
            />

            <PhoneField
              required
              size="small"
              name="phone"
              label="Phone"
              value={formUser?.phone ?? data.user?.phone ?? ""}
              onChange={(newPhone: string) =>
                setFormUser((p: any) => ({ ...p, phone: newPhone }))
              }
            />

            <Box className="flex flex-row-reverse">
              <Button
                type="submit"
                variant="outlined"
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                Save
              </Button>
            </Box>
          </Box>

          <MeetingEditVideoCallInfo
            {...{
              data,
              status,
              eventId,
              functions,
              setStatus,
              setEventId,
              selectedEvent,
              meetingsSorted,
              setOpenCalendarEvents,
              handleSetFormMeetingScheduled,
            }}
          />
        </Box>

        <Drawer
          sx={{ zIndex: 1300 }}
          open={openCalendarEvents}
          anchor="right"
          onClose={() => setOpenCalendarEvents(false)}
        >
          {data.user ? (
            <CalendarEvents
              user={data.user}
              status={status}
              onUpdateRow={(meet: any) => {
                const values: any = { status };

                const isScheduleMeeting =
                  status === data.buttons?.scheduled?.status;

                if (isScheduleMeeting) {
                  let meetings: any = data.user.meeting;

                  const formatMeet = {
                    id: meet?.id,
                    start: meet?.start,
                    end: meet?.end,
                    calendar: meet?.calendar,
                    link: meet?.meet,
                  };

                  if (isScheduleMeeting && meetings) {
                    meetings.meet.push(formatMeet);
                    values.meetings = meetings;
                  } else if (isScheduleMeeting && !meetings) {
                    values.meetings = {
                      meet: [formatMeet],
                      summary: meet?.summary,
                    };
                  }
                }

                functions?.onUpdateRow?.({
                  values,
                  toast: "status",
                  tokenUser: data.user.token,
                  activeApi: !isScheduleMeeting,
                });
                setOpenCalendarEvents(false);
              }}
            />
          ) : (
            "No user selected"
          )}
        </Drawer>
      </EduModalBody>
    </EduModal>
  );
};

export default MeetingComponent;
