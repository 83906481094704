import saveIcon from "@/assets/icons/save.svg";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useContextCrm } from "../Context";
import functions from "../functions";

export default () => {
  const context = useContextCrm();
  const getFunctions = functions(context);

  const [link, setLink] = useState<string>("");

  const handleClose = () => {
    setLink("");
    context.setDialog(undefined);
  };

  return (
    <EduModal
      fullWidth
      open={Boolean(context.dialog?.data?.date_time)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        Add link for meet: {context.dialog?.data?.date_time}
      </EduModalTitle>

      <EduModalBody className="flex flex-col gap-4 p-5">
        <TextField
          label="Link"
          value={link ?? ""}
          onChange={(e) => setLink(e.target.value)}
          placeholder="https://drive.google.com/file/d/..."
        />

        <Box className="flex justify-end">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => getFunctions.handleAddLinkForMeet(link)}
            disabled={!link}
          >
            <img
              src={saveIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};
