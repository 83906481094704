import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";

export const LINK_WHATS_APP = "https://api.whatsapp.com/send?phone=";

export const PRODUCTS_REMINDER_LINK_3 = {
  "Percorso di 48 ore per la certificazione IELTS C1": 1,
  "Percorso di 48 ore per la certificazione IELTS B2": 1,
  "Percorso di 36 ore per la certificazione IELTS B2": 1,
  "Percorso di 36 ore per la certificazione IELTS C1": 1,
  "Percorso di 36 ore per la certificazione IELTS B1": 1,
  "Percorso di 48 ore per la certificazione IELTS B1": 1,
  "Percorso di 48 ore per il potenziamento": 2,
  // new products
  "Percorso di 60 ore": 1,
  "Percorso di 36 ore per il potenziamento": 2,
};

export const NOT_FOUND_TEMPLATE_WHATSAPP =
  "Template not found for this lead with this lang!";

export const DEFAULT_FORM_MEETING_DONE: IFormMeetingDone = {
  notes: null,
  pacchetto: null,
  motivation: null,
  english_lvl: null,
  payment_link: null,
  certification: null,
  urlLinkRecord: null,
};
