import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import useAuth from "@/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { CustomToolbar } from "./Toolbar";
import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTableStyling } from "@/providers/Colors";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import useAPI from "@/api/useAPI";
import useDGS from "@/hooks/useDataGridSort";
import BasePage from "@/components/Page/Base";
import useScreen from "@/hooks/useScreen";
import CustomPaginationClient from "@/components/_Common/CustomPagination/CustomPaginationClient";

const KpisLeads = () => {
  const theme = useTheme();
  const { screen, perPage } = useScreen();
  const { isAdmission, isMember, userAdmin } = useAuth();

  const KEY_QUERY = ["interview-performance", "kpis-leads", isAdmission];

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [ownedBy, setOwnedBy] = useState<Array<string>>([]);
  const [columnsHeaders, setColumnsHeaders] = useState({});
  const [itemsUtmSource, setItemsUtmSource] = useState<Array<string>>([]);

  const [filters, setFilters] = useSearchParams();
  const filterData = Object.fromEntries(filters.entries());
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });

  let KEY_QUERY_DATE: any = [...KEY_QUERY, "", "", filterData];
  let params: any = {};

  if (filterData?.dates) {
    const date = filterData?.dates.split(",");

    const startDate = format(new Date(date[0]), "yyyy-MM-dd HH:mm:ss");
    const endDate = format(new Date(date[1]), "yyyy-MM-dd HH:mm:ss");

    params = { ...params, startDate, endDate };

    KEY_QUERY_DATE = [...KEY_QUERY, startDate, endDate, filterData];
  }

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY_DATE,
    () =>
      useAPI("/v1/interview-performance/kpis-leads", {
        ...params,
        ...(filterData ?? {}),
        ...(isMember
          ? {
              admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
            }
          : {}),
        isAdmission,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const renderHeader = (nameColumn: string) => ({
    renderHeader: ({ field }) => (
      <Box className="MuiDataGrid-columnHeaderTitle ">
        {Boolean(columnsHeaders?.[field]) && (
          <span className="number">{columnsHeaders?.[field]}</span>
        )}
        {nameColumn}
      </Box>
    ),
  });

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        minWidth: 180,
        flex: 1,
        headerName: "Admin",
      },
      {
        field: "total_leads",
        minWidth: 110,
        flex: 0.5,
        headerName: "Total_eads",
        ...(isMember ? {} : renderHeader("Total leads")),
      },
      {
        field: "count_delete",
        minWidth: 80,
        flex: 0.5,
        headerName: "Deleted",
        ...(isMember ? {} : renderHeader("Deleted")),
      },
      {
        field: "leads",
        minWidth: 80,
        flex: 0.5,
        headerName: "Leads",
        ...(isMember ? {} : renderHeader("Leads")),
      },
      {
        field: "interviews_done",
        minWidth: 160,
        flex: 0.7,
        headerName: "Interviews done",
        ...(isMember ? {} : renderHeader("Interviews done")),
      },
      {
        field: "sale",
        minWidth: 80,
        flex: 0.5,
        headerName: "Sale",
        ...(isMember ? {} : renderHeader("Sale")),
      },
      {
        field: "show_up_rate",
        minWidth: 150,
        flex: 0.7,
        headerName: "Show-up rate",
        ...(isMember ? {} : renderHeader("Show-up rate")),
      },
      {
        field: "conversion_rate",
        minWidth: 170,
        flex: 0.7,
        headerName: "Conversion rate",
        ...(isMember ? {} : renderHeader("Conversion rate")),
      },
      {
        field: "interviews_done_rescheduled",
        minWidth: 260,
        flex: 1,
        headerName: "Interviews done rescheduled",
        ...(isMember ? {} : renderHeader("Interviews done rescheduled")),
      },
      {
        field: "sales_rescheduled",
        minWidth: 180,
        flex: 1,
        headerName: "Sales rescheduled",
        ...(isMember ? {} : renderHeader("Sales rescheduled")),
      },
      {
        field: "conversion_rate_rescheduled",
        minWidth: 250,
        flex: 1,
        headerName: "Conversion rate rescheduled",
        ...(isMember ? {} : renderHeader("Conversion rate rescheduled")),
      },
    ],
    [screen, columnsHeaders]
  );

  useEffect(() => {
    if (!isLoading) {
      setColumnsHeaders(dataAPI?.columns);
      setRows(dataAPI?.rows);
      setOwnedBy(dataAPI?.rows?.map((e: any) => e.name));
      setItemsUtmSource(dataAPI?.utmSource);
    }
  }, [dataAPI, isLoading]);

  return (
    <BasePage title={"KPIs Leads" + (isAdmission ? " admission" : "")}>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPaginationClient,
        }}
        initialState={{
          sorting: { sortModel: [{ field: "meeting_done", sort: "desc" }] },
        }}
        componentsProps={{
          toolbar: {
            ownedBy,
            isMember,
            setFilters,
            itemsUtmSource,
            title: "Creation date",
            filters: filterData,
            fileName: "KPIs Leads",
            KEY_QUERY: KEY_QUERY_DATE,
            hideProduct: true,
            showQuickFilter: true,
            meetingCalendar: dataAPI?.owned_by || [],
            hideEnglishLevel: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </BasePage>
  );
};

export default KpisLeads;
