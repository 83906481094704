import client from "@/api/client";
import { useMutateOrder, usePaymentOptions } from "@/api/orders";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import MyTooltip from "@/components/MyTooltip";
import ProductSelect from "@/components/ProductSelect";
import { clone, fUpper } from "@/components/useHelpers";
import useAuth from "@/hooks/useAuth";
import { OrdersData, UserOrders, UserTypeof } from "@/interfaces";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import EnabledPaymentsIcon from "./EnabledPaymentsIcon";
import { checkIsSwiss } from "@/utils";

const whiteListKeys = [
  "left_time",
  "product_tk",
  "start_month",
  "enabled_payments",
  "default_discount_amount",
  "payment_option",
];

// Mapping for payment options
const paymentOptionsMapping = [
  { value: "50", label: "€50" },
  { value: "80", label: "€80" },
  { value: "120", label: "€120" },
  { value: "150", label: "€150" },
  { value: "new_checkout", label: "Pagolight/Alma" },
];

export interface IRef {
  open: (orders: Array<OrdersData>, user?: UserOrders) => void;
}

type IProps = {};

export default forwardRef<IRef, IProps>((_, ref) => {
  const { copyToClipboard } = useAuth();
  const { mutate: edit, isLoading } = useMutateOrder();

  const [user, setUser] = useState<UserOrders>();
  const [form, setForm] = useState({} as OrdersData);
  const [order, setOrder] = useState<OrdersData>();
  const [orders, setOrders] = useState<Array<OrdersData>>();
  const [addDays, setAddDays] = useState<number | null>(null);

  const isTutoring =
    user?.tutoring ||
    order?.user?.is_tutoring ||
    order?.typeof === UserTypeof.Tutoring;

  const lang = checkIsSwiss(user?.phone, user?.lang || "it");

  const { data: dataProducts, isLoading: isLoadingProducts } = useQuery({
    queryKey: ["products", lang, isTutoring],
    queryFn: () =>
      client.product.listByLanguage(lang, Boolean(isTutoring)),
    enabled: Boolean(user?.phone),
  });

  const { availablePaymentTypes: providers } = usePaymentOptions();

  const leftTime = form?.left_time ? new Date(form?.left_time) : new Date();

  const isExpired = leftTime < new Date();

  const fullName =
    (user?.first_name ?? order?.name ?? order?.user?.first_name) +
    " " +
    (user?.last_name ?? order?.lname ?? order?.user?.last_name);

  useImperativeHandle(ref, () => ({
    open: (orders, user) => {
      orders = clone(orders);
      setUser(user);
      setOrder(orders.find((o) => o.status !== "completed"));
      setOrders(orders);
    },
  }));

  const startMonths = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => {
        const year = new Date().getFullYear();
        const currentMonthIndex = new Date().getMonth() + 1;
        const offset = currentMonthIndex + i;
        const date = new Date(year, offset, 1);

        return {
          label: format(date, "MMMM yyyy"),
          value: fUpper(format(date, "MMMM yyyy", { locale: it })),
        };
      }),
    []
  );

  const handleClose = () => {
    setUser(undefined);
    setForm({} as OrdersData);
    setOrder(undefined);
    setOrders(undefined);
    setAddDays(null);
  };

  const handleEnabledPayments = (payment_type, installments, e) => {
    const checked = e.target.checked;

    if (checked) {
      setForm((p) => ({
        ...p,
        [e.target.name]: [
          ...(p[e.target.name] ?? []),
          { payment_type, installments },
        ],
      }));
    } else {
      setForm((p) => ({
        ...p,
        [e.target.name]: p[e.target.name].filter((item) => {
          return (
            item.payment_type !== payment_type ||
            item.installments !== installments
          );
        }),
      }));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === "default_discount_amount") {
      setForm({ ...form, [name]: parseInt(value || 0) * 100 });
      return;
    }

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (!order) {
      setForm({} as OrdersData);
      return;
    }

    let temp = {} as OrdersData;

    Object.keys(order).forEach((key) => {
      if (whiteListKeys.includes(key)) {
        temp[key] = order[key];
      }
    });

    setForm(temp);
  }, [order]);

  useEffect(() => {
    if (!order?.left_time) return;

    const newDate = new Date(
      addDays === null ? order.left_time : new Date().setHours(20, 0, 0, 0)
    );

    newDate.setDate(newDate.getDate() + (addDays ?? 0));

    setForm((p) => ({
      ...p,
      left_time: format(newDate, "yyyy-MM-dd HH:mm:ss"),
    }));
  }, [addDays, order]);

  return (
    <EduModal
      fullWidth
      open={Boolean(orders?.length)}
      onClose={handleClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={handleClose}>
        <img
          src={editIcon}
          className="icon"
          alt="Edit order"
        />
        Edit order{orders && orders?.length > 1 ? "s" : ""} - {fullName}
      </EduModalTitle>

      <EduModalBody className="flex flex-col p-5 pt-6 gap-10">
        {orders && orders?.length > 1 && (
          <TextField
            select
            fullWidth
            label="Orders user"
            value={order?.token ?? ""}
            onChange={(e) =>
              setOrder(
                orders?.find((o) => o?.token == e.target.value) ?? orders[0]
              )
            }
          >
            {orders.map((o) => (
              <MenuItem
                key={o.token + o.id}
                value={o.token}
                disabled={o?.status == "completed"}
              >
                {o?.token} - {fUpper(o?.typeof || "")} - {fUpper(o?.status)}
              </MenuItem>
            ))}
          </TextField>
        )}

        {order ? (
          <>
            <Box className="flex flex-col gap-5">
              <ProductSelect
                value={form?.product_tk}
                products={dataProducts?.item || []}
                onChange={handleChange}
                isLoading={isLoadingProducts}
              />
              <FormGroup>
                <InputLabel
                  required
                  id="enabled-providers"
                >
                  Enabled providers
                </InputLabel>

                <Box className="!grid grid-cols-2">
                  {providers?.map((provider, index) => (
                    <FormControlLabel
                      key={provider + "-" + index}
                      label={
                        <Box className="flex gap-2 items-center">
                          <EnabledPaymentsIcon providers={[provider]} />
                          {provider.display_name}
                        </Box>
                      }
                      control={
                        <Checkbox
                          name="enabled_payments"
                          color="primary"
                          value={provider.payment_type.toLowerCase()}
                          checked={
                            form?.enabled_payments?.filter(
                              (item) =>
                                item.payment_type === provider.payment_type &&
                                item.installments == provider.installments
                            ).length > 0 || false
                          }
                          onChange={(e) =>
                            handleEnabledPayments(
                              provider.payment_type,
                              provider.installments,
                              e
                            )
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </FormGroup>

              {order?.typeof === UserTypeof.Upselling && (
                <TextField
                  select
                  fullWidth
                  id="start-month-select"
                  name="start_month"
                  color="primary"
                  label="Start month"
                  value={form?.start_month ?? ""}
                  onChange={handleChange}
                >
                  {startMonths.map((m, i) => (
                    <MenuItem
                      key={m.value + "-" + i}
                      value={m.value}
                    >
                      {m.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                select
                fullWidth
                id="payment-option-select"
                name="payment_option"
                color="primary"
                label="Payment Option"
                value={form?.payment_option ?? ""}
                onChange={handleChange}
              >
                {paymentOptionsMapping.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                type="number"
                name="default_discount_amount"
                label="Default discount amount"
                value={
                  form?.default_discount_amount
                    ? form?.default_discount_amount / 100
                    : ""
                }
                variant="outlined"
                onChange={handleChange}
              />

              <Box className="w-full flex items-stretch justify-between gap-4">
                <Button
                  color="primary"
                  variant="outlined"
                  className="text-lg"
                  onClick={() =>
                    setAddDays((p: null | number) =>
                      p === null ? -1 : p === 0 ? null : p - 1
                    )
                  }
                >
                  -
                </Button>

                <Box
                  className={classNames(
                    "text-green-600 grow flex justify-center items-center",
                    { "!text-red-500": isExpired }
                  )}
                >
                  <MyTooltip
                    title={"Add days: " + addDays}
                    placement="bottom"
                    className="flex justify-center items-center"
                  >
                    {addDays === null && "Default:"}{" "}
                    {leftTime && format(leftTime, "EEEE, dd MMMM HH:mm")}
                  </MyTooltip>
                </Box>

                <Button
                  color="primary"
                  variant="outlined"
                  className="text-lg"
                  onClick={() =>
                    setAddDays((p) =>
                      p === null ? 0 : p === -1 ? null : p + 1
                    )
                  }
                >
                  +
                </Button>
              </Box>
            </Box>

            <Box className="flex flex-row-reverse">
              <LoadingButton
                color="primary"
                variant="outlined"
                loading={isLoading}
                onClick={() => {
                  if (!order?.token) return;
                  edit({ token: order.token, data: form });
                }}
                disabled={!order?.token}
                startIcon={<img src={saveIcon} />}
              >
                Save
              </LoadingButton>
            </Box>
          </>
        ) : (
          Boolean(orders?.[0]) && (
            <Button
              fullWidth
              onClick={() => copyToClipboard(orders?.[0]?.token ?? "")}
            >
              {orders?.[0]?.token} - {fUpper(orders?.[0]?.typeof ?? "")} -{" "}
              {fUpper(orders?.[0]?.status ?? "")}
            </Button>
          )
        )}
      </EduModalBody>
    </EduModal>
  );
});
