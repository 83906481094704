import Dropdown from "@/components/_Common/Dropdown";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { getTableStyling } from "@/providers/Colors";
import { PeopleAlt } from "@mui/icons-material";
import { Badge, Box, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { forwardRef, useImperativeHandle, useState } from "react";

interface IAVGPriceProducts {
  data: Array<any>;
  title: string;
  anchorEl: any;
}

export interface IRef {
  open: (products: Array<IAVGPriceProducts>, title: string) => void;
}

interface IProps {
  theme: any;
  setFilters: any;
  copyToClipboard: (data: string) => void;
}

const AVGPriceDialog = forwardRef<IRef, IProps>(
  ({ theme, setFilters, copyToClipboard }, ref) => {
    const [title, setTitle] = useState<string>();
    const [products, setProducts] = useState<Array<IAVGPriceProducts>>();

    useImperativeHandle(ref, () => ({
      open: (products, title) => {
        setTitle("AVG price " + title);
        setProducts(products);
      },
    }));

    const handleClose = () => {
      setTitle(undefined);
      setProducts(undefined);
    };

    const columns: GridColDef[] = [
      {
        field: "product_tag",
        headerName: "Product tag",
        minWidth: 180,
        flex: 1,
        renderCell: ({ row }) => (
          <button
            onClick={() =>
              setFilters(
                (p: any) => {
                  if (row?.token) {
                    p.set("product", row?.token);
                  }
                  return p;
                },
                { replace: true }
              )
            }
            className="w-full px-2 py-1 rounded truncate text-left hover:bg-gray-100/5"
          >
            {row?.product_tag}
          </button>
        ),
      },
      {
        field: "count",
        headerName: "Quantity",
        renderHeader: () => {
          const quantity = products?.reduce(
            (acc, el: any) => acc + el?.count,
            0
          );
          return (
            <Box className="MuiDataGrid-columnHeaderTitle ">
              {quantity && <span className="number">{quantity}</span>}
              Quantity
            </Box>
          );
        },
      },
      {
        field: "price",
        headerName: "Price",
        renderHeader: () => {
          const price = products?.reduce(
            (acc, el: any) => acc + Number(el.price.replace("€", "")),
            0
          );
          return (
            <Box className="MuiDataGrid-columnHeaderTitle ">
              {price && <span className="number">{price.toFixed(2)}€</span>}
              Price
            </Box>
          );
        },
      },
      {
        field: "total_price",
        headerName: "Total price",
        renderHeader: () => {
          const price = products?.reduce(
            (acc, el: any) => acc + Number(el.total_price.replace("€", "")),
            0
          );
          return (
            <Box className="MuiDataGrid-columnHeaderTitle ">
              {price && <span className="number">{price.toFixed(2)}€</span>}
              Revenues
            </Box>
          );
        },
      },
      {
        field: "users",
        headerName: "Users",
        width: 80,
        hideable: false,
        sortable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => {
          return (
            <Badge
              color="primary"
              badgeContent={row?.count}
            >
              <Dropdown text={<PeopleAlt />}>
                <Box className="flex flex-col gap-1 p-3">
                  {row?.users?.map((user: any, i: number) => (
                    <Button
                      key={user?.fullName + i}
                      onClick={() => copyToClipboard(user?.email)}
                    >
                      <Box className="w-full flex gap-4 justify-between">
                        <Box className="flex gap-4">
                          <span className="w-6">{i + 1}</span>
                          <span className="w-8 text-left">{user?.english_lvl}</span>
                        </Box>
                        <span>{user?.fullName}</span>
                      </Box>
                    </Button>
                  ))}
                </Box>
              </Dropdown>
            </Badge>
          );
        },
      },
    ];

    return (
      <EduModal
        fullWidth
        open={Boolean(products)}
        onClose={handleClose}
        maxWidth="lg"
      >
        <EduModalTitle onClose={handleClose}>{title}</EduModalTitle>

        <EduModalBody className="p-5">
          <DataGrid
            autoHeight
            hideFooter
            disableColumnMenu
            disableVirtualization
            disableSelectionOnClick
            sx={getTableStyling(theme)}
            rows={products || []}
            columns={columns}
            getRowId={(row) => row?.token}
            experimentalFeatures={{ newEditingApi: false }}
          />
        </EduModalBody>
      </EduModal>
    );
  }
);

export default AVGPriceDialog;
