import { ChangeEvent, useMemo, useState } from "react";
import { jS } from "../useHelpers";
import { useQuery } from "@tanstack/react-query";
import { Box, Avatar, MenuItem, TextField } from "@mui/material";
import useAPI from "@/api/useAPI";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface INProps {
  items: Array<string | { name: string; primary?: boolean }>;
  title?: string;
  ownedBy: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  useRemove?: boolean | undefined;
}

const SelectOwnedByFilter = ({
  title = "Owned by",
  items,
  ownedBy,
  onChange,
  useRemove,
}: INProps): JSX.Element => {
  const includesPrimary = jS(items ?? []).includes("primary");

  const [itemsToShow, setItemsToShow] = useState<Array<any>>(
    includesPrimary ? items.filter((e: any) => e?.primary) : items
  );

  const { data } = useQuery(["crm-get-img-calendars"], () =>
    useAPI("/v1/crm/get-img-calendars")
  );

  const handleShowAllItems = () =>
    setItemsToShow((e) => [
      ...e,
      ...(includesPrimary
        ? items.filter((i) => (typeof i === "string" ? false : !i?.primary))
        : []),
    ]);

  const menuItemsPrimary = useMemo(
    () =>
      itemsToShow
        ?.filter((item: string | { name: string }, i: number) =>
          (typeof item === "string" ? item : item?.name)?.trim()
        )
        ?.map((item: string | { name: string }, i: number) => {
          const name = typeof item === "string" ? item : item?.name;

          return (
            <MenuItem
              key={name + i}
              value={name}
              color="primary"
              className="w-full"
            >
              <Box className="w-full flex justify-between items-center gap-4">
                <Box>{name}</Box>

                {Boolean(data?.[name]?.img) ? (
                  <Avatar
                    alt={name}
                    src={data?.[name]?.img}
                    className="!w-[1.8rem] !h-[1.8rem]"
                  />
                ) : (
                  <Avatar className="!w-[1.8rem] !h-[1.8rem]">
                    {name.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </Box>
            </MenuItem>
          );
        }),
    [itemsToShow, jS(itemsToShow), data]
  );

  return (
    <TextField
      select
      fullWidth
      name="owned_by"
      label={title}
      color="primary"
      value={ownedBy || ""}
      onChange={onChange}
    >
      {Boolean(useRemove) ? <MenuItem value="">- Remove -</MenuItem> : null}

      {menuItemsPrimary}

      {Boolean(itemsToShow?.length < items?.length && includesPrimary) && (
        <li key="show-all-items">
          <button
            onClick={handleShowAllItems}
            className="w-full text-center p-2 hover:bg-gray-400/10"
          >
            <KeyboardArrowDownIcon fontSize="small" />
          </button>
        </li>
      )}
    </TextField>
  );
};

export default SelectOwnedByFilter;
