import addIcon from "@/assets/icons/add.svg";
import downloadIcon from "@/assets/icons/download.svg";
import filterIcon from "@/assets/icons/filter.svg";
import { STATUS_OFFER_REJECTED } from "@/components/Crm/CrmTable";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import SelectStatusFilter from "@/components/Crm/SelectStatusFilter";
import SelectMultipleCheckbox, {
  IItemObject,
} from "@/components/SelectMultipleCheckbox";
import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import Dropdown from "@/components/_Common/Dropdown";
import FiltersBar from "@/components/_Common/FiltersBar";
import DateRangePickerField from "@/components/_Common/Forms/DateRangePickerField";
import BUTTONS_CRM_WHATSAPP from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useAuth from "@/hooks/useAuth";
import { CALENDARS } from "@/providers/AuthProvider";
import { SocialDistance } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useContextCrm } from "./Context";
import apis from "./apis";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";

const Toolbar = () => {
  const { isMember, userAdmin } = useAuth();
  const context = useContextCrm();
  const getApis = apis(context);

  return (
    <Box
      id="filter_bar"
      className="flex gap-4 flex-wrap !items-stretch"
    >
      <FiltersBar>
        <Box className="flex">
          <Button
            sx={{
              borderRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            variant="outlined"
            onClick={() => context.setFilters({} as URLSearchParams)}
            className="hover:!border-r-0"
          >
            Refresh
          </Button>

          <Button
            sx={{
              borderLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            variant="outlined"
            onClick={() => context.getInvalidateQuery()}
            className="hover:!border-l-0"
          >
            Sync
          </Button>
        </Box>

        <Button
          variant="outlined"
          onClick={() => context.setDialog({ component: "create_lead" })}
        >
          <img
            src={addIcon}
            className="icon -ml-1"
          />
          Add manually
        </Button>

        <Dropdown
          text="Filter"
          icon={filterIcon}
          active={Boolean(
            Object.keys(context.filters).filter(
              (key) => !["search", "sorts", "page"].includes(key)
            ).length
          )}
        >
          <FilterItems />
        </Dropdown>

        {(!isMember || userAdmin?.user?.email == "admission@edusogno.com") && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => getApis.download()}
          >
            <img
              src={downloadIcon}
              className="icon"
            />
            Download
          </Button>
        )}
      </FiltersBar>

      <SearchToolbar
        {...{ filters: context.filters, setFilters: context.setFilters }}
      />
    </Box>
  );
};

function FilterItems() {
  const context = useContextCrm();
  const { userAdmin } = useAuth();

  const handleOnChange = ({ target: { name, value } }) =>
    context.setFilters((f) => {
      if (value) {
        f.set(name, value);
      } else {
        f.delete(name);
      }
      return f;
    });

  return (
    <Box className="bg-edu-800 flex flex-col w-[250px] gap-5 p-4">
      {/* Status */}
      <SelectStatusFilter
        useRemove
        status={context.filters?.status ?? ""}
        onChange={(e) =>
          context.setFilters((f) => {
            if (e.target.value) {
              f.set("status", e.target.value);
            } else {
              f.delete("status");
            }
            return f;
          })
        }
      />

      {/* Owned by */}
      <SelectOwnedByFilter
        useRemove
        items={
          userAdmin?.calendars?.[CALENDARS.SALES]?.filter(
            (e: { name: string }) => e?.name
          ) || []
        }
        ownedBy={context.filters?.owned_by || ""}
        onChange={(e) =>
          context.setFilters((f) => {
            if (e.target.value) {
              f.set("owned_by", e.target.value);
            } else {
              f.delete("owned_by");
            }
            return f;
          })
        }
      />

      {/* Language */}
      <TextField
        select
        fullWidth
        id="calendar-select"
        name="lang"
        color="primary"
        label="Language"
        value={context.filters?.lang || ""}
        onChange={(e) =>
          context.setFilters((f) => {
            if (e.target.value) {
              f.set("lang", e.target.value);
            } else {
              f.delete("lang");
            }
            return f;
          })
        }
      >
        {[
          { name: "- Remove -", iso: "" },
          ...LANGUAGES,
          { name: "Switzerland", iso: "ch" },
        ].map((item: { iso: string; name: string }, i: number) => (
          <MenuItem
            key={item.name + i}
            value={item.iso}
          >
            {item.name}
          </MenuItem>
        ))}
      </TextField>

      {/* Reminder */}
      <TextField
        select
        fullWidth
        id="reminder-select"
        name="reminder"
        color="primary"
        label="Reminder"
        value={context.filters?.reminder || ""}
        onChange={handleOnChange}
      >
        {[
          { label: "- Remove -" },
          ...Object.values(BUTTONS_CRM_WHATSAPP),
          {
            icon: (
              <SocialDistance
                color="primary"
                fontSize="small"
              />
            ),
            label: "Reminder 2",
            value: "reminder_2",
            valueDB: "reminder2",
          },
        ].map(
          (
            item: { label: string; icon?: JSX.Element; value?: string },
            index: number
          ) => (
            <MenuItem
              key={(item?.value || item.label) + index}
              value={item?.value || ""}
            >
              <Box className="w-full flex justify-between gap-2">
                <Box>{item.label}</Box>
                {item?.icon ? item.icon : null}
              </Box>
            </MenuItem>
          )
        )}
      </TextField>

      {/* Utm source */}
      <SelectMultipleCheckbox
        label="Utm source"
        value={context.filters?.utm_source?.split(",") ?? []}
        items={
          [
            { label: "Blank", value: "null" },
            ...(context.utmItems?.utm_source || []),
          ] as Array<string | IItemObject>
        }
        onChange={(_, value) =>
          context.setFilters((p: any) => {
            if (value?.length) {
              p.set("utm_source", value.join(","));
            } else {
              p.delete("utm_source");
            }
            return p;
          })
        }
      />

      {/* Utm campaign */}
      <SelectMultipleCheckbox
        label="Utm campaign"
        value={context.filters?.utm_campaign?.split(",") ?? []}
        items={
          [
            { label: "Blank", value: "null" },
            ...(context.utmItems?.utm_campaign || []),
          ] as Array<string | IItemObject>
        }
        onChange={(_, value) =>
          context.setFilters((p: any) => {
            if (value?.length) {
              p.set("utm_campaign", value.join(","));
            } else {
              p.delete("utm_campaign");
            }
            return p;
          })
        }
      />

      {/* Status offer rejected */}
      <TextField
        select
        fullWidth
        name="status_offer_rejected"
        label="Status offer rejected"
        value={context.filters?.status_offer_rejected ?? ""}
        onChange={handleOnChange}
      >
        <MenuItem
          key="- Remove -"
          value=""
        >
          - Remove -
        </MenuItem>

        {STATUS_OFFER_REJECTED.all.map((status) => (
          <MenuItem
            key={status}
            value={status}
          >
            {status}
          </MenuItem>
        ))}
      </TextField>

      {/* Validate name */}
      <TextField
        select
        fullWidth
        name="validate_name"
        label="Validate name"
        value={context.filters?.validate_name ?? ""}
        onChange={handleOnChange}
      >
        <MenuItem
          key="- Remove -"
          value=""
        >
          - Remove -
        </MenuItem>

        {["Yes", "No"].map((v) => (
          <MenuItem
            key={v}
            value={v}
          >
            {v}
          </MenuItem>
        ))}
      </TextField>

      {/* Has order/s */}
      <TextField
        select
        fullWidth
        name="has_orders"
        label="Has order/s"
        value={context.filters?.has_orders ?? ""}
        onChange={handleOnChange}
      >
        <MenuItem
          key="- Remove -"
          value=""
        >
          - Remove -
        </MenuItem>

        {["Yes", "No"].map((v) => (
          <MenuItem
            key={v}
            value={v}
          >
            {v}
          </MenuItem>
        ))}
      </TextField>

      {/* Letter info */}
      <TextField
        select
        fullWidth
        name="letter_info"
        label="Letter info"
        value={context?.filters?.letter_info ?? ""}
        onChange={handleOnChange}
      >
        {[
          {
            letter: "A",
            classBgColor: "!text-red-400",
          },
          {
            letter: "D",
            classBgColor: "!text-blue-400",
          },
          {
            letter: "R",
            classBgColor: "!text-yellow-400",
          },
        ].map((l) => (
          <MenuItem
            key={l.letter}
            value={l.letter}
            className={l.classBgColor}
          >
            {l.letter}
          </MenuItem>
        ))}
      </TextField>

      {/* Creation date */}
      <DateRangePickerField
        title="Creation date"
        value={
          context.filters?.created_date
            ? context.filters?.created_date
                .split(",")
                .map((c: any) => new Date(c))
            : null
        }
        onChange={(value) => {
          context.setFilters((p: any) => {
            if (value) {
              p.set(
                "created_date",
                [
                  dayjs(value?.startDate).format("YYYY-MM-DD HH:mm:ss"),
                  dayjs(value?.endDate).format("YYYY-MM-DD HH:mm:ss"),
                ].join(",")
              );
              p.set("page", "1");
            } else p.delete("created_date");
            return p;
          });
        }}
      />

      {/* Meeting date */}
      <DateRangePickerField
        title="Meeting date"
        value={
          context.filters?.meeting_date
            ? context.filters?.meeting_date
                .split(",")
                .map((c: string) => new Date(c))
            : null
        }
        onChange={(value) => {
          context.setFilters((p: any) => {
            if (value) {
              p.set(
                "meeting_date",
                [
                  dayjs(value?.startDate).format("YYYY-MM-DD HH:mm:ss"),
                  dayjs(value?.endDate).format("YYYY-MM-DD HH:mm:ss"),
                ].join(",")
              );
              p.set("sorts", '{"meeting_date":"asc"}');
              p.set("page", "1");
            } else p.delete("meeting_date");
            return p;
          });
        }}
      />

      <Dropdown
        text="Meeting filter"
        className="!h-[2.6rem]"
        classNameText="w-full text-left text-base"
      >
        <Box className="bg-edu-800 flex flex-col w-[250px] gap-5 p-4">
          <TextField
            select
            fullWidth
            name="english_level"
            label="English level"
            value={context.filters?.english_level ?? ""}
            onChange={handleOnChange}
          >
            <MenuItem
              key="- Remove -"
              value=""
            >
              - Remove -
            </MenuItem>

            {ENGLISH_LEVELS.map((l) => (
              <MenuItem
                key={l}
                value={l}
              >
                {l}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            name="product"
            label="Product"
            value={context.filters?.product ?? ""}
            onChange={handleOnChange}
          >
            {context?.products?.products?.map((p, i) => (
              <MenuItem
                key={p.id + i}
                value={p?.token ?? ""}
              >
                <Box className="w-full flex justify-between gap-4">
                  <span className="truncate">{p?.product_tag}</span>
                  <span>{Number(p?.price) / 100}€</span>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Dropdown>
    </Box>
  );
}

export default Toolbar;
