import { clone } from "@/components/useHelpers";

export const defValueType = ["Listening", "Reading", "Grammar"] as const;

export const defValueType2 = ["true_false", "multiple_choice"] as const;

export const defValueType3 = ["matching_option", "gap_fill"] as const;

export const defOption = ["true", "false", "not given"] as const;

export interface IMultipleChoiceTrueFalse {
  question: string;
  type: (typeof defValueType2)[number];
  options: Array<{
    option: string;
    is_correct: boolean;
  }>;
}

export interface IMatchingOptionGapFill {
  statement: string;
  type: (typeof defValueType3)[number];
  correct_answer: string;
}

export interface IGroupings {
  word: string;
  category: string;
}

export interface IDefinitions {
  value: string;
  title:string;
  definition: string;
}

export interface ISecondaryExercises {
  multiple_choice_true_false?: Array<IMultipleChoiceTrueFalse>;
  matching_option_gap_fill?: Array<IMatchingOptionGapFill>;
  groupings?: Array<IGroupings>;
}

export type ISecondaryKeysExercises =
  | "multiple_choice_true_false"
  | "matching_option_gap_fill"
  | "groupings";

export const defSecondaryKeysExercises: Array<ISecondaryKeysExercises> = [
  "multiple_choice_true_false",
  "matching_option_gap_fill",
  "groupings",
];

export interface IExercise {
  id: string;
  name: string;
  type: string;
  category: string;
  duration: number;
  text: string;
  audio_file: any;
  img_file: any;
  exercises: ISecondaryExercises;
  definitions: Array<IDefinitions>;
  hasDeadLine: boolean;
  lang: Array<string>;
  english_level: string;
  is_precourse: boolean;
}

export const defDefinition: IDefinitions = {
  value: "",
  title:"",
  definition: "",
};

export const defExercise: IExercise = {
  id: "",
  name: "",
  type: "",
  category: "",
  duration: 10,
  text: "",
  audio_file: null,
  img_file: null,
  exercises: {},
  definitions: [],
  hasDeadLine: true,
  lang: [],
  english_level: "",
  is_precourse: false,
};

export const defMultipleChoiceTrueFalseOption: {
  option: string;
  is_correct: boolean;
} = {
  option: "",
  is_correct: false,
};

export const defMultipleChoiceTrueFalse: IMultipleChoiceTrueFalse = {
  question: "",
  type: "true_false",
  options: [
    clone(defMultipleChoiceTrueFalseOption),
    clone(defMultipleChoiceTrueFalseOption),
    clone(defMultipleChoiceTrueFalseOption),
  ],
};

export const defMatchingOptionGapFill: IMatchingOptionGapFill = {
  statement: "",
  type: "matching_option",
  correct_answer: "",
};

export const defGroupings: IGroupings = {
  word: "",
  category: "",
};

export const defKeysExercises = {
  multiple_choice_true_false: clone(defMultipleChoiceTrueFalse),
  matching_option_gap_fill: clone(defMatchingOptionGapFill),
  groupings: clone(defGroupings),
};

export type ITypeExercises =
  | "multiple_choice"
  | "true_false"
  | "matching_option"
  | "gap_fill"
  | "groupings";

export const defKeysExercisesArray: Array<ITypeExercises> = [
  "multiple_choice",
  "true_false",
  "matching_option",
  "gap_fill",
  "groupings",
];
