import { useEffect, useState } from "react";
import { clone } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/main";
import { LinearProgress } from "@mui/material";
import useAPI from "@/api/useAPI";
import BasePage from "@/components/Page/Base";
import useProductApi from "@/api/useProductApi";
import ProductDialog from "@/components/Prodotti/ProductDialog";
import ProductsTable from "@/components/Prodotti/ProductsTable";
import DiscountDialog from "@/components/Prodotti/DiscountDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export const QUERY_GET_TOOGLE = ["toogle-add-to-languages-for-select"];
export const API_GET_TOOGLE =
  "/v1/prodotti/get-toogle-add-to-languages-for-select";

const Prodotti = () => {
  const { userAdmin, isAdmission, snackHandler } = useAuth();
  const { callDeleteProduct, callGetProducts, callHideProduct } =
    useProductApi();
  const { data, isLoading } = callGetProducts();

  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<any>>([]);
  const [dataToogle, setDataToogle] = useState<any>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Products | null>(null);
  const [discountCodes, setDiscountCodes] = useState<Array<any>>([]);
  const [openHideDialog, setOpenHideDialog] = useState<boolean>(false);
  const [showDialogDiscount, setShowDialogDiscount] = useState<boolean>(false);

  const { data: dataGetToogle } = useQuery(QUERY_GET_TOOGLE, () =>
    useAPI(API_GET_TOOGLE)
  );


  const toogleProductFormDialog = (data: Products, isCopy: boolean = false) => {
    if (!data) return;
    setIsCopy(isCopy);
    setSelectedRow(data);
    setShowDialog((prev) => !prev);
  };

  const toogleConfirmDeleteDialog = (products: Products, count: number) => {
    if (!data) return;

    if (count > 0) {
      snackHandler("Cannot delete product with active subscriptions", "error");
      return;
    }

    setSelectedRow(products ? products : null);
    setOpenConfirm((prev) => !prev);
  };

  const toogleConfirmHideDialog = (products: Products) => {
    if (!data) return;
    setSelectedRow(products ? products : null);
    setOpenHideDialog((prev) => !prev);
  };

  const handleToogleAddToLanguagesForSelect = async (
    product_id: number,
    lang: string,
    add: boolean = true
  ) => {
    const old = clone(dataToogle);

    setDataToogle((p: any) => {
      const t = { ...p };
      if (add) {
        t[lang].push(product_id);
      } else {
        t[lang] = t[lang].filter((o: number) => o != product_id);
      }
      return t;
    });

    const res = await useAPI(
      "/v1/prodotti/toogle-add-to-languages-for-select",
      { add, lang, product_id }
    );

    if (!res?.status) {
      snackHandler("Failed edit language product!", "error");
      setDataToogle(old);
      return;
    }

    queryClient.invalidateQueries(QUERY_GET_TOOGLE);
  };

  useEffect(() => {
    if (!data?.products?.length) return;
    setProducts(data.products);
    setDiscountCodes(data.discount_codes);
  }, [data, isLoading]);

  useEffect(() => {
    if (dataGetToogle) {
      setDataToogle(dataGetToogle);
    }
  }, [dataGetToogle]);

  if (isLoading) return <LinearProgress />;

  return (
    <BasePage title={"Products" + (isAdmission ? " admission" : "")}>
      <ProductsTable
        data={products}
        dataToogle={dataToogle}
        setShowDialog={setShowDialog}
        setShowDialogDiscount={setShowDialogDiscount}
        toogleProductFormDialog={toogleProductFormDialog}
        toogleConfirmHideDialog={toogleConfirmHideDialog}
        toogleConfirmDeleteDialog={toogleConfirmDeleteDialog}
        handleToogleAddToLanguagesForSelect={
          handleToogleAddToLanguagesForSelect
        }
      />

      {showDialog && (
        <ProductDialog
          show={showDialog}
          data={selectedRow}
          isCopy={isCopy}
          setData={setSelectedRow}
          setIsCopy={setIsCopy}
          hideDialog={setShowDialog}
        />
      )}

      {showDialogDiscount && (
        <DiscountDialog
          data={discountCodes}
          show={showDialogDiscount}
          hideDialog={setShowDialogDiscount}
        />
      )}

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          if (selectedRow?.token)
            callDeleteProduct.mutate({
              token: userAdmin.token,
              prod_tk: selectedRow.token,
              callback: () => setOpenConfirm(false),
            });
        }}
      >
        Do you want to delete this product?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openHideDialog}
        onClose={() => setOpenHideDialog(false)}
        onConfirm={() => {
          if (selectedRow?.token)
            callHideProduct.mutate({
              token: userAdmin.token,
              prod_tk: selectedRow?.token,
              callback: () => setOpenHideDialog(false),
            });
        }}
      >
        Do you want to hide this product?
      </ConfirmationDialog>
    </BasePage>
  );
};

export default Prodotti;
