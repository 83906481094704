import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";

export interface INStatus {
  Admin: string;
  NoBooked: string;
  MailSent: string;
  ToBeCalled: string;
  UpsellingScheduled: string;
  UpsellingDone: string;
  ToBeConverted: string;
  Reschedule: string;
  WASent: string;
  InterestedInTheFuture: string;
  StandBy: string;
  OfferRejected: string;
  NoShow: string;
  NotInterested: string;
  Upsold: string;
}

export interface INButtonCup {
  open: boolean;
  template: string;
  showConfirm: boolean;
  isLoadingConfirm: boolean;
}

export const defaultButtonCup: INButtonCup = {
  open: false,
  template: "",
  showConfirm: false,
  isLoadingConfirm: false,
};

export type TypeTemplate =
  | ""
  | "info"
  | "comments"
  | "otherStatus"
  | "email"
  | "changeEnglishLevel";

export interface INAnchorsEl {
  open?: boolean;
  value?: any;
  element?: any;
  content?: any;
  buttons?: Array<string>;
  template?: TypeTemplate;
}

export const defaultAnchorEl: INAnchorsEl = {
  open: false,
  value: "",
  element: "",
  content: "",
  buttons: [],
  template: "",
};

export interface INDialogsEl {
  firstMeeting: { value: boolean };
  templateEmail: { value: boolean };
  updateMeeting: { value: boolean };
  confirmPayment: { value: boolean };
}

export const defaultDialogsEl: INDialogsEl = {
  firstMeeting: { value: false },
  templateEmail: { value: false },
  updateMeeting: { value: false },
  confirmPayment: { value: false },
};

export interface INUpdateRow {
  toast?: string;
  values?: any;
  otherRows?: any;
  tokenUser?: string;
  activeApi?: boolean;
}

export const defaultFormMeetingDone: IFormMeetingDone = {
  id: null,
  notes: null,
  pacchetto: null,
  english_lvl: null,
  interviewer: null,
  newStartingMonth: null,
};

export interface INConfirmDialog {
  text: string;
  open: boolean;
  title: any;
  onAgree: null | Function;
  onDisagree: null | Function;
}

export const defaultConfirmDialog: INConfirmDialog = {
  text: "",
  open: false,
  title: "",
  onAgree: null,
  onDisagree: null,
};

export interface INCodeValues {
  code: string;
  product?: string;
  expires_at: number;
  newStartingMonth?: string;
}

export interface INStatus {
  Admin: string;
  NoBooked: string;
  MailSent: string;
  ToBeCalled: string;
  UpsellingScheduled: string;
  UpsellingDone: string;
  ToBeConverted: string;
  Reschedule: string;
  InterestedInTheFuture: string;
  StandBy: string;
  OfferRejected: string;
  NoShow: string;
  NotInterested: string;
  Upsold: string;
}

export interface INButtonCup {
  open: boolean;
  template: string;
  showConfirm: boolean;
  isLoadingConfirm: boolean;
}

export interface INAnchorsEl {
  open?: boolean;
  value?: any;
  element?: any;
  content?: any;
  buttons?: Array<string>;
  template?: TypeTemplate;
}

export interface INDialogsEl {
  firstMeeting: { value: boolean };
  templateEmail: { value: boolean };
  updateMeeting: { value: boolean };
  confirmPayment: { value: boolean };
}

export interface INUpdateRow {
  toast?: string;
  values?: any;
  otherRows?: any;
  tokenUser?: string;
  activeApi?: boolean;
}

export interface INConfirmDialog {
  text: string;
  open: boolean;
  title: any;
  onAgree: null | Function;
  onDisagree: null | Function;
}

export interface INCodeValues {
  code: string;
  product?: string;
  expires_at: number;
  newStartingMonth?: string;
}

import { DefaultResponse } from "@/interfaces";

// Discount code interface
export interface DiscountCode {
  id: string;
  code: string | "";
  value: string;
}

// Product interface
export interface Product {
  id: number;
  product_tag: string;
}

// Payment interface
export interface Payment {
  id: number;
  token_user: string;
  amount: number;
  paid_for: string;
  product_id: number;
  product: Product;
}

// Product detail interface
export interface ProductDetail {
  mese_fine: string;
  product_tag: string;
  pivot: {
    user_id: number;
    product_id: number;
    id: number;
    start_date: string;
    end_date: string;
  };
}

// Meeting interface
export interface Meeting {
  id: string;
  notes: string;
  pacchetto: string;
  english_lvl: string;
  interviewer: string;
  newStartingMonth: string;
  start: string;
  end: string;
  link: string | null;
  calendar: string;
}

// Updates interface
export interface Updates {
  status: string;
  owned_by: string;
  date: string;
  [key: string]: any;
}

// Meetings interface
export interface Meetings {
  meet: Meeting[];
  summary: string;
}

// CRM Upselling interface
export interface CrmUpselling {
  id: number;
  token_user: string;
  status: string;
  updates: Updates;
  meetings: Meetings;
  meeting_date: string | null;
  meeting_status: string | null;
  convert_status: string | null;
  comments: string | null;
  owned_by: string;
  created_at: string;
  updated_at: string;
}

// User interface
export interface CrmUpsellingUser {
  id: number;
  token: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  english_level: string;
  ultimo_mese_inizio: string | null;
  created_at: string;
  fullName: string;
  end_date: string;
  ltv: number;
  feedbacks: string | null;
  count_feedbacks: string | null;
  status: string;
  upselling_tracks: string | null;
  upselling_tracks_count: number;
  owned_by: string;
  crm_upselling: CrmUpselling;
  payments: Payment[];
  products: ProductDetail[];
  discount_codes: DiscountCode[];
  user_events: string[];
}

// CRM Upselling Pagination interface
export interface CrmUpsellingPagination {
  current_page: number;
  data: CrmUpsellingUser[];
  per_page: number;
  total: number;
}

// Root interface to include all data
export interface CrmUpsellingList {
  codes: DiscountCode[];
  status: boolean;
  owned_by: string[];
  endMonth: string[];
  crmUpselling: CrmUpsellingPagination;
  newStartingMonth: string[];
}

//responses
export interface UpdateMeetingResult extends DefaultResponse {
  newStartingMonth: string[];
}

export interface UpdateMeetingOtherResult extends DefaultResponse {
  message: string;
}

export interface SendTemplateMailResult extends DefaultResponse {
  discount: string;
  success: boolean;
  errors?: object
}

//UPSOLD INTERFACE
