import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/save.svg";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import SelectOwnedByFilter from "@/components/Crm/SelectOwnedByFilter";
import useAuth from "@/hooks/useAuth";
import { queryClient } from "@/main";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useCrmContext } from "../state/CrmV3Context";

export default ({ user, open, onClose }) => {
  const { snackHandler } = useAuth();
  const { KEY_QUERY, queryResult } = useCrmContext();

  const [data, setData] = useState<any>({});

  const onChangeData = ({ target: { name, value } }) =>
    setData((p: any) => ({ ...p, [name]: value }));

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!user?.token) {
      snackHandler("User token not found!", "error");
      return;
    }

    const res = await useAPI(`/v1/upselling/crm/edit/${user?.token}`, data);

    if (res?.status == false) {
      snackHandler(res?.message ?? "Failed edit user!", "error");
      return;
    }

    snackHandler("Success edit user!");
    onClose();
    queryClient.invalidateQueries(KEY_QUERY);
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>Edit</EduModalTitle>

      <EduModalBody
        width="100%"
        onSubmit={handleSubmit}
        component="form"
        className="flex flex-col gap-5 p-8"
      >
        <SelectOwnedByFilter
          useRemove
          items={queryResult?.data?.owned_by ?? []}
          ownedBy={data?.owned_by ?? user?.owned_by ?? ""}
          onChange={(e) => {
            onChangeData({
              target: { name: "owned_by", value: e.target.value },
            });
          }}
        />

        <Box className="flex flex-row-reverse">
          <Button
            type="submit"
            variant="outlined"
          >
            <img
              src={addIcon}
              className="icon"
            />
            Save
          </Button>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};
