import deleteIcon from "@/assets/icons/delete.svg";
import meetingDoneIcon from "@/assets/icons/meeting-done.svg";
import noShowIcon from "@/assets/icons/no-show.svg";
import rescheduledIcon from "@/assets/icons/rescheduled.svg";
import toBeCallIcon from "@/assets/icons/to-be-call.svg";
import BUTTONS_CRM_WHATSAPP from "@/components/assets/BUTTONS_CRM_WHATSAPP";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AddInterestedEventDialog, {
  IAddInterestedEventDialog,
} from "@/components/Crm/AddInterestedEventDialog";
import ConfirmPaymentDialog from "@/components/Crm/ConfirmPaymentDialog";
import DialogNewStatusToBeConverted, {
  IRef as IDialogNewStatusToBeConvertedRef,
} from "@/components/Crm/DialogNewStatusToBeConverted";
import InfoCrmDialog from "@/components/Crm/InfoCrmDialog";
import MailDialog, {
  IRef as IRefMailDialog,
} from "@/components/Crm/MailDialog";
import MeetingComponent, {
  IFormMeetingDone,
} from "@/components/Crm/MeetingComponent";
import UserInfoDialog from "@/components/Crm/UserInfoDialog";
import ViewCrmDialog from "@/components/Crm/ViewCrmDialog";
import { convert } from "@/components/useHelpers";
import useAuth from "@/hooks/useAuth";
import { UserOrders } from "@/interfaces";
import EditOrder, {
  IRef as IRefEditOrder,
} from "@/pages/Orders/components/EditOrder";
import { MoveUpOutlined, WavingHand } from "@mui/icons-material";
import { createRef, useEffect, useState } from "react";
import CrmCall from "../../CrmCall";
import { useContextCrm } from "../Context";
import functions from "../functions";
import Comments from "./Comments";
import CreateLead, { IRef as IRefCreateLead } from "./CreateLead";
import SimplyDrawer from "./SimplyDrawer";
import AddLinkForMeet from "./AddLinkForMeet";

export default () => {
  const context = useContextCrm();
  const getFunctions = functions(context);
  const { userAdmin } = useAuth();

  const refEditOrder = createRef<IRefEditOrder>();
  const refMailDialog = createRef<IRefMailDialog>();
  const createLeadRef = createRef<IRefCreateLead>();
  const addInterestedEventDialogRef = createRef<IAddInterestedEventDialog>();
  const dialogNewStatusToBeConvertedRef =
    createRef<IDialogNewStatusToBeConvertedRef>();

  const [product, setProduct] = useState<any>();
  const [amountProduct, setAmountProduct] = useState<number>(100);

  useEffect(() => {
    switch (context.dialog?.component) {
      case "create_lead":
        createLeadRef.current?.open();
        break;
      case "new_status_to_be_converted_interested_event":
        dialogNewStatusToBeConvertedRef.current?.open(
          context.dialog?.row ?? context.rowSelected
        );
        break;
      case "interested_event":
        addInterestedEventDialogRef.current?.open(
          context.dialog?.row ?? context.rowSelected
        );
        break;
      case "choose_ini_payment":
        refMailDialog.current?.open(context.dialog?.row ?? context.rowSelected);
        break;
      case "confirm_payment":
        const row = context?.dialog?.row ?? context?.rowSelected;

        const lastProductFromMeeting = convert(
          context.dialog?.row?.meeting ?? context.rowSelected?.meeting ?? {}
        )
          ?.meet?.filter((m: any) => m?.pacchetto)
          ?.at(-1)?.pacchetto;

        const productToSet = context.products?.products?.find(
          (p) => p.token == lastProductFromMeeting
        );

        setProduct(productToSet);

        const prices = productToSet?.prices?.find(
          (p: any) => p?.country == (row?.lang ?? "it")
        );

        let price = 100;

        if (prices?.price) {
          price = Number(prices.price) * 100;
        } else if (productToSet?.price) {
          price = Number(productToSet.price);
        }

        setAmountProduct(Number(price));
        break;
      case "show_orders":
        refEditOrder.current?.open(
          context.dialog?.row?.orders ?? context.rowSelected?.orders,
          (context.dialog?.row ?? context.rowSelected) as UserOrders
        );
      default:
        break;
    }
  }, [
    context.dialog,
    context.dialog?.row,
    context.dialog?.component,
    context.rowSelected,
  ]);

  switch (context.dialog?.component) {
    case "create_lead":
      return <CreateLead ref={createLeadRef} />;
    case "add_meeting_calendar":
    case "add_meeting_to_be_called":
      return (
        <SimplyDrawer
          status={
            context.dialog?.component == "add_meeting_calendar"
              ? "Colloquio fissato"
              : "Da richiamare"
          }
          context={context}
          onUpdateRow={getFunctions.handleAddMeetingCalendar}
        />
      );
    case "view_details_updates_lead":
      return (
        <ViewCrmDialog
          open={Boolean(context.dialog?.component)}
          user={context.dialog?.row ?? context.rowSelected}
          onClose={() => context.setDialog(undefined)}
        />
      );
    case "info_meetings":
      return (
        <InfoCrmDialog
          open={Boolean(context.dialog?.component)}
          user={context.dialog?.row ?? context.rowSelected}
          onClose={() => context.setDialog(undefined)}
        />
      );
    case "move_to_upselling":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          title={
            <>
              <MoveUpOutlined
                fontSize="small"
                className="mr-2"
              />
              Move to Upselling
            </>
          }
          onClose={() => context.setDialog(undefined)}
          onConfirm={() => getFunctions.handleMoveToUpselling()}
        >
          Are you sure to move{" "}
          <b>
            {context.dialog?.row?.name} {context.dialog?.row?.lname}
          </b>{" "}
          to upselling?
        </ConfirmationDialog>
      );
    case "send_welcome_message":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={() =>
            getFunctions.handleSendTemplatesWithWati([
              "1_messaggio",
              "2_messaggio",
            ])
          }
        >
          Do you want send welcome message{" "}
          <WavingHand
            color="primary"
            fontSize="small"
          />{" "}
          to{" "}
          <b>
            {context.dialog?.row?.name} {context.dialog?.row?.lname}
          </b>{" "}
          with whatsapp?
        </ConfirmationDialog>
      );
    case "reminder_send_welcome_message":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={() => getFunctions.handleSendMessagesWhatsApp(null)}
        >
          Do you want send reminder welcome message{" "}
          {BUTTONS_CRM_WHATSAPP?.[context.dialog?.template]?.icon} to{" "}
          <b>
            {context.dialog?.row?.name} {context.dialog?.row?.lname}
          </b>{" "}
          with whatsapp?
        </ConfirmationDialog>
      );
    case "reminder1":
    case "reminder2":
    case "reminder3":
    case "reminder3.1":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={() =>
            getFunctions.handleSendMessagesWhatsApp(context.dialog?.component)
          }
        >
          Do you send the reminder{" "}
          {BUTTONS_CRM_WHATSAPP?.[context.dialog?.template]?.icon} to{" "}
          <b>
            {context.dialog?.row?.name} {context.dialog?.row?.lname}
          </b>{" "}
          with whatsapp?
        </ConfirmationDialog>
      );
    case "crm_call":
      return (
        <CrmCall
          open={Boolean(context.dialog?.component)}
          data={{
            user: context.dialog?.row ?? context.rowSelected,
            buttons: {
              toBeCalled: { status: "Da richiamare" },
              scheduleMeeting: { status: "Colloquio fissato" },
              noReply: { status: "Non risposto" },
              incorectNumber: { status: "Numero non valido" },
            },
          }}
          onClose={() => context.setDialog(undefined)}
          functions={{ onUpdateRow: getFunctions.handleUpdateRowCrmCall }}
        />
      );
    case "update_meeting":
      return (
        <MeetingComponent
          useDataGetToogle
          open={Boolean(context.dialog?.component)}
          data={{
            user: context.dialog?.row ?? context.rowSelected,
            userAdmin,
            buttons: {
              noShow: {
                icon: noShowIcon,
                name: "No show",
                status: "Assente al colloquio",
              },
              reschedule: {
                icon: rescheduledIcon,
                name: "Reschedule",
                status: "Colloquio fissato",
              },
              toBeCall: {
                icon: toBeCallIcon,
                name: "To be called",
                status: "Da richiamare",
              },
              meetingDone: {
                icon: meetingDoneIcon,
                name: "Meeting done",
                status: "Colloquio fatto",
                form: context.eventSelected,
                setForm: (key: string, value: string) =>
                  context.setEventSelected(
                    (p: IFormMeetingDone | undefined) => ({
                      ...(p ?? {}),
                      [key]: value,
                    })
                  ),
              },
            },
          }}
          onClose={() => context.setDialog(undefined)}
          functions={{
            onUpdateRow: getFunctions.handleUpdateRowCrmCall,
            onMeetingDone: getFunctions.handleUpdateEventMeeting,
            onMeetingDoneOther: getFunctions.updateMeetingDoneOther,
          }}
        />
      );
    case "new_status_to_be_converted_interested_event":
      return (
        <>
          <DialogNewStatusToBeConverted
            ref={dialogNewStatusToBeConvertedRef}
            invalidateQuery={context.getInvalidateQuery}
            onInterestedInTheFuture={(row) =>
              addInterestedEventDialogRef.current?.open(row)
            }
          />

          <AddInterestedEventDialog
            ref={addInterestedEventDialogRef}
            dialogNewStatusToBeConvertedRef={dialogNewStatusToBeConvertedRef}
          />
        </>
      );
    case "interested_event":
      return (
        <AddInterestedEventDialog
          ref={addInterestedEventDialogRef}
          dialogNewStatusToBeConvertedRef={dialogNewStatusToBeConvertedRef}
        />
      );
    case "choose_ini_payment":
      return (
        <MailDialog
          ref={refMailDialog}
          onInvalidateQuery={context.getInvalidateQuery}
        />
      );
    case "send_email_incorect_number":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={getFunctions.handleSendEmailIncorectNumberSubmit}
        >
          Did you send email incorect number to {context.dialog?.row?.name}{" "}
          {context.dialog?.row?.lname}?
        </ConfirmationDialog>
      );
    case "show_comments":
      return <Comments />;
    case "show_orders":
      return <EditOrder ref={refEditOrder} />;
    case "confirm_payment":
      return (
        <ConfirmPaymentDialog
          open={Boolean(context.dialog?.component)}
          data={{
            user: context.dialog?.row ?? context.rowSelected,
            products: context.products?.products,
          }}
          onClose={() => context.setDialog(undefined)}
          onSubmit={getFunctions.handleSubmitConfirmPayment}
          defaultValues={{
            amount: String(amountProduct / 100),
            paidFor: product?.product_tag,
            pacchetto: product?.token,
          }}
        />
      );
    case "send_discount_code":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => {
            context.setDialog(undefined);
            context.setPopover(undefined);
          }}
          onConfirm={getFunctions.handleAssignDiscountCode}
        >
          Did you send the discount code to {context.dialog?.row?.name}{" "}
          {context.dialog?.row?.lname}?
        </ConfirmationDialog>
      );
    case "move_to_english_admission":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={getFunctions.handleMoveToAdmission}
        >
          Do you want to move {context.dialog?.row?.name}{" "}
          {context.dialog?.row?.lname}?
        </ConfirmationDialog>
      );
    case "also_add_on_other_version":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          onClose={() => context.setDialog(undefined)}
          onConfirm={getFunctions.handleAlsoAddOnOtherVersionApi}
        >
          Do you want to also add on{" "}
          {context.dialog?.row?.tutoring ? "English" : "Admission"}{" "}
          {context.dialog?.row?.name} {context.dialog?.row?.lname}?
        </ConfirmationDialog>
      );
    case "edit_lead":
      return (
        <UserInfoDialog
          open={Boolean(context.dialog?.component)}
          user={context.dialog?.row ?? context.rowSelected}
          onClose={() => context.setDialog(undefined)}
          onSubmit={getFunctions.handleEditLead}
        />
      );
    case "delete_lead":
      return (
        <ConfirmationDialog
          open={Boolean(context.dialog?.component)}
          title={
            <>
              <img src={deleteIcon} /> DELETE LEAD
            </>
          }
          onClose={() => context.setDialog(undefined)}
          onConfirm={getFunctions.handleDelete}
        >
          Are you sure to <b>delete</b> lead{" "}
          <b>
            {context.dialog?.row?.name ?? context.rowSelected?.name}{" "}
            {context.dialog?.row?.lname ?? context.rowSelected?.lname}
          </b>
          ?
        </ConfirmationDialog>
      );
    case "add_link_for_meet":
      return <AddLinkForMeet />;
    default:
      return null;
  }
};
