import { getAndRemove } from "@/components/useHelpers";
import useAuth from "@/hooks/useAuth";
import useDGS from "@/hooks/useDataGridSort";
import { queryClient } from "@/main";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_FORM_MEETING_DONE } from "./constants";
import {
  IContextCrm,
  IDialog,
  IImgsCalendars,
  IPopover,
  IProducts,
} from "./interfaces";

const AuthCrm = createContext<IContextCrm>({
  page: 1,
  setPage: () => {},
  rows: [],
  setRows: () => {},
  dataAPI: {},
  setDataAPI: () => {},
  products: {
    products: [],
    discount_codes: [],
  },
  setProducts: () => {},
  dialog: undefined,
  setDialog: () => {},
  filters: {},
  setFilters: () => {},
  popover: undefined,
  setPopover: () => {},
  isLoading: false,
  setIsLoading: () => {},
  rowSelected: undefined,
  setRowSelected: () => {},
  eventSelected: DEFAULT_FORM_MEETING_DONE,
  setEventSelected: () => {},
  sorts: [],
  setSorts: () => {},
  commentSelected: undefined,
  setCommentSelected: () => {},
  pagination: {
    total: 1,
    last_page: 1,
    current_page: 1,
  },
  keyQueryCrm: [],
  getInvalidateQuery: () => {},
  utmItems: { utm_source: [], utm_campaign: [] },
  setUtmItems: () => {},
  imgsCalendars: {},
  setImgsCalendars: () => {},
  openSelectCalendar: false,
  setOpenSelectCalendar: () => {},
  globalAnchorEl: undefined,
  setGlobalAnchorEl: () => {},
});

const Context = ({ children }) => {
  const { isAdmission } = useAuth();

  const [rows, setRows] = useState<Array<any>>([]);
  const [dialog, setDialog] = useState<IDialog>();
  const [dataAPI, setDataAPI] = useState<any>({});
  const [popover, setPopover] = useState<IPopover>();
  const [products, setProducts] = useState<IProducts>({
    products: [],
    discount_codes: [],
  });
  const [utmItems, setUtmItems] = useState<{
    utm_source: Array<string>;
    utm_campaign: Array<string>;
  }>({ utm_source: [], utm_campaign: [] });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>({});
  const [rowSelected, setRowSelected] = useState<any>();
  const [eventSelected, setEventSelected] = useState<any>(
    DEFAULT_FORM_MEETING_DONE
  );
  const [imgsCalendars, setImgsCalendars] = useState<{
    [x: string]: IImgsCalendars;
  }>({});
  const [globalAnchorEl, setGlobalAnchorEl] = useState<any>();
  const [commentSelected, setCommentSelected] = useState<any>();
  const [openSelectCalendar, setOpenSelectCalendar] = useState<boolean>(false);

  const [filtersURL, setFilters] = useSearchParams();
  const filters = Object.fromEntries(filtersURL.entries());

  const val = getAndRemove(filters, ["page"]);

  const page = Number(val?.page ?? 1);

  const { sortModel: sorts, onSortModelChange: setSorts } = useDGS({
    filters: filtersURL,
    setFilters,
  });

  const keyQueryCrm = ["crm", filters, sorts, page, isAdmission];

  const setPage = (value: number) => {
    setFilters((f) => {
      if (value != 1) {
        f.set("page", value.toString());
      } else {
        f.delete("page");
      }
      return f;
    });
  };

  const getInvalidateQuery = () => queryClient.invalidateQueries(keyQueryCrm);

  const authCrmValues: IContextCrm = {
    page,
    setPage,
    rows,
    setRows,
    dataAPI,
    setDataAPI,
    products,
    setProducts,
    dialog,
    setDialog,
    filters,
    setFilters,
    popover,
    setPopover,
    isLoading,
    setIsLoading,
    rowSelected,
    setRowSelected,
    eventSelected,
    setEventSelected,
    commentSelected,
    setCommentSelected,
    sorts,
    setSorts,
    pagination,
    keyQueryCrm,
    getInvalidateQuery,
    imgsCalendars,
    setImgsCalendars,
    utmItems,
    setUtmItems,
    openSelectCalendar,
    setOpenSelectCalendar,
    globalAnchorEl,
    setGlobalAnchorEl,
  };

  useEffect(() => {
    if (!dataAPI?.data?.length) return;

    setRows(dataAPI.data);

    if (dataAPI?.last_page != 1 || dataAPI?.last_page != dataAPI?.last_page) {
      setPagination({
        total: dataAPI?.total || 1,
        last_page: dataAPI?.last_page || 1,
        current_page: dataAPI?.current_page || 1,
      });
    }
  }, [dataAPI?.data]);

  return <AuthCrm.Provider value={authCrmValues}>{children}</AuthCrm.Provider>;
};

export const useContextCrm = () => useContext(AuthCrm);

export default Context;
