import {
  ARRAY_LABEL_OPTIONS,
  NEW_STATUS_FOR_CALL_CENTER,
} from "@/api/useCrmApi";
import callIcon from "@/assets/icons/call.svg";
import commentIcon from "@/assets/icons/comments.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import discountIcon from "@/assets/icons/discount.svg";
import editIcon from "@/assets/icons/edit.svg";
import eventIcon from "@/assets/icons/event.svg";
import mailIcon from "@/assets/icons/mail.svg";
import videoIcon from "@/assets/icons/video.svg";
import viewIcon from "@/assets/icons/view.svg";
import {
  ForwardIcon,
  HardwareRoundedIcon,
  InfoIcon,
  MessageRoundedIcon,
  PriceCheckIcon,
  SocialDistanceIcon,
  TimerRoundedIcon,
} from "@/components";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import ChooseIniPayment from "@/components/Crm/ChooseIniPayment";
import {
  IComments,
  sortDescNotesByCreatedAt,
} from "@/components/Crm/CommentDialog";
import { getPaymentLink } from "@/components/Crm/MailDialog";
import ProofRejection from "@/components/Crm/ProofRejection";
import FlagRender from "@/components/FlagRender";
import MyTooltip from "@/components/MyTooltip";
import {
  convert,
  euro,
  fUpperWords,
  jS,
  objectInvertKeyValue,
} from "@/components/useHelpers";
import useAuth from "@/hooks/useAuth";
import {
  Add,
  Circle,
  ContactSupport,
  CopyAll,
  Dialpad,
  Done,
  Edit,
  Forward,
  Handshake,
  HardwareRounded,
  Info,
  LowPriority,
  Mail,
  MoreVert,
  MoveUpOutlined,
  OpenInNew,
  PeopleOutlineRounded,
  ShoppingCart,
  Signpost,
  ThumbDownRounded,
  TimerRounded,
  WavingHand,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  useTheme,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import classNames from "classnames";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useContextCrm } from "./Context";
import functions, { getColor } from "./functions";
import { IDialog } from "./interfaces";

const LANDING_BASE = import.meta.env.VITE_LANDING;

const BtnMeetingLink = ({
  m,
  i,
  dateTime,
  urlLinkRecord,
  copyToClipboard,
}: {
  m: any;
  i: number;
  dateTime: string;
  urlLinkRecord: string;
  copyToClipboard: (urlLinkRecord: string) => void;
}) => (
  <Box className="flex justify-between">
    <Button
      sx={{ flexGrow: 1 }}
      key={(m?.id ?? "") + i}
      title={"Open: " + (m?.urlLinkRecord ?? dateTime)}
      onClick={() => urlLinkRecord && window.open(urlLinkRecord, "_blank")}
      className="flex gap-2 items-center"
    >
      <OpenInNew fontSize="small" />
      <span>{dateTime}</span>
    </Button>

    <Button
      title="Copy url link record"
      onClick={() => copyToClipboard(urlLinkRecord)}
    >
      <CopyAll fontSize="small" />
    </Button>
  </Box>
);

const BtnMeetingNotLink = ({
  i,
  row,
  context,
  dateTime,
  version_meet,
}: {
  i: number;
  row: any;
  context: { setDialog: Dispatch<SetStateAction<IDialog>> };
  dateTime: string;
  version_meet: string;
}) => (
  <Button
    fullWidth
    onClick={() =>
      context.setDialog({
        component: "add_link_for_meet",
        data: {
          version_meet,
          user_id: row?.id,
          date_time: dateTime,
          meet_index: i,
        },
      })
    }
    className="flex gap-2 items-center !justify-start"
  >
    <Add />
    <span>{dateTime}</span>
  </Button>
);

export default (): Array<GridColDef> => {
  const theme = useTheme();
  const context = useContextCrm();
  const getFunctions = functions(context);
  const { isAdmission, activity, copyToClipboard, userAdmin } = useAuth();

  const numMaxBox = useMemo(() => {
    let nf = 0;

    context.rows.forEach((row) => {
      const m = ["hasReschedule", "has_meeting_done"].filter((r) =>
        Boolean(row?.[r])
      );

      const n = [
        m?.length ? m[0] : "hasReschedule",
        "last_product_from_last_duplicate_letter_A",
        "last_product_from_last_duplicate_letter_D",
        "has_discount",
      ].filter((r) => Boolean(row?.[r])).length;

      if (nf < n) {
        nf = n;
      }
    });

    return nf;
  }, [jS(context.rows)]);

  const menuItemsStatus = useMemo(
    () =>
      [
        ...new Set([
          ...Object.values(ARRAY_LABEL_OPTIONS),
          ...(activity == "Free" || activity == "Call center"
            ? NEW_STATUS_FOR_CALL_CENTER
            : []),
          ...(context.filters?.status
            ? [
                context.filters?.status in ARRAY_LABEL_OPTIONS
                  ? ARRAY_LABEL_OPTIONS[context.filters?.status]
                  : context.filters?.status,
              ]
            : []),
        ]),
      ]
        .sort()
        .map((option, i) => {
          const invertKeyValue = objectInvertKeyValue(ARRAY_LABEL_OPTIONS);

          return (
            <MenuItem
              key={i}
              color="primary"
              value={option in invertKeyValue ? invertKeyValue[option] : option}
            >
              {option}
            </MenuItem>
          );
        }),
    [activity, context.filters?.status]
  );

  return [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <button
          className="flex items-center cursor-default"
          onDoubleClick={() =>
            row?.lang &&
            context.setFilters((f) => {
              f.set("lang", row?.lang);
              return f;
            })
          }
        >
          {Boolean(row?.tutoring && !isAdmission) && (
            <span title="This lead is also in the Admission">A</span>
          )}

          <FlagRender
            flag={
              row?.lang == "de"
                ? row?.phone?.includes("+41")
                  ? "ch"
                  : row?.phone?.includes("+43")
                  ? "at"
                  : "de"
                : row?.lang
            }
          />
        </button>
      ),
    },
    {
      field: "created_at",
      headerName: "Date & Time",
      width: 140,
      valueGetter: ({ row }) =>
        dayjs(row.created_at).tz("Europe/Rome").format("ddd, DD-M HH:mm"),
      renderCell: ({ row }) => {
        const updates = convert(row?.updates ?? []);

        let isLate = false;

        if (updates?.length && updates?.[0]?.date && updates?.[1]?.date) {
          const start = dayjs(updates?.[0]?.date).tz("Europe/Rome");
          const next = updates?.[1]?.date
            ? dayjs(updates?.[1]?.date).tz("Europe/Rome")
            : dayjs().tz("Europe/Rome");

          isLate = start.add(5, "minutes") < next;
        }

        const created_at = dayjs(row?.created_at);

        return (
          <MyTooltip title={created_at.format("YYYY-MM-DD HH:mm")}>
            <Box className={isLate ? "text-red-400" : ""}>
              {created_at.format("ddd, DD-M HH:mm")}
            </Box>
          </MyTooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => {
        const fullName = fUpperWords(
          `${row?.first_name || ""} ${row?.last_name || ""}`.trim()
        );

        const name = (
          <Box
            sx={{
              "&:hover .edit-phone": { display: "block" },
            }}
            color="primary"
            className={classNames(
              "w-full flex items-center gap-2 truncate overflow-hidden",
              {
                "text-[#ffcc00]": Boolean(!row?.is_valid_name),
                "max-w-[7rem]": Boolean(row?.name?.duplicate),
              }
            )}
            onMouseEnter={(e) => context.setGlobalAnchorEl(e.currentTarget)}
          >
            <button
              onClick={() => copyToClipboard(row?.email ?? fullName)}
              className="truncate hover:bg-gray-500/10 p-1 rounded"
            >
              {fullName}
            </button>

            <button
              onClick={() => {
                context.setRowSelected(row);
                context.setPopover({
                  row,
                  anchor: context.globalAnchorEl,
                  component: "edit_full_name",
                  anchorOrigin: { vertical: "bottom", horizontal: "center" },
                  transformOrigin: { vertical: "top", horizontal: "center" },
                });
              }}
              className="edit-phone hidden rounded-full p-1 px-2 pb-2 hover:bg-gray-500/10"
            >
              <Edit fontSize="small" />
            </button>
          </Box>
        );

        return (
          <MyTooltip title={fullName}>
            {row?.name?.duplicate ? (
              <Badge
                color="primary"
                badgeContent={<PeopleOutlineRounded style={{ fontSize: 10 }} />}
              >
                {name}
              </Badge>
            ) : (
              name
            )}
          </MyTooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 145,
      renderCell: ({ row }) => (
        <MyTooltip title={row?.phone}>
          <Box
            style={{
              color: Boolean(Number(row?.otp_verification_code_verified))
                ? "#28a745"
                : theme.palette.mode == "dark"
                ? "white"
                : "black",
              fontWeight: Boolean(Number(row?.otp_verification_code_verified))
                ? "bold"
                : "normal",
            }}
          >
            <Box
              sx={{
                "&:hover .edit-phone": { display: "block" },
              }}
              onMouseEnter={(e) => context.setGlobalAnchorEl(e.currentTarget)}
              className="w-full flex gap-2"
            >
              <button
                onClick={() => copyToClipboard(row?.phone?.replace("+", ""))}
                className="truncate hover:bg-gray-500/10 p-1 rounded"
              >
                {row?.phone}
              </button>

              <button
                onClick={() => {
                  context.setRowSelected(row);
                  context.setPopover({
                    row,
                    anchor: context.globalAnchorEl,
                    component: "edit_phone",
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                    transformOrigin: { vertical: "top", horizontal: "center" },
                  });
                }}
                className="edit-phone hidden rounded-full p-1 px-2 pb-2 hover:bg-gray-500/10"
              >
                <Edit fontSize="small" />
              </button>
            </Box>
          </Box>
        </MyTooltip>
      ),
    },
    {
      field: "utm_source",
      headerName: "UTM_Source",
      minWidth: 130,
      flex: 0.5,
      valueGetter: ({ row }) => row?.utm_source || "-",
      renderCell: ({ row }) =>
        row?.utm_source ? (
          <button
            className="w-full text-left truncate cursor-default"
            onDoubleClick={() =>
              context.setFilters((f) => {
                f.set("utm_source", row?.utm_source);
                return f;
              })
            }
          >
            <MyTooltip>{row?.utm_source}</MyTooltip>
          </button>
        ) : (
          "-"
        ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 210,
      flex: 1,
      renderCell: ({ row }) => (
        <Box className="w-full flex items-stretch">
          <TextField
            select
            fullWidth
            name="status"
            value={row?.status ?? ""}
            onChange={(e) =>
              getFunctions.handleChangeStatus(row.id, {
                status: e.target.value,
              })
            }
            className="grow"
          >
            {menuItemsStatus}
          </TextField>

          <button
            className="py-4 px-[0.25rem] cursor-default"
            onDoubleClick={() =>
              row?.status &&
              context.setFilters((f) => {
                f.set("status", row?.status);
                return f;
              })
            }
          />
        </Box>
      ),
    },
    ...(!numMaxBox
      ? []
      : [
          {
            field: "hasReschedule",
            headerName: "",
            width: numMaxBox == 1 ? 50 : numMaxBox == 2 ? 80 : 150,
            hideable: false,
            sortable: false,
            editable: false,
            filterable: false,
            disableExport: true,
            renderCell: ({ row }) => {
              const productLPFLDL = [
                {
                  key: "A",
                  value: convert(
                    row?.last_product_from_last_duplicate_letter_A ?? {}
                  ),
                  classBgColor: "!bg-red-400",
                },
                {
                  key: "D",
                  value: convert(
                    row?.last_product_from_last_duplicate_letter_D ?? {}
                  ),
                  classBgColor: "!bg-blue-400",
                },
              ];

              const aspectBox = { width: 30, height: 30 };

              return (
                <Box className="flex gap-1">
                  {productLPFLDL.map((l, i) => {
                    const letter = (
                      <Avatar
                        sx={aspectBox}
                        variant="rounded"
                        className={l.classBgColor}
                      >
                        <b>{l.key}</b>
                      </Avatar>
                    );

                    return Object.keys(l.value).length ? (
                      <MyTooltip
                        key={l.key + i}
                        title={
                          <Box className="flex flex-col">
                            <Box>Last lead:</Box>

                            {Boolean(l.value?.status) && (
                              <Box className="ml-4">
                                <b>Status: </b>
                                {l.value?.status}
                              </Box>
                            )}

                            {Boolean(l.value?.product_tag) ? (
                              <>
                                <Box>Info product:</Box>

                                <Box className="ml-4">
                                  <Box>
                                    {l.value?.isHidden &&
                                      !l.value?.isDeleted && (
                                        <Box>Is hidden</Box>
                                      )}
                                    {l.value?.isDeleted && (
                                      <Box>Is deleted</Box>
                                    )}
                                    {l.value?.isAdmission && (
                                      <Box>Is admission</Box>
                                    )}
                                  </Box>

                                  <Box>
                                    <Box>
                                      <b>Price:</b> {euro(l.value?.price)}
                                    </Box>

                                    <Box>
                                      <b>Product:</b> {l.value?.product_tag}
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <Box>Not has info product</Box>
                            )}
                          </Box>
                        }
                      >
                        {letter}
                      </MyTooltip>
                    ) : null;
                  })}

                  {Boolean(row?.hasReschedule) ? (
                    <MyTooltip title={"Info meetings"}>
                      <Avatar
                        sx={aspectBox}
                        variant="rounded"
                        onClick={() =>
                          context.setDialog({
                            row,
                            component: "info_meetings",
                          })
                        }
                        className="cursor-pointer !bg-yellow-400"
                      >
                        <b>R</b>
                      </Avatar>
                    </MyTooltip>
                  ) : (
                    Boolean(row?.has_meeting_done) && (
                      <MyTooltip title={"Info meetings"}>
                        <Avatar
                          sx={aspectBox}
                          variant="rounded"
                          onClick={() =>
                            context.setDialog({
                              row,
                              component: "info_meetings",
                            })
                          }
                          className="cursor-pointer !bg-gray-400"
                        >
                          <b>M</b>
                        </Avatar>
                      </MyTooltip>
                    )
                  )}

                  {Boolean(row?.waiting_list_data) &&
                    ["Da convertire"].includes(row?.status) && (
                      <MyTooltip
                        title={
                          <span>
                            Waiting list data: <br /> Start month:{" "}
                            <b>{row?.waiting_list_data?.start_month}</b>{" "}
                          </span>
                        }
                      >
                        <Avatar
                          sx={aspectBox}
                          variant="rounded"
                          className="cursor-pointer !bg-green-400"
                        >
                          <b>W</b>
                        </Avatar>
                      </MyTooltip>
                    )}

                  {Boolean(row?.has_discount) && (
                    <MyTooltip title={<span>{row?.utm_ads}</span>}>
                      <Avatar
                        sx={aspectBox}
                        variant="rounded"
                        className="cursor-pointer !bg-green-600"
                      >
                        <b>S</b>
                      </Avatar>
                    </MyTooltip>
                  )}
                </Box>
              );
            },
          },
        ]),
    {
      field: "meeting_date",
      headerName: "Meeting date",
      width: 165,
      renderCell: ({ row }) => {
        if (!row?.meeting) return "-";

        const meeting = convert(row?.meeting);

        const meet = (meeting?.meet ?? [])?.at(-1)?.start;
        const meetToBeCalled = (meeting?.meetToBeCalled ?? [])?.at(-1)?.start;

        let date: string | null = null;
        let isToBeCalled = false;

        if (meetToBeCalled) {
          if (meet && dayjs(meetToBeCalled) > dayjs(meet)) {
            date = meetToBeCalled;
            isToBeCalled = true;
          } else {
            date = meet || meetToBeCalled;
          }
        } else if (meet) {
          date = meet;
        }

        if (!date) return "-";

        const finalDate = date
          ? dayjs(date).tz("Europe/Rome")
          : dayjs(row?.meeting_date).tz("Europe/Rome");

        return (
          <MyTooltip title={finalDate.format("YYYY-MM-DD HH:mm")}>
            {isToBeCalled && "TBC"} {finalDate.format("ddd, DD-M HH:mm")}
          </MyTooltip>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      minWidth: 150,
      flex: 0.6,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        let notes: Array<IComments> = convert(row?.notes ?? []);

        const meetings: {
          meet?: Array<IComments>;
          meetToBeCalled?: Array<IComments>;
        } = convert(row?.meeting ?? {});

        const meets: Array<IComments> =
          [
            ...(meetings?.meet?.filter((e: any) => e?.notes) ?? []),
            ...(meetings?.meetToBeCalled?.filter((e: any) => e?.notes) ?? []),
          ].map((e: any) => ({
            admin: {
              name: e?.interviewer,
              lname: "",
              email: "",
            },
            created: dayjs(e?.start).format("DD-MM % HH:mm").replace("%", "at"),
            content: e?.notes,
            created_at: e?.start,
            isFromMeeting: "from meeting",
          })) ?? [];

        const firstNote = sortDescNotesByCreatedAt([...notes, ...meets])?.[0]
          ?.content;

        return (
          <MyTooltip title={firstNote ?? "empty"}>
            <Button
              className="!p-1 !min-w-0 !m-0 !w-full !flex !justify-start"
              onClick={() =>
                context.setDialog({ row, component: "show_comments" })
              }
            >
              <Box className="p-1 w-full truncate text-left">
                {firstNote || (
                  <img
                    src={commentIcon}
                    className="icon"
                  />
                )}
              </Box>
            </Button>
          </MyTooltip>
        );
      },
    },
    {
      field: "owned_by",
      headerName: "Owned by",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        if (!row?.owned_by) return "NA";

        const calendar = fUpperWords((row?.owned_by || "").trim());

        return (
          <button
            className="text-center cursor-default"
            onDoubleClick={() =>
              context.setFilters((f) => {
                f.set("owned_by", calendar);
                return f;
              })
            }
          >
            <MyTooltip title={calendar}>
              <Avatar
                alt={calendar}
                src={context.imgsCalendars?.[calendar]?.img ?? calendar}
                style={{ height: "2.5rem", width: "2.5rem" }}
              />
            </MyTooltip>
          </button>
        );
      },
    },
    {
      field: "button_records_meetings",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        if (
          ![
            "Colloquio fissato",
            "Da convertire",
            "Rejected",
            "Offerta rifiutata",
            "Interested in the future",
          ].includes(row?.status)
        ) {
          return <></>;
        }

        const meets = JSON.parse(row?.meeting ?? "{}")?.meet ?? [];
        const meetsToBeCalled =
          JSON.parse(row?.meeting ?? "{}")?.meetToBeCalled ?? [];

        if (!meets?.length && !meetsToBeCalled?.length) {
          return <></>;
        }

        const s = meets?.length > 1 ? "s" : "";

        const showLabels =
          Boolean(meets?.length) && Boolean(meetsToBeCalled?.length);

        interface IM {
          id: string | undefined | null;
          start: string;
          urlLinkRecord: string | null;
        }

        const convertDateTime = (v: undefined | string) =>
          dayjs(v).tz("Europe/Rome").format("ddd, DD-M HH:mm");

        return (
          <Dropdown
            text={<Circle fontSize="small" />}
            title={`URL${s} Record${s} from meeting${s}`}
            variant="text"
          >
            <Box
              sx={{ color: theme.palette.primary.main }}
              className="text-center"
            >
              {showLabels && <Box className="py-1">Meetings</Box>}

              {meets?.map((meet: IM, i: number) => {
                const dateTime = convertDateTime(meet?.start);

                const urlLinkRecord =
                  meet?.urlLinkRecord?.replaceAll(" ", "") ?? "";

                if (urlLinkRecord) {
                  return (
                    <BtnMeetingLink
                      {...{
                        i,
                        dateTime,
                        urlLinkRecord,
                        copyToClipboard,
                        m: meet,
                      }}
                    />
                  );
                } else {
                  return (
                    <BtnMeetingNotLink
                      {...{
                        i,
                        row,
                        context,
                        dateTime,
                        version_meet: "meet",
                      }}
                    />
                  );
                }
              })}

              <hr />

              {showLabels && <Box className="py-1">To be called</Box>}

              {meetsToBeCalled?.map((meetTBC: IM, i: number) => {
                const dateTime = convertDateTime(meetTBC?.start);

                const urlLinkRecord =
                  meetTBC?.urlLinkRecord?.replaceAll(" ", "") ?? "";

                if (urlLinkRecord) {
                  return (
                    <BtnMeetingLink
                      {...{
                        i,
                        dateTime,
                        urlLinkRecord,
                        copyToClipboard,
                        m: meetTBC,
                      }}
                    />
                  );
                } else {
                  return (
                    <BtnMeetingNotLink
                      {...{
                        i,
                        row,
                        context,
                        dateTime,
                        version_meet: "meetToBeCalled",
                      }}
                    />
                  );
                }
              })}
            </Box>
          </Dropdown>
        );
      },
    },
    {
      field: "button_1",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [
          "Offerta rifiutata",
          "No show not interested",
          "Assente al colloquio",
        ].includes(row.status) ? (
          <ProofRejection
            token={row.token}
            rejected={row?.rejection_proof}
          />
        ) : [
            "Rejected",
            "Convertito",
            "Da convertire",
            "Offerta rifiutata",
            "Interested in the future",
          ].includes(row.status) ? (
          <Button
            title="Info meetings"
            onClick={() =>
              context.setDialog({ row, component: "info_meetings" })
            }
          >
            <Info fontSize="small" />
          </Button>
        ) : ["Colloquio fissato"].includes(row?.status) ? (
          <Button
            title="Update meeting"
            onClick={() =>
              context.setDialog({
                row,
                component: "update_meeting",
              })
            }
          >
            <img
              src={videoIcon}
              className="icon no-margin"
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button_2",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        row?.status == "Colloquio fissato" ? (
          <>
            {!row?.sent_welcome_message ? (
              <Button
                title="Reminder welcome message"
                onClick={() =>
                  getFunctions.handleGenerateTemplateCrm(
                    row,
                    "reminder_4",
                    "reminder_send_welcome_message"
                  )
                }
              >
                <Handshake fontSize="small" />
              </Button>
            ) : !row?.sent_reminders?.reminder1 ? (
              <Button
                title="Timer"
                onClick={() =>
                  getFunctions.handleGenerateTemplateCrm(
                    row,
                    "reminder_1",
                    "reminder1"
                  )
                }
              >
                <TimerRounded fontSize="small" />
              </Button>
            ) : !row?.sent_reminders?.["reminder3.1"] ? (
              row?.meeting_status == "ci sarà" ? (
                <Button
                  title="Reminder after rescheduled"
                  onClick={() =>
                    getFunctions.handleGenerateTemplateCrm(
                      row,
                      "reminder_after_rescheduled",
                      undefined,
                      getFunctions.handleGetReminderLinkAfterRescheduledFallback
                    )
                  }
                >
                  <Done
                    color="primary"
                    fontSize="small"
                  />
                </Button>
              ) : (
                <Button
                  title="Question"
                  onClick={() =>
                    getFunctions.handleGenerateTemplateCrm(
                      row,
                      "reminder_3.1",
                      "reminder3.1"
                    )
                  }
                >
                  <ContactSupport fontSize="small" />
                </Button>
              )
            ) : row?.hasReschedule == 1 ? (
              <Button
                title="Reminder after rescheduled"
                onClick={() =>
                  getFunctions.handleGenerateTemplateCrm(
                    row,
                    "reminder_after_rescheduled",
                    undefined,
                    getFunctions.handleGetReminderLinkAfterRescheduledFallback
                  )
                }
              >
                <Done
                  color="primary"
                  fontSize="small"
                />
              </Button>
            ) : (
              <Done
                color="primary"
                fontSize="small"
              />
            )}
          </>
        ) : row?.status == "Colloquio fatto" ? (
          <ChooseIniPayment
            displayButton
            onClick={(iniType) =>
              context.setDialog({
                row: { ...row, iniType },
                component: "choose_ini_payment",
              })
            }
          />
        ) : row?.status == "Da convertire" ? (
          !row?.sent_reminders?.reminder3 ? (
            <Button
              title="Reminder 3"
              onClick={() =>
                getFunctions.handleGenerateTemplateCrm(
                  row,
                  "reminder_3",
                  "reminder3"
                )
              }
            >
              <HardwareRounded fontSize="small" />
            </Button>
          ) : (
            <Done
              color="primary"
              fontSize="small"
            />
          )
        ) : row?.status == "Numero non valido" ? (
          <Button
            title="Send email incorect number"
            onClick={() =>
              context.setDialog({
                row,
                component: "send_email_incorect_number",
              })
            }
          >
            <Mail fontSize="small" />
          </Button>
        ) : row?.status == "Convertito" && !row?.sent_reminders?.reminder2 ? (
          <Button
            title="Reminder 2"
            onClick={() => getFunctions.handleGenerateReminderLink2(row)}
          >
            <SocialDistanceIcon fontSize="small" />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button_3",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        ["Nuovo", "Da richiamare"].includes(
          row?.status?.replace(/\d+/g, "").trim()
        ) && ["Crina", "Oriana"].includes(userAdmin.user?.first_name) ? (
          <Button
            onClick={() =>
              context.setDialog({
                row,
                component: "send_welcome_message",
              })
            }
          >
            <WavingHand fontSize="small" />
          </Button>
        ) : (
          <Button onClick={() => getFunctions.handleOpenWhatsAppWeb(row)}>
            <i
              style={{ fontSize: 16 }}
              className="fa-brands fa-whatsapp"
            />
          </Button>
        ),
    },
    {
      field: "button_4",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          onClick={() => context.setDialog({ row, component: "dialpad_lead" })}
        >
          <Dialpad fontSize="small" />
        </Button>
      ),
    },
    {
      field: "button_5",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [
          "Nuovo",
          "Non risposto",
          "Rischedulare",
          "Da richiamare",
          "Whatsapp text",
          "Colloquio fissato",
          "Messaggio WhatsApp",
          "Waiting for feedback",
          "Assente al colloquio",
          "Assente senza avvisare",
          ...NEW_STATUS_FOR_CALL_CENTER.map((e) =>
            e?.replace(/\d+/g, "").trim()
          ),
        ].includes(row?.status?.replace(/\d+/g, "").trim()) ? (
          <Button
            onClick={() =>
              context.setDialog({ row, component: "add_meeting_calendar" })
            }
          >
            <img
              src={callIcon}
              className="icon no-margin"
            />
          </Button>
        ) : ["Da convertire"].includes(
            row?.status?.replace(/\d+/g, "").trim()
          ) ? (
          <Button
            title={
              row?.info?.offer_rejected
                ? "Offer rejected: " + row?.info?.offer_rejected
                : "Offer rejected"
            }
            onClick={() =>
              context.setDialog({
                row,
                component: "new_status_to_be_converted_interested_event",
              })
            }
          >
            <img
              src={callIcon}
              className={classNames(
                "icon no-margin",
                Boolean(row?.info?.offer_rejected)
                  ? "rotate-[165deg]"
                  : "rotate-[135deg]"
              )}
            />
          </Button>
        ) : (
          <Button
            title="View"
            onClick={() =>
              context.setDialog({
                row,
                component: "view_details_updates_lead",
              })
            }
            className="!h-[2rem]"
          >
            <img
              src={viewIcon}
              className="icon no-margin"
            />
          </Button>
        ),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      width: 75,
      renderCell: ({ row }) => {
        const colorMS = getColor(row?.meeting_status, [
          "ci sarà",
          "non risponde",
        ]);

        const colorCS = getColor(row?.convert_status, [
          "soon buying",
          "no reply",
        ]);

        const countNotes: number = convert(row?.notes ?? [])?.length ?? 0;

        const meetings = convert(row?.meeting ?? {});

        const countMeetings = meetings?.meet ?? [];

        const countMeetingsAll = [
          ...countMeetings,
          ...(meetings?.meetToBeCalled ?? []),
        ];

        const countMeetingNotes =
          countMeetingsAll?.filter((m: any) => Boolean(m?.notes))?.length ?? 0;

        const countComments = countNotes + countMeetingNotes;

        return (
          <EduBadge
            badgeContent={
              row?.status == "Colloquio fissato" ? (
                <Box
                  sx={{
                    bgcolor: colorMS,
                    boxShadow: colorMS !== "grey" ? `0 0 5px ${colorMS}` : "",
                  }}
                  className="rounded-full transitions-colors duration-200 p-[0.4rem]"
                />
              ) : row.status == "Da convertire" ? (
                <Forward style={{ color: colorCS }} />
              ) : null
            }
          >
            <Dropdown
              text={<MoreVert />}
              content={
                <MenuList>
                  <MenuItem
                    color="primary"
                    onClick={() => copyToClipboard(row.email)}
                  >
                    <ListItemIcon>
                      <img
                        src={mailIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy email</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => getFunctions.handleOpenWhatsAppWeb(row)}
                  >
                    <ListItemIcon>
                      <i
                        className="fa-brands fa-whatsapp"
                        style={{ fontSize: 16 }}
                      />
                    </ListItemIcon>
                    <ListItemText>Whatsapp</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Info"
                    color="primary"
                    onClick={() =>
                      context.setDialog({
                        row,
                        component: "view_details_updates_lead",
                      })
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={viewIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>View</ListItemText>
                  </MenuItem>

                  {Boolean(row?.meeting) && (
                    <MenuItem
                      title="Info meetings"
                      color="primary"
                      onClick={() =>
                        context.setDialog({ row, component: "info_meetings" })
                      }
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        <Box className="w-full flex justify-between gap-2">
                          <span>Info meetings</span>
                          <span>
                            {Boolean(countMeetingsAll?.length) && (
                              <Badge
                                sx={{ mx: 1 }}
                                color="warning"
                                badgeContent={countMeetingsAll?.length}
                              />
                            )}
                          </span>
                        </Box>
                      </ListItemText>
                    </MenuItem>
                  )}

                  {["Nuovo", "Da richiamare"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "send_welcome_message",
                        })
                      }
                    >
                      <ListItemIcon>
                        <WavingHand fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Welcome message</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Non risposto",
                    "Rischedulare",
                    "Da richiamare",
                    "Assente al colloquio",
                    "Assente senza avvisare",
                    ...NEW_STATUS_FOR_CALL_CENTER,
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({ row, component: "crm_call" })
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Call meeting</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Da richiamare",
                    "Whatsapp text 1",
                    "Whatsapp text 2",
                    "Colloquio fissato",
                    "Messaggio WhatsApp",
                    ...NEW_STATUS_FOR_CALL_CENTER,
                  ].includes(row?.status) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "add_meeting_to_be_called",
                        })
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>To be called</ListItemText>
                    </MenuItem>
                  )}

                  {["Colloquio fissato", "Colloquio fatto"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "update_meeting",
                        })
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={videoIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Box className="w-full flex justify-between gap-2">
                          <span>Update meeting</span>
                          <span>
                            {Boolean(countMeetings?.length) && (
                              <Badge
                                sx={{ mx: 1 }}
                                color="warning"
                                badgeContent={countMeetings?.length}
                              />
                            )}
                          </span>
                        </Box>
                      </ListItemText>
                    </MenuItem>
                  )}

                  {["Colloquio fissato"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <Box>
                      {!row?.sent_reminders?.reminder1 && (
                        <MenuItem
                          title="Timer"
                          onClick={() =>
                            getFunctions.handleGenerateTemplateCrm(
                              row,
                              "reminder_1",
                              "reminder1"
                            )
                          }
                        >
                          <ListItemIcon>
                            <TimerRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Reminder</ListItemText>
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={(e) =>
                          context.setPopover({
                            row,
                            anchor: e.currentTarget,
                            component: "meeting_status",
                          })
                        }
                      >
                        <ListItemIcon style={{ color: colorMS }}>
                          <Box
                            className="rounded-full transitions-colors duration-200"
                            sx={{
                              p: "0.55rem",
                              boxShadow:
                                colorMS !== "grey" ? `0 0 5px ${colorMS}` : "",
                              bgcolor: colorMS,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>Meeting Status</ListItemText>
                      </MenuItem>
                    </Box>
                  )}

                  {["Colloquio fatto"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <ChooseIniPayment
                      onClick={(iniType) =>
                        context.setDialog({
                          row: { ...row, iniType },
                          component: "choose_ini_payment",
                        })
                      }
                    />
                  )}

                  {["Convertito"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) &&
                    Boolean(!row?.sent_reminders?.reminder2) && (
                      <MenuItem
                        title="Reminder 2"
                        onClick={() =>
                          getFunctions.handleGenerateReminderLink2(row)
                        }
                      >
                        <ListItemIcon>
                          <SocialDistanceIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reminder</ListItemText>
                      </MenuItem>
                    )}

                  {["Da convertire"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && [
                    !row?.sent_reminders?.reminder3 ? (
                      <MenuItem
                        key="Reminder 3"
                        title="Reminder 3"
                        onClick={() =>
                          getFunctions.handleGenerateTemplateCrm(
                            row,
                            "reminder_3",
                            "reminder3"
                          )
                        }
                      >
                        <ListItemIcon>
                          <HardwareRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reminder</ListItemText>
                      </MenuItem>
                    ) : null,

                    <MenuItem
                      key="Landing checkout link"
                      title="Landing checkout link"
                      onClick={() => copyToClipboard(getPaymentLink(row))}
                    >
                      <ListItemIcon>
                        <CopyAll fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Landing checkout link</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Convertion status"
                      title="Convertion status"
                      onClick={(e) =>
                        context.setPopover({
                          row,
                          anchor: e.currentTarget,
                          component: "convert_status",
                        })
                      }
                    >
                      <ListItemIcon style={{ color: colorCS }}>
                        <ForwardIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Convertion status</ListItemText>
                    </MenuItem>,

                    // <MenuItem
                    //   key="Restart timer"
                    //   title="Restart timer"
                    //   onClick={() =>
                    //     context.setDialog({ row, component: "restart_timer" })
                    //   }
                    // >
                    //   <ListItemIcon>
                    //     <RefreshRoundedIcon fontSize="small" />
                    //   </ListItemIcon>
                    //   <ListItemText>Restart timer</ListItemText>
                    // </MenuItem>,

                    <MenuItem
                      key="Confirm payment"
                      title="Confirm payment"
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "confirm_payment",
                        })
                      }
                    >
                      <ListItemIcon>
                        <PriceCheckIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Confirm payment</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Activate discount code"
                      title="Activate discount code"
                      onClick={(e) =>
                        context.setPopover({
                          row,
                          anchor: e.currentTarget,
                          component: "send_discount_code",
                        })
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={discountIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Activate discount code</ListItemText>
                    </MenuItem>,
                  ]}

                  {[
                    "Da convertire",
                    "Interested in the future",
                    "Offerta rifiutata",
                    "Rejected",
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <>
                      <MenuItem
                        onClick={() =>
                          context.setDialog({
                            row,
                            component: "interested_event",
                          })
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={callIcon}
                            className="icon no-margin"
                          />
                        </ListItemIcon>
                        <ListItemText>Interested in the future</ListItemText>
                      </MenuItem>
                    </>
                  )}

                  {[
                    "Rejected",
                    "Da convertire",
                    "Offerta rifiutata",
                    "Interested in the future",
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <MenuItem
                      onClick={() =>
                        copyToClipboard(
                          `${LANDING_BASE}/${row.lang}/waiting-list?user_tk=${row.token}`
                        )
                      }
                      disabled={row.waiting_list_data !== null}
                    >
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Copy waiting list link</ListItemText>

                      {row.waiting_list_data !== null && (
                        <Badge
                          sx={{ mx: 1 }}
                          color="success"
                          badgeContent={"✓"}
                        />
                      )}
                    </MenuItem>
                  )}

                  {["Rejected"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "choose_ini_payment",
                        })
                      }
                    >
                      <ListItemIcon>
                        <ThumbDownRounded sx={{ fontSize: "1.15rem" }} />
                      </ListItemIcon>
                      <ListItemText>Send mail</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Rischedulare",
                    "Da richiamare",
                    "Whatsapp text",
                    "Colloquio fissato",
                    "Messaggio WhatsApp",
                    "Waiting for feedback",
                    "Assente al colloquio",
                    "Assente senza avvisare",
                    "Waiting for Feedback No show",
                    ...NEW_STATUS_FOR_CALL_CENTER,
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <MenuItem
                      title="Copy link reschedule user from platform"
                      onClick={(e: any) =>
                        copyToClipboard(
                          `https://edusogno.com/form/reschedule/edusogno-inglese/${row.token}`
                        )
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={eventIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Reschedule link</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={(e) =>
                      context.setPopover({
                        row,
                        anchor: e.currentTarget,
                        component: "show_comment",
                      })
                    }
                  >
                    <ListItemIcon>
                      {row.comments ? (
                        <Badge
                          sx={{ p: 0.2 }}
                          color="warning"
                          variant="dot"
                        >
                          <img
                            src={commentIcon}
                            className="icon"
                          />
                        </Badge>
                      ) : (
                        <img
                          src={commentIcon}
                          className="icon"
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText>Comment</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Comments"
                    color="primary"
                    onClick={() =>
                      context.setDialog({ row, component: "show_comments" })
                    }
                  >
                    <ListItemIcon>
                      <MessageRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      <Box className="w-full flex justify-between gap-2">
                        <span>Comments</span>
                        <span>
                          {Boolean(countComments) && (
                            <Badge
                              sx={{ mx: 1 }}
                              color="warning"
                              badgeContent={countComments}
                            />
                          )}
                        </span>
                      </Box>
                    </ListItemText>
                  </MenuItem>

                  {Boolean(row?.orders?.length) && (
                    <MenuItem
                      title={`Order${row?.orders?.length > 1 ? "s" : ""}`}
                      onClick={() =>
                        context.setDialog({ row, component: "show_orders" })
                      }
                    >
                      <ListItemIcon>
                        <ShoppingCart fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        Order{row?.orders?.length > 1 ? "s" : ""}
                        <Badge
                          sx={{ mx: 2 }}
                          color="warning"
                          badgeContent={row?.orders?.length}
                        />
                      </ListItemText>
                    </MenuItem>
                  )}

                  {Boolean(row.user) && (
                    <MenuItem
                      title="Info"
                      color="primary"
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "move_to_upselling",
                        })
                      }
                    >
                      <ListItemIcon>
                        <MoveUpOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Move to UpSelling</ListItemText>
                    </MenuItem>
                  )}

                  {!["Convertito"].includes(row?.status) && (
                    <MenuItem
                      onClick={() =>
                        context.setDialog({
                          row,
                          component: "move_to_english_admission",
                        })
                      }
                    >
                      <ListItemIcon>
                        <LowPriority fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        Move to{" "}
                        {Number(row?.tutoring) ? "English" : "Admission"}
                      </ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() =>
                      context.setDialog({
                        row,
                        component: "also_add_on_other_version",
                      })
                    }
                  >
                    <ListItemIcon>
                      <Signpost />
                    </ListItemIcon>
                    <ListItemText>
                      Also add on{" "}
                      {Number(row?.tutoring) ? "English" : "Admission"}
                    </ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() =>
                      context.setDialog({
                        row,
                        component: "edit_lead",
                      })
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={editIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() =>
                      context.setDialog({ row, component: "delete_lead" })
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </EduBadge>
        );
      },
    },
  ];
};
