import { ChangeEvent, useEffect } from "react";
import { STATUS } from "@/pages/Upselling/v1/Crm";
import { Step3Props } from "../components/crm-upselling.types";
import { useCrmContext } from "../state/CrmV3Context";
import { BASE_PAYMENT_LINK_MEETING } from "../helpers/constants";
import { Box, Button, MenuItem, TextField, Tooltip } from "@mui/material";
import MONTHS from "@/components/assets/MONTHS";
import backIcon from "@/assets/icons/back.svg";
import saveIcon from "@/assets/icons/save.svg";
import FlagRender from "../components/FlagRender";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client";
import { checkIsSwiss } from "@/utils";

const Step3: React.FC<Step3Props> = ({
  data,
  setStep,
  functions,
  otherEvent,
  isAdmission,
  selectedEvent,
  newStartingMonths,
  keysFormMeetingDone,
  handleSetFormMeetingDone,
}) => {
  const { meetingComponentState } = useCrmContext();
  const { status, eventId } = meetingComponentState;
  const order_tk = data.buttons?.meetingDone.form?.order_tk ?? undefined;

  const lang = checkIsSwiss(data?.user?.phone, data?.user?.lang);

  console.log({ user: data?.user });

  const { data: products } = useQuery({
    queryKey: ["products", lang, data?.user?.tutoring],
    queryFn: () => client.product.listByLanguage(lang, data?.user?.tutoring),
    enabled: Boolean(data?.user?.phone),
  })

  useEffect(() => {
    if (order_tk) {
      handleSetFormMeetingDone({
        target: { name: "order_tk", value: order_tk },
      });
    }
  }, [order_tk]);

  return (
    <Box
      onSubmit={(e) => {
        e.preventDefault();

        if (otherEvent) {
          functions?.onMeetingDoneOther?.("Upselling done");
        } else {
          functions?.onMeetingDone?.(selectedEvent, eventId, status);
        }
      }}
      component="form"
      className="flex flex-col gap-5"
    >
      {keysFormMeetingDone.includes("pacchetto") && (
        <TextField
          select
          required
          fullWidth
          name="pacchetto"
          label={
            [STATUS.UpsellingDone].includes(status)
              ? "Product"
              : "Product of interest 1"
          }
          value={
            data.buttons?.meetingDone?.form?.pacchetto ??
            selectedEvent?.pacchetto ??
            ""
          }
          onChange={(e) => {
            handleSetFormMeetingDone(e);

            if (data.buttons?.meetingDone?.form.payment_link !== undefined) {
              data.buttons?.meetingDone?.setForm(
                "payment_link",
                BASE_PAYMENT_LINK_MEETING + e.target.value
              );
            }
          }}
        >
          {
            products?.item?.map((product) => (
              <MenuItem
                key={product.id}
                value={product.token}
              >
                <Tooltip title={product.product_tag}>
                  <Box className="flex gap-2 justify-between items-center w-full">

                    <Box className="truncate mr-2">
                      <FlagRender
                        flag={product?.prices?.country}
                        className="w-[22px]"
                      />
                    </Box>

                    <Box className="truncate mr-2 w-[350px]">{product.product_tag}</Box>

                    <Box>{product?.prices?.currency} {product?.prices?.price}</Box>
                  </Box>
                </Tooltip>
              </MenuItem>
            ))
          }
        </TextField>
      )}

      {Boolean(keysFormMeetingDone.includes("english_lvl") && !isAdmission) && (
        <TextField
          select
          required
          fullWidth
          size="small"
          name="english_lvl"
          label="English level"
          value={
            data.buttons?.meetingDone.form.english_lvl ??
            selectedEvent?.english_lvl ??
            ""
          }
          onChange={handleSetFormMeetingDone}
        >
          {["A0", "A1", "A2", "B1", "B1+", "B2-", "B2", "B2+", "C1"].map(
            (level: string, index: any) => {
              return (
                <MenuItem
                  key={level + index}
                  value={level}
                >
                  {level}
                </MenuItem>
              );
            }
          )}
        </TextField>
      )}

      {Boolean(
        keysFormMeetingDone.includes("certification") && !isAdmission
      ) && (
          <TextField
            select
            required
            fullWidth
            name="certification"
            label="Interest for Certificate"
            value={
              data.buttons?.meetingDone.form.certification ??
              selectedEvent?.certification ??
              ""
            }
            onChange={(e) => handleSetFormMeetingDone(e)}
          >
            {["YES", "NO"].map((level, index: any) => (
              <MenuItem
                key={level + index}
                value={level}
              >
                {level}
              </MenuItem>
            ))}
          </TextField>
        )}

      {keysFormMeetingDone.includes("motivation") && (
        <TextField
          select
          required
          fullWidth
          name="motivation"
          label="Willingness to participate"
          value={
            data.buttons?.meetingDone.form.motivation ??
            selectedEvent?.motivation ??
            ""
          }
          onChange={handleSetFormMeetingDone}
        >
          {["Will buy", "Would buy", "Interested", "Rejected"].map(
            (level, index: any) => (
              <MenuItem
                key={level + index}
                value={level}
              >
                {level}
              </MenuItem>
            )
          )}
        </TextField>
      )}

      {keysFormMeetingDone.includes("newStartingMonth") &&
        ([STATUS.UpsellingDone].includes(status) || otherEvent) && (
          <TextField
            select
            required
            fullWidth
            name="newStartingMonth"
            label="New starting month"
            value={
              data.buttons?.meetingDone.form.newStartingMonth ??
              selectedEvent?.newStartingMonth ??
              ""
            }
            onChange={handleSetFormMeetingDone}
          >
            {newStartingMonths?.map((month: string, i: number) => {
              const v = month.split(" ");

              return (
                <MenuItem
                  key={i + month}
                  value={`${MONTHS[v[0]]} ${v[1]}`}
                >
                  {month}
                </MenuItem>
              );
            })}
          </TextField>
        )}

      {keysFormMeetingDone.includes("notes") && (
        <TextField
          multiline
          fullWidth
          id="outlined-required"
          rows={3}
          size="small"
          name="notes"
          label="Notes"
          value={
            data.buttons?.meetingDone.form.notes ?? selectedEvent?.notes ?? ""
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleSetFormMeetingDone(e)
          }
          placeholder="Write here a note..."
        />
      )}

      <Box className="text-center">
        {!otherEvent && (
          <Button
            sx={{ marginRight: "10px" }}
            size="small"
            variant="outlined"
            onClick={() => setStep((p: number) => p - 1)}
          >
            <img
              src={backIcon}
              className="icon"
            />
            Back
          </Button>
        )}

        <Button
          size="small"
          type="submit"
          variant="outlined"
        >
          <img
            src={saveIcon}
            className="icon"
          />
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default Step3;
