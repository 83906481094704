import { useEffect, useMemo, useState } from "react";
import useAuth from '@/hooks/useAuth';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Info, WarningOutlined } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SUBTYPE, TYPE, TIME_DURATION_EVENT } from "../../Events";
import {
  useEventUsers,
  useEventTutors,
  useListMaterialExercises,
} from "@/api/events";
import {
  Box,
  Grid,
  Chip,
  Popover,
  Checkbox,
  MenuItem,
  TextField,
  Autocomplete,
  FormControlLabel,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  EventItem,
  Materials,
  EventUsers,
  EventTutors,
  EVENT_TYPES,
  CreateEventInput,
  MaterialExercise,
} from "@/interfaces";
import client from "@/api/client";
import saveIcon from "@/assets/icons/save.svg";
import LANGUAGES from "@/components/assets/LANGUAGES";
import CustomInput from "../../custom/CustomInput";
import CustomSelect from "../../custom/CustomSelect";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useHourGenerator from "@/utils/hooks/use-hour-generator";
import AutoCompleteInput from "../../custom/AutoCompleteInput";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AutoCompleteExercisesInput from "@/pages/Exercises/ExercisesV1/components/AutoCompleteExercisesInput";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  keyQuery: Array<any>;
  onSubmit: () => void;
  selectedEvent: EventItem;
}

const SingleEvent = ({ onSubmit, selectedEvent, keyQuery }: IProps) => {
  const queryClient = useQueryClient();
  const { tutors } = useEventTutors();
  const { materialExercises } = useListMaterialExercises();
  const { users, isLoading: loadingUsers } = useEventUsers();
  const { snackHandler: snack, copyToClipboard } = useAuth();
  const { hourStart, hourEnd, setStartHour, setUseHalf } =
    useHourGenerator(TIME_DURATION_EVENT);

  const [isUserSlotChecked, setIsUserSlotChecked] = useState<boolean>(false);
  const [isTutorSlotChecked, setIsTutorSlotChecked] = useState<boolean>(false);

  const usersMap = useMemo(
    () =>
      users?.map((u) => ({
        token: u?.token,
        email: u?.email,
        exercises_mock_test: u?.exercises_mock_test,
        exercises_corrections: u?.exercises_corrections,
      })),
    [users]
  );

  const [formState, setFormState] = useState<CreateEventInput>({
    ora: "",
    type: "",
    data: new Date(),
    lang: "it",
    titolo: "",
    livello: "",
    ora_fine: "",
    id_utente: [],
    descrizione: "",
    english_lvl: "",
    tutor_token: "",
    material_token: [],
    exercise_token: [],
    titolo_argomento: "",
    nome_visualizzato: "",
    id_utente_presences: [],
    exercises_correction: "",
    descrizione_argomento: "",
    slot_available: 0,
    user_slot_available: 0,
    exercise_type: "old",
  });

  const isMockTest = formState.type == "Mock test";
  const isCorrection = formState.type == "Correction";

  const subtypeLevel = [...SUBTYPE];

  if (
    selectedEvent?.livello &&
    !subtypeLevel.includes(selectedEvent?.livello)
  ) {
    subtypeLevel.unshift(selectedEvent.livello);
  }

  const [tutor, setTutor] = useState<EventTutors | null>(null);
  const [students, setStudents] = useState<EventUsers[]>([]);
  const [exercises, setExercises] = useState<MaterialExercise[]>([]);
  const [oneStudent, setOneStudent] = useState<EventUsers | null>(null);
  const [popoverInfo, setPopoverInfo] = useState<any>();
  const [materialUsers, setMaterialUsers] = useState<Array<any>>([]);
  const [materialTutors, setMaterialTutors] = useState<Array<any>>([]);
  const [studentsPresences, setStudentsPresences] = useState<EventUsers[]>([]);
  const [exercisesCorrections, setExercisesCorrections] = useState<Array<any>>(
    []
  );
  const [openConfirmWithoutStudents, setOpenConfirmWithoutStudents] =
    useState<boolean>(false);

  const handleUpdateFormState = (
    key: string,
    value: string | Date | string[] | number[] | number
  ) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const { mutate: createEvent, isLoading: isCreatingEvent } = useMutation(
    client.events.create,
    {
      onSuccess: (data) => {
        snack(data.message);
        onSubmit();
        queryClient.invalidateQueries(keyQuery);
      },
      onError: () => snack("Some error occurred. Retry", "error"),
    }
  );

  const { mutate: updateEvent, isLoading: isUpdatingEvent } = useMutation(
    client.events.update,
    {
      onSuccess: (data) => {
        snack(data.message);
        onSubmit();
        queryClient.invalidateQueries(keyQuery);
      },
      onError: () => snack("An error occurred. Retry", "error"),
    }
  );

  const createEventHandler = async (e: any) => {
    e.preventDefault();
    let studentTokens: string[] = [];

    const newFormState: CreateEventInput = { ...formState };

    if (["One to One", "Correction", "Mock test"].includes(formState.type)) {
      if (oneStudent?.token) {
        studentTokens.push(oneStudent?.token);
      }

      if (formState.type == "Mock test") {
        newFormState.type = EVENT_TYPES.MOCK_TEST;
      }
    } else {
      studentTokens = students.map((s) => s?.token);
    }

    if (selectedEvent) {
      const { id, ...rest } = newFormState;

      updateEvent({
        id: selectedEvent.id,
        input: {
          ...rest,
          fromAdmin: true,
          id_utente: studentTokens,
          titolo_argomento: newFormState.descrizione_argomento,
          id_utente_presences: studentsPresences.map((s) => s.token),
          exercise_type: formState.exercise_type,
        },
      });

      return;
    }

    createEvent({
      ...newFormState,
      id_utente: studentTokens,
      titolo_argomento: newFormState.descrizione_argomento,
      exercise_type: formState.exercise_type,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!selectedEvent) {
      createEventHandler(e);
      return;
    }

    const isOneToOne = formState.type == "One to One";

    if (
      (isOneToOne && !oneStudent?.token) ||
      (!isOneToOne && !students?.length)
    ) {
      setOpenConfirmWithoutStudents(true);
    } else {
      createEventHandler(e);
    }
  };

  const forUsersTutors = useMemo(
    () => ({
      users: materialExercises?.materials?.filter(
        (m: any) => m?.for == "User" || m?.for == "Both" || !m?.for
      ),
      tutors: materialExercises?.materials?.filter(
        (m: any) => m?.for == "Tutor" || m?.for == "Both" || !m?.for
      ),
    }),
    [materialExercises, materialExercises?.materials]
  );

  useEffect(() => {
    if (!selectedEvent) return;

    for (const key in selectedEvent) {
      if (!selectedEvent.hasOwnProperty(key)) continue;

      const el = selectedEvent[key];

      if (key === "data") {
        handleUpdateFormState(key, new Date(el));
      } else if (key === "user_events") {
        setStudents(
          el?.map((u: any) => ({ token: u?.token, email: u?.email }))
        );

        setOneStudent(
          usersMap?.find((u: any) => u?.token == el?.[0]?.token) ?? null
        );

        setStudentsPresences(
          el
            ?.filter((s: any) => s?.access_date != null)
            ?.map((u: any) => ({ token: u?.token, email: u?.email }))
        );
      } else if (["exercises", "materials"].includes(key)) {
        if (key === "exercises") {
          setExercises(el);

          handleUpdateFormState(
            "exercise_token",
            el?.map((e: MaterialExercise) => e?.token)
          );
        } else {
          handleUpdateFormState(
            "material_token",
            el.map((e: Materials) => e.material_id)
          );

          setMaterialUsers(
            el?.filter(
              (m: any) => m?.for == "User" || m?.for == "Both" || !m?.for
            )
          );

          setMaterialTutors(
            el?.filter(
              (m: any) => m?.for == "Tutor" || m?.for == "Both" || !m?.for
            )
          );
        }
      } else if (key === "tutors") {
        const eventTutor = tutors?.find((t) => t?.token === el?.token) ?? null;

        if (eventTutor) {
          setTutor(eventTutor);
        }

        handleUpdateFormState("tutor_token", el?.token ?? null);
      } else if (key == "exercises_correction") {
        const exercises_correction = el?.split(",");

        const currentUserMap = usersMap?.find(
          (u: any) => u?.token == selectedEvent?.user_events?.[0]?.token
        );

        const ex =
          selectedEvent?.type == "Mock test" &&
            currentUserMap?.exercises_mock_test?.length
            ? [
              currentUserMap?.exercises_mock_test?.find((e) =>
                exercises_correction?.includes(e?.token)
              ),
            ]
            : selectedEvent?.type == "Correction" &&
              currentUserMap?.exercises_corrections?.length
              ? currentUserMap?.exercises_corrections?.filter((e) =>
                exercises_correction?.includes(e?.token)
              )
              : [];

        setExercisesCorrections(ex);
      } else if (key == "type") {
        setUseHalf(
          ["Correction", "Mock test"].includes(el) ||
          ("Speaking" == el &&
            selectedEvent.titolo.toLowerCase().includes("mock test"))
        );

        handleUpdateFormState(key, el);
      } else {
        handleUpdateFormState(key, el);
      }
    }
  }, [selectedEvent, tutors, usersMap]);

  useEffect(() => {
    let presencesSet: Array<any> = [];

    if (students?.length) {
      presencesSet = selectedEvent?.user_events
        ?.filter(
          (u: any) =>
            Boolean(u?.access_date) &&
            students?.map((e: any) => e?.token)?.includes(u?.token)
        )
        ?.map((u: any) => ({ token: u?.token, email: u?.email }));
    }

    setStudentsPresences(presencesSet);
  }, [students, selectedEvent]);

  const handleCheckboxChange = (event: any) => {
    setIsUserSlotChecked(event?.target?.checked);
  };

  const handleTutorCheckboxChange = (event: any) => {
    setIsTutorSlotChecked(event?.target?.checked);
  };

  const [exerciseTab, setExerciseTab] = useState<"old" | "new">("old");
  const [exerciseOptions, setExerciseOptions] = useState<any[]>([]);
  const [exercisesLoading, setExercisesLoading] = useState(true);
  const [exerciseError, setExerciseError] = useState("");
  const [showExerciseSelector, setShowExerciseSelector] = useState(false);


  // Fetch exercises based on selected tab
  useEffect(() => {
    const fetchExercises = async () => {
      try {
        setExercisesLoading(true);
        const response = await client.exercises.getExercises({ perPage: 99999 });

        const filtered = response.data.filter((exercise: any) =>
          exerciseTab === "old"
            ? exercise.exercise_type === "old"
            : exercise.exercise_type === "new"
        );

        setExerciseOptions(filtered);
        setExercisesLoading(false);
      } catch (err) {
        setExerciseError("Failed to load exercises");
        setExercisesLoading(false);
      }
    };

    fetchExercises();
  }, [exerciseTab]);

  return (
    <>
      <Box
        width="100%"
        onSubmit={handleSubmit}
        component="form"
      >
        <Grid
          container
          spacing="20px"
        >
          {/* // TITLE */}
          <Grid
            item
            xs={6}
          >
            <CustomInput
              required
              label="Event name"
              value={formState.titolo || ""}
              onChange={(v: string) => {
                handleUpdateFormState("titolo", v);
                setUseHalf(
                  ["Correction", "Mock test"].includes(formState.type) ||
                  ("Speaking" == formState.type &&
                    v.toLowerCase().includes("mock test"))
                );
              }}
            />
          </Grid>

          {/* // VIEW NAME */}
          <Grid
            item
            xs={6}
          >
            <CustomInput
              label="Displayed name"
              value={formState.nome_visualizzato || ""}
              required={!selectedEvent}
              onChange={(v: string) =>
                handleUpdateFormState("nome_visualizzato", v)
              }
            />
          </Grid>

          {/* // TYPE */}
          <Grid
            item
            xs={3}
          >
            <CustomSelect
              label="Type"
              items={TYPE}
              value={formState.type || ""}
              onChange={(v: string) => {
                handleUpdateFormState("type", v);
                setUseHalf(
                  ["Correction", "Mock test"].includes(v) ||
                  ("Speaking" == v &&
                    formState.titolo.toLowerCase().includes("mock test"))
                );
              }}
              placeholder="Type"
            />
          </Grid>

          {/* // Sub type */}
          <Grid
            item
            xs={3}
          >
            <CustomInput
              label="Subtype"
              value={formState.english_lvl || ""}
              onChange={(v: string) => handleUpdateFormState("english_lvl", v)}
              placeholder="Subtype"
            />
          </Grid>

          {/* // DESCRIPTION */}
          <Grid
            item
            xs={4}
          >
            <CustomInput
              label="Topic"
              value={formState.descrizione_argomento || ""}
              required={!selectedEvent}
              onChange={(v: string) =>
                handleUpdateFormState("descrizione_argomento", v)
              }
            />
          </Grid>

          {/* // LANG */}
          <Grid
            item
            xs={2}
          >
            <TextField
              select
              required
              fullWidth
              name="lang"
              label="Lang"
              value={formState.lang || ""}
              onChange={({ target: { name, value } }) =>
                handleUpdateFormState(name, value)
              }
            >
              {LANGUAGES.map((l) => (
                <MenuItem
                  key={l.iso}
                  value={l.iso}
                >
                  {l.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* // LEVEL */}
          <Grid
            item
            xs={3}
          >
            <CustomSelect
              label="Level"
              value={formState.livello || ""}
              items={subtypeLevel}
              onChange={(v: string) => handleUpdateFormState("livello", v)}
              placeholder="Choose a Level"
            />
          </Grid>

          {/* //DATE */}
          <Grid
            item
            xs={3}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                inputFormat="yyyy-MM-dd"
                views={["day"]}
                value={formState.data || null}
                onChange={(newValue) =>
                  newValue && handleUpdateFormState("data", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Date *"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          {/* //HOUR START*/}
          <Grid
            item
            xs={3}
          >
            <CustomSelect
              required
              label="Start"
              items={hourStart}
              value={formState.ora || ""}
              onChange={(v: string) => {
                handleUpdateFormState("ora", v);
                setStartHour(v);
              }}
              placeholder="Choose a hour to start"
            />
          </Grid>

          {/* //HOUR END*/}
          <Grid
            item
            xs={3}
          >
            <CustomSelect
              label="End"
              items={hourEnd}
              value={formState.ora_fine || ""}
              disabled={!formState.ora}
              required={formState.type !== EVENT_TYPES.CORRECTION}
              onChange={(v: string) => handleUpdateFormState("ora_fine", v)}
              placeholder="Choose a hour to end"
            />
          </Grid>

          {/* // USER SLOT AVAILABLE */}
          <Grid
            item
            xs={6}
            style={{ display: "flex" }}
          >
            <FormControlLabel
              name="slot_available"
              label={
                isUserSlotChecked
                  ? "Speaking class slot available"
                  : "Speaking class slot not available"
              }
              control={
                <Checkbox
                  checked={Boolean(formState?.user_slot_available)}
                  onChange={(event: any) => {
                    handleCheckboxChange(event);
                    handleUpdateFormState(
                      "user_slot_available",
                      event?.target?.checked ? 1 : 0
                    );
                  }}
                />
              }
            />
          </Grid>

          {/* // TUTOR SLOT AVAILABLE */}
          <Grid
            item
            xs={6}
            style={{ display: "flex" }}
          >
            <FormControlLabel
              name="tutor_slot_available"
              label={
                isTutorSlotChecked
                  ? "Tutor slot is available "
                  : "No tutor slot available"
              }
              control={
                <Checkbox
                  checked={Boolean(formState?.slot_available)}
                  onChange={(event: any) => {
                    handleTutorCheckboxChange(event);
                    handleUpdateFormState(
                      "slot_available",
                      event?.target?.checked ? 1 : 0
                    );
                  }}
                />
              }
            />
          </Grid>

          {/* // TUTOR */}
          <Grid
            item
            xs={12}
          >
            <Autocomplete
              id="tutor-tags-demo"
              color="primary"
              value={tutor}
              options={tutors}
              disableCloseOnSelect
              isOptionEqualToValue={(option: any, value) =>
                option.token === value.token
              }
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.id}
                >
                  {option.first_name} {option.last_name}
                </li>
              )}
              onChange={(_event, value) => {
                handleUpdateFormState("tutor_token", value?.token || "");
                setTutor(value);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tutor"
                  placeholder="Tutor"
                />
              )}
            />
          </Grid>

          {/* // STUDENTS */}
          <Grid
            item
            xs={12}
          >
            {["One to One", "Correction", "Mock test"].includes(
              formState.type
            ) ? (
              <AutoCompleteInput
                data={usersMap}
                label="Student"
                value={oneStudent}
                loading={loadingUsers}
                multiple={false}
                required={false}
                setValue={setOneStudent}
                closeSelect={true}
                onClickItem={(e: any, option: any) =>
                  copyToClipboard(option?.email)
                }
                placeholder="Students"
              />
            ) : (
              <AutoCompleteInput
                data={usersMap}
                label="Students"
                value={students}
                loading={loadingUsers}
                multiple={true}
                required={false}
                setValue={setStudents}
                closeSelect={true}
                onClickItem={(e: any, option: any) =>
                  copyToClipboard(option?.email)
                }
                placeholder="Students"
              />
            )}
          </Grid>

          {/* // PRESENCES STUDENTS */}
          {Boolean(selectedEvent) && (
            <Grid
              item
              xs={12}
            >
              <AutoCompleteInput
                data={students}
                value={studentsPresences}
                label="Presences students"
                loading={loadingUsers}
                required={false}
                multiple={true}
                setValue={setStudentsPresences}
                closeSelect={true}
                onClickItem={(e: any, option: any) =>
                  copyToClipboard(option?.email)
                }
                placeholder="Presences students"
              />
            </Grid>
          )}

          {/* // EXERCISES */}
       {/* // EXERCISE TOGGLE BUTTON */}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowExerciseSelector((prev) => !prev)}
              >
                {showExerciseSelector ? "Hide Exercises" : "+ Assign Exercises"}
              </Button>
            </Grid>

            {/* // EXERCISE SELECTOR UI */}
            {showExerciseSelector && (
              <Grid item xs={12}>
                <Tabs
                  value={exerciseTab}
                  onChange={(_event, value) => {
                    setExerciseTab(value); 
                    setExercises([]);
                  }}
                  centered
                >
                  <Tab label="Old Exercises" value="old" />
                  <Tab label="New Exercises" value="new" />
                </Tabs>

                {exerciseError && (
                  <Typography color="error" className="text-center mt-2">
                    {exerciseError}
                  </Typography>
                )}

                {exercisesLoading ? (
                  <CircularProgress />
                ) : (
                  <AutoCompleteExercisesInput
                    multiple
                    closeSelect
                    optionsLabel
                    data={exerciseOptions}
                    label={exerciseTab === "old" ? "Old Exercises" : "New Exercises"}
                    value={exercises ?? []}
                    setValue={(value) => {
                      setExercises(value);           
                      const isOld = exerciseTab === "old";
                      const tokens = value.map((e) => (isOld ? e.token : String(e.id)));
                      handleUpdateFormState("exercise_token", tokens);
                      handleUpdateFormState("exercise_type", isOld ? "old" : "new");
                    }}
                  />
                )}
              </Grid>
            )}

          {/* // EXERCISES CORRECTIONS */}
          {Boolean(
            ["Correction", "Mock test"].includes(formState.type) ||
            (formState.type == EVENT_TYPES.SPEAKING &&
              formState.titolo.toLowerCase().includes("mock test"))
          ) && (
              <Grid
                item
                md={12}
              >
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="checkboxes-tags-demo"
                  color="primary"
                  value={exercisesCorrections}
                  style={{ width: "100%" }}
                  options={
                    isMockTest && oneStudent?.exercises_mock_test?.length
                      ? oneStudent?.exercises_mock_test
                      : isCorrection && oneStudent?.exercises_corrections?.length
                        ? oneStudent?.exercises_corrections
                        : []
                  }
                  isOptionEqualToValue={(option, value) =>
                    option?.token === value?.token
                  }
                  getOptionLabel={(option) => option?.name}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.token}
                    >
                      <Checkbox
                        icon={icon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        checkedIcon={checkedIcon}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={(_event, value) => {
                    const v: Array<any> = Boolean(value?.length)
                      ? isMockTest
                        ? [value?.[0]]
                        : value
                      : [];

                    const exercisesCorrection =
                      v?.map((e: any) => e.token).join(",") ?? "";

                    handleUpdateFormState(
                      "exercises_correction",
                      exercisesCorrection
                    );

                    setExercisesCorrections(v);
                  }}
                  ChipProps={{ color: "primary", variant: "outlined" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Exercises corrections"
                      placeholder="Exercises corrections"
                    />
                  )}
                />
              </Grid>
            )}

          {/* // MATERIALS TUTORS */}
          <Grid
            item
            md={12}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="checkboxes-tags-demo"
              color="primary"
              value={materialTutors}
              style={{ width: "100%" }}
              options={forUsersTutors?.tutors ?? []}
              isOptionEqualToValue={(option, value) =>
                option.token === value.token
              }
              getOptionLabel={(option) =>
                `${option.name} | ${option.lesson} | ${option.category} | ${option.language}`
              }
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.token}
                >
                  <Chip label={option.type} />
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    checkedIcon={checkedIcon}
                  />
                  {`${option.name} | ${option.lesson} | ${option.category} | ${option.language}`}
                </li>
              )}
              onChange={(_event, value) => {
                const v = [
                  ...materialUsers.map((m) => m?.id ?? m?.material_id),
                  ...value.map((m) => m?.id ?? m?.material_id),
                ];

                handleUpdateFormState("material_token", v);
                setMaterialTutors(value);
              }}
              ChipProps={{ color: "primary", variant: "outlined" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Materials tutors"
                  placeholder="Materials"
                />
              )}
            />
          </Grid>

          {/* // MATERIALS USERS */}
          <Grid
            item
            md={12}
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="checkboxes-tags-demo"
              color="primary"
              value={materialUsers}
              style={{ width: "100%" }}
              options={forUsersTutors?.users ?? []}
              isOptionEqualToValue={(option, value) =>
                option.token === value.token
              }
              getOptionLabel={(option: {
                id: number;
                for: string | null;
                name: string;
                type: string;
                token: string;
                material_id: number;
                lang: string[];
                language: string[];
                category: string[];
                lesson: string;
              }) =>
                `${option.name} | ${option.lesson} | ${option.category} | ${option.language}`
              }
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={option.token}
                >
                  <Chip label={option.type} />
                  <Checkbox
                    icon={icon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    checkedIcon={checkedIcon}
                  />
                  {`${option.name} | ${option.lesson} | ${option.category} | ${option.language}`}
                </li>
              )}
              onChange={(_event, value) => {
                const v = [
                  ...materialTutors.map((m) => m?.id ?? m?.material_id),
                  ...value.map((m) => m?.id ?? m?.material_id),
                ];

                handleUpdateFormState("material_token", v);
                setMaterialUsers(value);
              }}
              ChipProps={{ color: "primary", variant: "outlined" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Materials users"
                  placeholder="Materials"
                />
              )}
            />
          </Grid>

          {/* // CREATE */}
          <Grid
            item
            md={12}
            className="flex justify-between items-center"
          >
            <button
              type="button"
              title="Info"
              onClick={(e) => setPopoverInfo(e.currentTarget)}
              className="pl-2"
            >
              <Info />
            </button>

            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={isCreatingEvent || isUpdatingEvent}
            >
              <img
                src={saveIcon}
                className="icon"
              />
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>

      <ConfirmationDialog
        title={
          <>
            <WarningOutlined
              fontSize="small"
              className="mr-2"
            />
            No students
          </>
        }
        open={openConfirmWithoutStudents}
        onClose={() => setOpenConfirmWithoutStudents(false)}
        onConfirm={(e) => {
          setOpenConfirmWithoutStudents(false);
          createEventHandler(e);
        }}
      >
        Are you sure save this event without students?
      </ConfirmationDialog>

      <Popover
        open={Boolean(popoverInfo)}
        onClose={() => setPopoverInfo(undefined)}
        anchorEl={popoverInfo}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="flex flex-col gap-4 p-3">
          <Box>
            The selection of a single student is active only when the{" "}
            <b>"Type"</b> field is:
            <br />
            <b>"Correction"</b>, <b>"One to One"</b> or <b>"Mock test"</b>
          </Box>
          <Box>
            The <b>"Exercises corrections"</b> field appears only if the{" "}
            <b>"Type"</b> field is:
            <br />
            <b>"Correction"</b> or <b>"Mock test"</b>
          </Box>
          <Box>
            In the <b>"Exercises corrections"</b> field you can only select a
            value if the <b>"Type"</b> field is <b>"Mock test"</b>
          </Box>
          <Box>
            In the <b>"Start"</b> and <b>"End"</b> fields to have the values
            with half hours, the "Type" field must be:
            <br />
            <b>"Correction"</b> or <b>"Mock test"</b>
          </Box>
          <Box>
            In the <b>"Type"</b> the value <b>"Mock test"</b> is converted into{" "}
            <b>"Speaking"</b>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SingleEvent;
