import useRecruitingApi from "@/api/useRecruitingApi";
import commentsIcon from "@/assets/icons/comments.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import linkIcon from "@/assets/icons/link.svg";
import mailIcon from "@/assets/icons/mail.svg";
import noShowIcon from "@/assets/icons/no-show.svg";
import rescheduledIcon from "@/assets/icons/rescheduled.svg";
import videoIcon from "@/assets/icons/video.svg";
import viewIcon from "@/assets/icons/view.svg";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import BasePage from "@/components/Page/Base";
import MeetingComponent, {
  INFormMeetingDone,
  UserRecruitingMeeting,
} from "@/components/recruiting/MeetingComponent";
import ViewInfoDialog from "@/components/recruiting/ViewInfoDialog";
import ViewScreeningInfoDialog from "@/components/recruiting/ViewScreeningInfoDialog";
import { filterObject } from "@/components/useHelpers";
import useAuth from "@/hooks/useAuth";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import RecruitingDialog, {
  IForwardRef as IRecruitingDialog,
} from "@/pages/RecruitingForms/components/RecruitingFormDialog";
import { getTableStyling } from "@/providers/Colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Comments from "./components/Comments";
import Toolbar from "./components/Toolbar";

const RECRUITING_BASE = import.meta.env.VITE_ROUTE_RECRUITING;

const defaultFormMeetingDone: INFormMeetingDone = {
  personality: null,
  availability: null,
  salary: null,
  languages: null,
};

const Recruiting = () => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const { userAdmin, copyToClipboard, typeOf, activity, snackHandler } =
    useAuth();

  const recruitingRef = createRef<IRecruitingDialog>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [calendars, setCalendars] = useState<Array<string>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openMeeting, setOpenMeeting] = useState<boolean>(false);
  const [openScreening, setOpenScreening] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [viewInfoTutor, setViewInfoTutor] = useState<boolean>(false);
  const [tutorSelected, setTutorSelected] = useState<any>(null);

  const [formMeetingDone, setFormMeetingDone] = useState<INFormMeetingDone>(
    defaultFormMeetingDone
  );
  const [rowSelected, setRowSelected] = useState<any>({});

  const [filters, setFilters] = useSearchParams();
  const { onSortModelChange, sortModel } = useDGS({ filters, setFilters });
  const filterData = Object.fromEntries(filters.entries());
  const { page, setPage } = usePagination({ filters, setFilters });

  const enabled = perPage > 0 ? true : false;
  const { callPutUpdateCandidate, callDeleteCandidate } = useRecruitingApi();
  const { data } = useRecruitingApi().callGetCandidates(
    {
      page,
      sorts: sortModel,
      filter: filterData,
      per_page: perPage,
    },
    enabled
  );

  const cacheKey = { page, ...filterData, per_page: perPage };

  const statuses = useRecruitingApi().statuses;
  const rows = data?.data || [];

  const pagination = {
    total: data?.total,
    last_page: data?.last_page,
    current_page: data?.current_page,
  };

  const getTextWh = (user: any) => {
    return `Ciao ${user.first_name}`;
  };

  const handleWhatsApp = (row: any) => {
    const link =
      "https://api.whatsapp.com/send?phone=" +
      row.phone +
      "&text=" +
      getTextWh(row);
    window.open(link, "_blank");
  };

  const formsStatus = useMemo(
    () =>
      statuses.map((s) => (
        <MenuItem
          key={s.value}
          value={s.value}
        >
          {s.label}
        </MenuItem>
      )),
    [statuses]
  );

  const formatUser: UserRecruitingMeeting = useMemo(() => {
    const data = {
      id: rowSelected.id,
      first_name: rowSelected.first_name,
      last_name: rowSelected.last_name,
      lang: rowSelected.lang,
      email: rowSelected.email,
      token: rowSelected.token,
      phone: rowSelected.phone,
      status: rowSelected.status,
      updates:
        typeof rowSelected.updates === "string"
          ? JSON.parse(rowSelected.updates)
          : rowSelected.updates,
      meeting:
        typeof rowSelected.meeting === "string"
          ? JSON.parse(rowSelected.meeting)
          : rowSelected.meeting,
    };
    return data;
  }, [rowSelected]);

  const handleSetFormMeetingDone = useCallback(
    (key: string, value: string) =>
      setFormMeetingDone((p: INFormMeetingDone) => ({ ...p, [key]: value })),
    []
  );

  const handleUpdateRowCrmCall = useCallback(
    (item: any) => {
      const updates =
        typeof rowSelected.updates === "string"
          ? JSON.parse(rowSelected.updates)
          : rowSelected.updates;

      const newUpdate = {
        status: item.values.status,
        userAdmin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
        updates: JSON.stringify([
          ...(Array.isArray(updates) ? updates : []),
          {
            status: item.values.status,
            date: null,
            admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
          },
        ]),
      };

      callPutUpdateCandidate.mutate({
        id: rowSelected.id,
        data: newUpdate,
      });
      setOpenMeeting(false);
    },
    [rowSelected, userAdmin]
  );

  const handleUpdate = useCallback(
    (selectedEvent: any, eventId: number | string, newStatus: string) => {
      const newMeet = {
        ...selectedEvent,
        ...filterObject(formMeetingDone, [null]),
      };

      const meetings = formatUser.meeting;

      const i = meetings.meet.findIndex((meet: any) => meet.id === eventId);

      meetings.meet[i] = newMeet;

      const {
        user: { first_name, last_name },
      } = userAdmin;

      const fullNameAdmin = first_name + " " + last_name;

      formMeetingDone.status = newStatus;

      const dataApi: any = {
        screening_info: formMeetingDone,
        userAdmin: fullNameAdmin,
        status: newStatus,
        meeting: JSON.stringify(meetings),
      };

      if (fullNameAdmin !== newMeet.calendar) {
        dataApi.meeting_calendar = fullNameAdmin;
        dataApi.owned_by = fullNameAdmin;
      }

      callPutUpdateCandidate.mutate({ id: rowSelected.id, data: dataApi });
      setOpenMeeting(false);
    },
    [formMeetingDone, formatUser, rowSelected, userAdmin]
  );

  const handleSubmitUserUpdate = ({ id, formUser }) =>
    callPutUpdateCandidate.mutate({ id, data: formUser });

  const columns: GridColDef[] = [
    {
      field: "application",
      headerName: "Application",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => `${row.application}`,
    },
    {
      field: "created_at",
      headerName: "Date & Time",
      minWidth: 150,
      valueGetter: ({ row }) =>
        `${format(parseISO(row.created_at), "EEE, dd-M HH:mm")}`,
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => `${row.fullName}`,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 200,
      valueGetter: ({ row }) => `${row.phone}`,
    },
    {
      field: "email",
      headerName: "email",
      minWidth: 200,
      valueGetter: ({ row }) => `${row.email}`,
    },
    {
      field: "languages",
      headerName: "languages",
      minWidth: 150,
      valueGetter: ({ row }) => `${row.languages ?? "-"}`,
    },
    {
      field: "status",
      headerName: "status",
      minWidth: 250,
      valueGetter: ({ row }) => `${row.status}`,
      renderCell: ({ row }) => (
        <FormControl fullWidth>
          <Select
            name="status"
            value={row.status}
            onChange={(e) => {
              callPutUpdateCandidate.mutateAsync({
                id: row.id,
                data: {
                  status: e.target.value,
                  userAdmin:
                    userAdmin.user.first_name + " " + userAdmin.user.last_name,
                },
              });
            }}
          >
            {formsStatus}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "comment",
      headerName: "Comments",
      minWidth: 150,
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        const latestComment =
          row?.note?.length > 0 ? row.note[row.note.length - 1] : "No comments";

        return (
          <Box
            color="primary"
            display="flex"
            alignItems="center"
            onClick={() => {
              setOpen(true);
              setSelectedUserId(row.token);
            }}
            style={{ cursor: "pointer" }}
          >
            {latestComment?.content ? (
              <Box>{latestComment.content}</Box>
            ) : (
              <ListItemIcon>
                <img
                  src={commentsIcon}
                  alt="comments icon"
                  className="icon"
                />
              </ListItemIcon>
            )}
          </Box>
        );
      },
    },
    {
      field: "button 1",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          title="Whatsapp message"
          onClick={() => handleWhatsApp(row)}
        >
          <i
            style={{ fontSize: 16 }}
            className="fa-brands fa-whatsapp"
          />
        </Button>
      ),
    },
    {
      field: "button 2",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        ["Partially filled", "Screened out"].includes(row.status) ? (
          <Button
            className="flex items-center justify-center"
            title="View"
            onClick={() => {
              setTutorSelected(row);
              setViewInfoTutor(true);
            }}
          >
            <img
              src={viewIcon}
              className="icon no-margin"
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button 3",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        ["Partially filled"].includes(row.status) ? (
          <Button
            className="flex items-center justify-center"
            title="Copy link to complete form"
            onClick={(e: any) =>
              copyToClipboard(`${RECRUITING_BASE}${row.form_uuid}/${row.id}`)
            }
          >
            <img
              src={linkIcon}
              className="icon no-margin"
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button 4",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [
          "Rejected screening",
          "Interview with manager scheduled",
          "Rejected interview",
          "Employed",
          "Offer rejected",
        ].includes(row.status) ? (
          <Button
            className="flex items-center justify-center"
            title="Screening info"
            onClick={() => {
              setRowSelected(row);
              setOpenScreening(true);
            }}
          >
            <img
              src={viewIcon}
              className="icon no-margin"
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 100,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <>
          <Dropdown text={<MoreVertIcon />}>
            <MenuList>
              {["Partially filled", "Screened out"].includes(row.status) && (
                <MenuItem
                  title="Info"
                  color="primary"
                  onClick={() => {
                    setTutorSelected(row);
                    setViewInfoTutor(true);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={viewIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                title="Copy email"
                color="primary"
                onClick={() => copyToClipboard(row.email)}
              >
                <ListItemIcon>
                  <img
                    src={mailIcon}
                    className="icon no-margin"
                  />
                </ListItemIcon>
                <ListItemText>Copy email</ListItemText>
              </MenuItem>

              {["Partially filled"].includes(row.status) && (
                <MenuItem
                  title="Copy link to complete form"
                  onClick={(e: any) =>
                    copyToClipboard(
                      `${RECRUITING_BASE}${row.form_uuid}/${row.id}`
                    )
                  }
                >
                  <ListItemIcon>
                    <img
                      src={linkIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Link to complete</ListItemText>
                </MenuItem>
              )}

              {["Schedule screening"].includes(row.status) && (
                <MenuItem
                  title="Copy link to calendar"
                  onClick={() =>
                    copyToClipboard(
                      `${RECRUITING_BASE}${row.form_uuid}/${row.id}/schedule`
                    )
                  }
                >
                  <ListItemIcon>
                    <img
                      src={linkIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Link to calendar</ListItemText>
                </MenuItem>
              )}

              {["Screening scheduled"].includes(row.status) && (
                <MenuItem
                  title="Video meeting"
                  onClick={() => {
                    setOpenMeeting(true);
                    setRowSelected(row);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={videoIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Video meeting</ListItemText>
                </MenuItem>
              )}

              {["Screening scheduled", "No show"].includes(row.status) && (
                <MenuItem
                  title="Reschedule link"
                  onClick={(e: any) =>
                    copyToClipboard(
                      `${RECRUITING_BASE}${row.form_uuid}/${row.id}/reschedule`
                    )
                  }
                >
                  <ListItemIcon>
                    <img
                      src={linkIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Reschedule link</ListItemText>
                </MenuItem>
              )}

              {[
                "Rejected screening",
                "Interview with manager scheduled",
                "Rejected interview",
                "Employed",
                "Offer rejected",
              ].includes(row.status) && (
                <MenuItem
                  title="Screening info"
                  onClick={() => {
                    setRowSelected(row);
                    setOpenScreening(true);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={viewIcon}
                      className="icon no-margin"
                    />
                  </ListItemIcon>
                  <ListItemText>Screening info</ListItemText>
                </MenuItem>
              )}

              <MenuItem
                title="Delete"
                color="primary"
                onClick={() => {
                  setDeleteId(row.id);
                  setOpenConfirmDialog(true);
                }}
              >
                <ListItemIcon>
                  <img
                    src={deleteIcon}
                    className="icon "
                  />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          </Dropdown>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!data && !selectedUserId) return;

    setSelectedUser(
      data?.data.filter(
        (user: { token: string }) => user.token === selectedUserId
      )
    );
  }, [selectedUserId]);

  useEffect(() => {
    setCalendars(userAdmin?.calendars?.[typeOf]?.map((e: any) => e?.name));
  }, [typeOf, userAdmin]);

  return (
    <BasePage title="Recruiting">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        sortingMode="server"
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            filters: filterData,
            setFilters,
            recruitingRef,
          },
          pagination: { page, setPage, pagination },
        }}
      />

      <Comments
        open={open}
        setOpen={setOpen}
        cacheKey={cacheKey}
        userAdmin={userAdmin}
        selectedUser={selectedUser && selectedUser[0]}
      />

      <RecruitingDialog ref={recruitingRef} />

      {viewInfoTutor && (
        <ViewInfoDialog
          user={tutorSelected}
          open={viewInfoTutor}
          onClose={() => {
            setViewInfoTutor(false);
            setTutorSelected(null);
          }}
        />
      )}

      {openScreening && (
        <ViewScreeningInfoDialog
          user={rowSelected}
          open={openScreening}
          onClose={() => {
            setOpenScreening(false);
            setRowSelected({});
          }}
        />
      )}

      {openMeeting && (
        <MeetingComponent
          open={openMeeting}
          onClose={() => (
            setOpenMeeting(false),
            setFormMeetingDone(defaultFormMeetingDone),
            setRowSelected({})
          )}
          data={{
            user: formatUser,
            userAdmin,
            buttons: {
              noShow: {
                icon: noShowIcon,
                name: "No show",
                status: "No show",
              },
              onHold: {
                icon: noShowIcon,
                name: "On-hold",
                status: "On-hold",
              },
              rejected: {
                icon: noShowIcon,
                name: "Rejected screening",
                status: "Rejected screening",
                form: formMeetingDone,
                setForm: handleSetFormMeetingDone,
              },
              notInterested: {
                icon: noShowIcon,
                name: "Not interested",
                status: "Not interested",
              },
              scheduled: {
                icon: rescheduledIcon,
                name: "Interview scheduled",
                status: "Interview with manager scheduled",
                form: formMeetingDone,
                setForm: handleSetFormMeetingDone,
              },
            },
          }}
          functions={{
            onUpdateRow: handleUpdateRowCrmCall,
            onMeetingDone: handleUpdate,
            onSendFormUser: handleSubmitUserUpdate,
          }}
        />
      )}

      {openConfirmDialog && (
        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          title={
            <>
              <img src={deleteIcon} /> DELETE Candidate
            </>
          }
          onConfirm={() => {
            if (!deleteId) return;
            callDeleteCandidate.mutate({
              id: deleteId,
            });
            setOpenConfirmDialog(false);
          }}
        >
          Are you sure to remove this candidate?
        </ConfirmationDialog>
      )}
    </BasePage>
  );
};

export default Recruiting;
