import useAPI from "@/api/useAPI";
import useProductApi from "@/api/useProductApi";
import callIcon from "@/assets/icons/call.svg";
import copyIcon from "@/assets/icons/copy.svg";
import eventIcon from "@/assets/icons/event.svg";
import mailIcon from "@/assets/icons/mail.svg";
import meetingDoneIcon from "@/assets/icons/meeting-done.svg";
import noShowIcon from "@/assets/icons/no-show.svg";
import presenceIcon from "@/assets/icons/presence.svg";
import rescheduledIcon from "@/assets/icons/rescheduled.svg";
import toBeCallIcon from "@/assets/icons/to-be-call.svg";
import videoIcon from "@/assets/icons/video.svg";
import viewIcon from "@/assets/icons/view.svg";
import {
  EmojiEventsRoundedIcon,
  ForwardIcon,
  HardwareRoundedIcon,
  InfoIcon,
  MessageRoundedIcon,
  PriceCheckIcon,
  RefreshRoundedIcon,
  TimerRoundedIcon,
} from "@/components";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import emailUpsellingBtnCup from "@/components/assets/crm-upselling/emailUpsellingBtnCup";
import ENGLISH_LEVELS from "@/components/assets/ENGLISH_LEVELS";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import AddInterestedEventDialog, {
  IAddInterestedEventDialog as IEventDialog,
} from "@/components/Crm/AddInterestedEventDialog";
import ConfirmPaymentDialog from "@/components/Crm/ConfirmPaymentDialog";
import DialogNewStatusToBeConverted, {
  IRef as IDialogNewStatusToBeConvertedRef,
} from "@/components/Crm/DialogNewStatusToBeConverted";
import MeetingComponent, {
  IFormMeetingDone,
  INUserMeeting,
} from "@/components/Crm/MeetingComponent";
import FlagRender from "@/components/FlagRender";
import MyTooltip from "@/components/MyTooltip";
import BasePage from "@/components/Page/Base";
import useGenerateTemplateCrm, {
  convertRowCrm,
} from "@/components/useGenerateTemplateCrm";
import {
  clone,
  convert,
  euro,
  fUpperWords,
  filterObject,
  jP,
} from "@/components/useHelpers";
import {
  IAnchorElProduct,
  PopoverProduct,
  RenderCellProduct,
  defaultAnchorElProduct,
  valueGetterProduct,
} from "@/components/User/PopoverProduct";
import useAuth from "@/hooks/useAuth";
import useDGS from "@/hooks/useDataGridSort";
import usePagination from "@/hooks/usePagination";
import useScreen from "@/hooks/useScreen";
import { queryClient } from "@/main";
import CrmCall, { INUserCrmCall } from "@/pages/Crm/CrmCall";
import PresenceUserDialog, {
  IForwardRef as IPresenceUserDialogRef,
} from "@/pages/Users/components/PresenceUserDialog";
import { getTableStyling } from "@/providers/Colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { addMonths, format } from "date-fns";
import dayjs from "dayjs";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ViewCrmDialog from "../../v2/Crm/components/ViewCrmDialog";
import {
  INAnchorsEl,
  INButtonCup,
  INCodeValues,
  INConfirmDialog,
  INDialogsEl,
  INStatus,
  INUpdateRow,
  defaultAnchorEl,
  defaultButtonCup,
  defaultConfirmDialog,
  defaultDialogsEl,
  defaultFormMeetingDone,
} from "./interfacesAndDefaults";
import PopoverUpselling from "./PopoperUpselling";
import TemplateMail from "./TemplateEmail";
import Toolbar from "./Toolbar";
import { baseWhatsApp, hammerWhatsApp, timerWhatsApp } from "./WhatsAppTexts";

const GENERATE_NUMBER_MONTHS = 12;

const ROUTE_API_INDEX = "/v1/upselling/crm";
const ROUTE_API_UPDATE = "/v1/upselling/crm/update";
const ROUTE_API_TEMPLATE_EMAIL_SEND = "/v1/upselling/crm/template/email/send";

const DEFAULT_EXPIRE_CODE = 2;

const CHECKOUT_BASE: string = import.meta.env.VITE_CHECKOUT_BASE;
const BASE_PAYMENT_LINK = CHECKOUT_BASE + "existing-user";

export const STATUS: INStatus = {
  Admin: "Admin",
  NoBooked: "No Booked",
  MailSent: "Mail sent",
  ToBeCalled: "To be called",
  UpsellingScheduled: "Upselling scheduled",
  UpsellingDone: "Upselling done",
  ToBeConverted: "To be converted",
  Reschedule: "Reschedule",
  InterestedInTheFuture: "Interested in the future",
  StandBy: "Stand by",
  OfferRejected: "Offer rejected",
  NoShow: "No show",
  NotInterested: "Not interested",
  Upsold: "Upsold",
};

const MEETING_STATUS_OPTIONS = [
  { status: "present", color: "#58ff2f" },
  { status: "no reply", color: "#FFD700" },
];

const CONVERT_STATUS_OPTIONS = [
  { status: "soon buying", color: "#58ff2f" },
  { status: "no reply", color: "#FFD700" },
];

const defaultCodeValues: INCodeValues = {
  code: "",
  expires_at: DEFAULT_EXPIRE_CODE,
};

const CrmUpselling = () => {
  const theme = useTheme();
  const { data: products } = useProductApi().callGetProducts();
  const { screen, perPage } = useScreen();
  const { userAdmin, snackHandler, copyToClipboard } = useAuth();
  const [viewCrmDialog, setViewCrmDialog] = useState<{
    data: any;
    open: boolean;
  }>({ open: false, data: null });

  const eventRef = createRef<IEventDialog>();
  const PresenceUserDialogRef = createRef<IPresenceUserDialogRef>();
  const dialogNewStatusToBeConvertedRef =
    createRef<IDialogNewStatusToBeConvertedRef>();

  const { data: imgCalendars } = useQuery(["crm-get-img-calendars"], () =>
    useAPI("/v1/crm/get-img-calendars")
  );

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [comments, setComments] = useState<string>("");
  const [anchorsEl, setAnchorsEl] = useState<INAnchorsEl>(defaultAnchorEl);
  const [buttonCup, setButtonCup] = useState<INButtonCup>(defaultButtonCup);
  const [dialogsEl, setDialogsEl] = useState<INDialogsEl>(defaultDialogsEl);
  const [codeValues, setCodeValues] = useState<INCodeValues>(defaultCodeValues);
  const [rowSelected, setRowSelected] = useState<any>({});
  const [crmUpselling, setCrmUpselling] = useState<any>();
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const [formMeetingDone, setFormMeetingDone] = useState<IFormMeetingDone>(
    defaultFormMeetingDone
  );
  const [newStartingMonth, setNewStartingMonth] = useState<
    Array<string> | undefined
  >();
  const [confirmDialogData, setConfirmDialogData] =
    useState<INConfirmDialog>(defaultConfirmDialog);
  const [loadingConfirmPayment, setLoadingConfirmPayment] =
    useState<boolean>(false);

  const [filters, setFilters] = useSearchParams({});
  const filterData = Object.fromEntries(filters.entries());

  const { sortModel, onSortModelChange } = useDGS({ filters, setFilters });

  const { page, setPage } = usePagination({ setFilters, filters });

  const sorts = filterData?.sorts;
  delete filterData?.page;
  delete filterData?.sorts;
  const KEY_QUERY = ["upselling", "crm", page, filterData, perPage, sorts];

  const { data: dataAPI, isLoading } = useQuery(
    KEY_QUERY,
    () =>
      useAPI(ROUTE_API_INDEX, {
        page,
        sorts,
        filters: filterData,
        paginate: perPage,
      }),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const handleSetFormMeetingDone = useCallback(
    (key: string, value: string): void =>
      setFormMeetingDone((p: IFormMeetingDone) => ({ ...p, [key]: value })),
    []
  );

  const meetingsRowSelected = useMemo(
    () =>
      typeof rowSelected?.crm_upselling?.meetings === "string"
        ? jP(rowSelected?.crm_upselling?.meetings)
        : rowSelected?.crm_upselling?.meetings,
    [rowSelected]
  );

  const notesMeetings = useMemo(
    () =>
      meetingsRowSelected?.meet
        ?.filter((m: any) => Boolean(m?.notes))
        ?.map((n: any) => ({
          start: n.start,
          notes: n.notes,
          interviewer: n.interviewer,
        })) || [],
    [meetingsRowSelected]
  );

  const pacchetto: string = useMemo(
    () =>
      meetingsRowSelected?.meet
        ?.filter((m: any) => Boolean(m?.pacchetto))
        ?.at(-1)?.pacchetto || "",
    [meetingsRowSelected]
  );

  const formatUserCrmCall: INUserCrmCall = useMemo(
    () => ({
      first_name: rowSelected.first_name,
      email: rowSelected.email,
      phone: rowSelected.phonee,
      token: rowSelected.token,
      status: rowSelected.crm_upselling?.status || "New",
      answers: undefined,
      last_name: rowSelected.last_name,
      updates: rowSelected.crm_upselling?.updates || undefined,
      details: rowSelected.crm_upselling?.details || undefined,
      meetings: rowSelected.crm_upselling?.meetings,
      created_at:
        rowSelected.crm_upselling?.created_at ||
        format(new Date(), "dd/MM/yyyy HH:mm:ss"),
      lang: rowSelected.lang,
      tutoring: rowSelected.tutoring,
    }),
    [rowSelected]
  );

  const formatUserCrmMeeting: INUserMeeting = useMemo(
    () => ({
      id: rowSelected.id,
      first_name: rowSelected.first_name,
      phone: rowSelected.phone,
      email: rowSelected.email,
      token: rowSelected.token,
      status: rowSelected.crm_upselling?.status || "New",
      last_name: rowSelected.last_name,
      updates: rowSelected.crm_upselling?.updates || undefined,
      tutoring: rowSelected.tutoring,
      meetings: rowSelected.crm_upselling?.meetings || undefined,
    }),
    [rowSelected]
  );

  const popoverInfo = useMemo(() => {
    let lastMeet = rowSelected?.crm_upselling?.meetings?.meet
      ?.filter((meet: any) => Boolean(meet?.pacchetto))
      ?.at(-1);

    let path: string = products?.products?.find(
      (product: any) => product?.token === lastMeet?.pacchetto
    )?.product_tag;

    const dis = rowSelected?.discount_codes?.[0];

    return {
      path,
      code: dis?.code,
      used: parseInt(dis?.pivot?.used),
      amount: "-" + dis?.amount + (dis?.is_percentage ? "%" : "€"),
      expires_at: dis?.pivot?.expires_at,
      english_lvl: lastMeet?.english_lvl,
      newStartingMonth: lastMeet?.newStartingMonth,
    };
  }, [
    products,
    rowSelected,
    products?.products,
    rowSelected?.discount_codes,
    rowSelected?.crm_upselling?.meetings?.meet,
  ]);

  const newStartingMonths = useMemo(() => {
    const futureMonths: Array<string> = [];
    for (let i = 0; i <= GENERATE_NUMBER_MONTHS; i++) {
      const futureMonth = addMonths(new Date(), i);
      const formattedMonth = format(futureMonth, "MMMM yyyy");
      futureMonths.push(
        formattedMonth.charAt(0).toUpperCase() +
          formattedMonth.slice(1).toLowerCase()
      );
    }
    return futureMonths;
  }, []);

  const handleCloseConfirmDialog = useCallback((): void => {
    setConfirmDialogData(defaultConfirmDialog);
  }, []);

  const handleUpdateRow = useCallback(
    async (params: INUpdateRow): Promise<void> => {
      const defaultParams = {
        toast: "",
        values: {},
        tokenUser: "",
        activeApi: true,
        otherRows: rows,
      };
      const { toast, values, tokenUser, activeApi, otherRows } = {
        ...defaultParams,
        ...params,
      };

      const token = tokenUser || rowSelected?.token;

      const rows2 = clone(otherRows);
      const i = rows2.findIndex((row: any) => row.token === token);

      if (activeApi) {
        useAPI(ROUTE_API_UPDATE, {
          values,
          token_user: token,
          admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
        });
      }

      if (!rows2[i]?.crm_upselling) {
        rows2[i].crm_upselling = { token_user: tokenUser, ...values };
      } else {
        rows2[i].crm_upselling = { ...rows2[i].crm_upselling, ...values };
      }

      handleCloseConfirmDialog();

      setRows(rows2);
      setAnchorsEl(defaultAnchorEl);
      setDialogsEl(defaultDialogsEl);

      queryClient.invalidateQueries(KEY_QUERY);

      switch (toast) {
        case "comments":
          snackHandler(`New comments saved!`);
          break;
        case "status":
          snackHandler(`New status "${values.status}"`);
          break;
        case "change":
          snackHandler(`Change response!`);
          break;
        default:
          break;
      }
    },
    [rows, rowSelected, userAdmin, dataAPI]
  );

  const handleMeetingDone = useCallback(
    async (
      selectedEvent: any,
      eventId: number | string,
      newStatus: string,
      useSetUpdate: boolean = true
    ): Promise<any> => {
      setConfirmDialogData(defaultConfirmDialog);
      setDialogsEl(defaultDialogsEl);

      const newMeet = {
        ...selectedEvent,
        ...filterObject(formMeetingDone, [null]),
      };

      const res = await useAPI("/v1/upselling/crm/update-meeting", {
        token_user: rowSelected.token,
        data: newMeet,
        admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
        status: newStatus,
        useSetUpdate,
      });

      if (!res || !res?.status) {
        snackHandler(`Error update meet!`, "error");
        return;
      }

      setNewStartingMonth(res?.newStartingMonth);

      const rows2 = clone(rows);
      const i = rows2.findIndex((row) => row.token === rowSelected.token);

      const indexMeet = rows2[i].crm_upselling.meetings.meet.findIndex(
        (f: any) => f.id === newMeet.id
      );

      const meets = rows2[i].crm_upselling.meetings.meet[indexMeet];

      rows2[i].crm_upselling.meetings.meet[indexMeet] = {
        ...meets,
        ...newMeet,
      };
      rows2[i].crm_upselling.status = newStatus;

      setRows(rows2);

      queryClient.invalidateQueries(KEY_QUERY);

      snackHandler(`Update meet and new status "${newStatus}"`);

      setFormMeetingDone(defaultFormMeetingDone);

      return rows2[i];
    },
    [dataAPI, rows, rowSelected, formMeetingDone]
  );

  const handleMeetingDoneOther = useCallback(
    async (newStatus: string): Promise<any> => {
      setConfirmDialogData(defaultConfirmDialog);
      setDialogsEl(defaultDialogsEl);

      const rows2 = clone(rows);
      const i = rows2.findIndex((row) => row.token === rowSelected.token);

      const newMeet = {
        meet: [
          {
            ...filterObject(formMeetingDone, [null]),
            id: "other-meet-" + (Math.random() * 100000).toFixed(0),
            start: dayjs().startOf("minutes").format("YYYY-MM-DD HH:mm:ss"),
            end: dayjs()
              .startOf("minutes")
              .add(20, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            link: null,
            calendar: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
          },
        ],
        summary:
          (rows2?.[i]?.fullName ? rows2?.[i]?.fullName + " e " : "") +
          "Edusogno",
      };

      const res = await useAPI("/v1/upselling/crm/update-meeting-other", {
        admin: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
        status: newStatus,
        meetings: newMeet,
        token_user: rowSelected.token,
      });

      if (!res?.status) {
        snackHandler(res?.message ?? `Error update meet!`, "error");
        return;
      }

      rows2[i].meetings = newMeet;

      setRows(rows2);

      queryClient.invalidateQueries(KEY_QUERY);
      snackHandler(`Update meet and new status "${newStatus}"`);
      setFormMeetingDone(defaultFormMeetingDone);
    },
    [dataAPI, rows, rowSelected, formMeetingDone]
  );

  const getLastByKey = useCallback(
    (row: any, key: string): any =>
      row?.crm_upselling?.meetings?.meet
        ?.filter((m: any) => Boolean(m?.[key]))
        ?.at(-1),
    []
  );

  const handleConfirmSendMail = useCallback(async (): Promise<void> => {
    let meet: any = getLastByKey(rowSelected, "pacchetto");

    let product: string | undefined = meet?.pacchetto;

    if ("newStartingMonth" in codeValues) {
      meet = {
        ...meet,
        ...(codeValues?.newStartingMonth
          ? { newStartingMonth: codeValues.newStartingMonth }
          : {}),
        ...(codeValues?.product ? { pacchetto: codeValues.product } : {}),
      };

      product = getLastByKey(
        await handleMeetingDone(meet, "", STATUS.ToBeConverted, false),
        "pacchetto"
      )?.pacchetto;
    }

    if (!product) {
      snackHandler("Error, the product is missing!", "error");
      return;
    }

    setButtonCup(
      (p: INButtonCup): INButtonCup => ({ ...p, isLoadingConfirm: true })
    );

    const res = await useAPI(ROUTE_API_TEMPLATE_EMAIL_SEND, {
      ...codeValues,
      userToken: rowSelected.token,
      template: emailUpsellingBtnCup({
        code: dataAPI.codes.find((c: any) => c.id === codeValues.code).code,
        link: `${BASE_PAYMENT_LINK}/${product}/${rowSelected.token}`,
        nameUser: rowSelected.first_name,
        lang: rowSelected?.lang,
        expires_at: codeValues?.expires_at,
      }),
    });

    if (res?.success == false) {
      snackHandler("Error send email!", "error");
      setButtonCup(defaultButtonCup);
      setDialogsEl(defaultDialogsEl);
      return;
    }

    snackHandler(
      `Send email to ${rowSelected.first_name} ${rowSelected.last_name}`
    );

    setAnchorsEl(defaultAnchorEl);
    setButtonCup(defaultButtonCup);
    setDialogsEl(defaultDialogsEl);
    setCodeValues(defaultCodeValues);

    const rows2: any = clone(rows);
    const i = rows2.findIndex((row: any) => row.token === rowSelected.token);

    rows2[i].discount_codes = [res?.discount];

    handleUpdateRow({
      values: { status: STATUS.ToBeConverted },
      otherRows: rows2,
    });
  }, [dataAPI, rows, rowSelected, codeValues]);

  const handleConfirmPayment = useCallback(
    (user: any, paymentData: any): void => {
      const data = {
        first_name: user.first_name,
        email: user.email,
        phone: user.phone,
        tokenUser: user.token,
        last_name: user.last_name,
        isUpselling: true,
        newStatus: STATUS.Upsold,
        ...paymentData,
      };

      setConfirmDialogData(
        (p: INConfirmDialog): INConfirmDialog => ({
          ...p,
          open: true,
          title: (
            <Box className="flex items-center gap-1">
              <PriceCheckIcon fontSize="small" />
              <Box>PAID</Box>
            </Box>
          ),
          text: `Do you confirm that ${rowSelected?.first_name} ${rowSelected?.last_name} has paid?`,
          onAgree: async (): Promise<void> => {
            setConfirmDialogData(
              (p: INConfirmDialog): INConfirmDialog => ({ ...p, open: false })
            );

            setLoadingConfirmPayment(true);

            const res = await useAPI("/v1/users/confirm-payment-v2", data);

            setLoadingConfirmPayment(false);

            if (!res || !res?.status) {
              snackHandler("Error confirm payment!", "error");
              return;
            }

            snackHandler("Success confirm payment!");
            handleUpdateRow({
              toast: "status",
              values: { status: STATUS.Upsold },
              activeApi: false,
            });
            handleCloseConfirmDialog();
          },
          onDisagree: handleCloseConfirmDialog,
        })
      );
    },
    [rowSelected]
  );

  const handleSetAnchorEl = (row: any, params: INAnchorsEl): void => {
    setRowSelected(row);
    setAnchorsEl((p: INAnchorsEl) => ({ ...p, open: true, ...params }));
  };

  const handleSetDialogEl = (typeAnchor: keyof INDialogsEl, row: any): void => {
    setRowSelected(row);
    setDialogsEl((prevState) => ({
      ...prevState,
      [typeAnchor]: { value: true },
    }));
  };

  const handleGenerateLinkCheckout = (row: any) => {
    let product: string | undefined = getLastByKey(row, "pacchetto")?.pacchetto;

    if (!product) {
      snackHandler("Error, the product is missing!", "error");
      return false;
    }

    return copyToClipboard(`${BASE_PAYMENT_LINK}/${product}/${row.token}`);
  };

  const handleGenerateEmail = async (e: any) => {
    e.preventDefault();

    let product: string | undefined = getLastByKey(
      rowSelected,
      "pacchetto"
    )?.pacchetto;

    if ("product" in codeValues) {
      product = codeValues?.product;
    }

    if (!product) {
      snackHandler("Error, the product is missing!", "error");
      return;
    }

    setButtonCup((p: INButtonCup) => ({
      ...p,
      template: emailUpsellingBtnCup({
        ...codeValues,
        code: dataAPI.codes.find((c: any) => c.id === codeValues.code).code,
        link: `${BASE_PAYMENT_LINK}/${product}/${rowSelected.token}`,
        nameUser: rowSelected.first_name,
        lang: rowSelected?.lang,
      }),
    }));

    setDialogsEl((p: INDialogsEl) => ({
      ...p,
      templateEmail: { value: true },
    }));
  };

  const handleSetEnglishLevel = async (value: string) => {
    setAnchorsEl(defaultAnchorEl);

    const res = await useAPI(`/v1/users/${rowSelected.token}/update`, {
      english_level: value,
    });

    if (res.english_level === value) {
      snackHandler("Success change english level");
    } else {
      snackHandler("Failed change english level", "error");
    }

    try {
      setRows((p: any) => {
        const t = clone(p);
        const i = t.findIndex((r: any) => r.token === rowSelected.token);
        setRowSelected(t[i]);
        t[i].english_level = value;
        return t;
      });
    } catch ($error) {
      queryClient.invalidateQueries(KEY_QUERY);
    }
  };

  const handleResetDiscountCodeUser = async (token: string) => {
    const res = await useAPI("/v1/users/reset-discount-code", {
      token,
    });

    if (res?.status) {
      snackHandler(
        `Success reset discount "${res?.discount?.code}" and +1 day from now`
      );
    } else {
      snackHandler(`Failed reset discount code`, "error");
    }
  };

  const handleSendMessageReminder = (row: any) => {
    const expires_at = row?.discount_codes?.[0]?.pivot?.expires_at;

    // if (!expires_at) {
    //   snackHandler("The discount code is expired", "error");
    //   return;
    // }

    hammerWhatsApp({
      phone: row.phone,
      nameUser: row.first_name,
      nameAdmin: userAdmin.user.first_name,
      expirationDateTime: expires_at,
    });
  };

  const handleInterestedInTheFuture = (row: any) =>
    eventRef.current?.open({
      date: "",
      first_name: row.first_name,
      token: row.token,
      last_name: row.last_name,
    });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "lang",
        headerName: "",
        align: "center",
        maxWidth: 75,
        renderCell: ({ row }) => (
          <Box className="flex items-center">
            <FlagRender
              flag={
                row?.lang == "de"
                  ? row?.cell?.includes("+41")
                    ? "ch"
                    : "de"
                  : row?.lang
              }
            />
          </Box>
        ),
      },
      {
        field: "end_date",
        headerName: "End month",
        minWidth: 150,
        flex: 0.5,
        valueGetter: ({ row }) => row?.products?.[0]?.mese_fine || "-",
      },
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 180,
        flex: 1,
        valueGetter: ({ row }) => row?.fullName || "-",
        renderCell: ({ row }) => {
          const v = row.fullName;
          return row.fullName ? (
            v.length >= 16 ? (
              <MyTooltip>{v}</MyTooltip>
            ) : (
              v
            )
          ) : (
            "-"
          );
        },
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "english_level",
        headerName: "English level",
        minWidth: 120,
        flex: 0.5,
      },
      {
        field: "product",
        headerName: "Product",
        minWidth: 150,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => valueGetterProduct(row),
        renderCell: ({ row }) => (
          <RenderCellProduct
            {...{
              row,
              setAnchorElProduct,
            }}
          />
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 230,
        renderCell: ({ row }) => (
          <FormControl
            className="w-full"
            size="small"
          >
            <Select
              name="status"
              value={row?.crm_upselling?.status ?? "New"}
              onChange={(e: any) =>
                handleUpdateRow({
                  toast: "status",
                  values: { status: e.target.value },
                  tokenUser: row.token,
                })
              }
            >
              {Object.values(STATUS).map((status, i) => (
                <MenuItem
                  key={status + i}
                  value={status}
                >
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
      {
        field: "meeting_date",
        headerName: "Meeting date",
        width: 135,
        valueGetter: ({ row }) => {
          if (!row?.crm_upselling?.meetings) return "-";

          const meetings = convert(row?.crm_upselling?.meetings);

          const meet = (meetings?.meet ?? [])?.at(-1)?.start;
          const meetToBeCalled = (meetings?.meetToBeCalled ?? [])?.at(
            -1
          )?.start;
          let date: string | null = null;
          if (meetToBeCalled) {
            date =
              meet && dayjs(meet).isBefore(dayjs(meetToBeCalled))
                ? meetToBeCalled
                : meet ?? meetToBeCalled;
          } else if (meet) {
            date = meet;
          }

          if (!date) return "-";

          return date
            ? dayjs(date).tz("Europe/Rome").format("ddd, DD-M HH:mm")
            : dayjs(row?.meeting_date)
                .tz("Europe/Rome")
                .format("ddd, DD-M HH:mm");
        },
      },
      {
        field: "owned_by",
        headerName: "Owned by",
        width: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: ({ row }) => row?.crm_upselling?.owned_by || "NA",
        renderCell: ({ row }) => {
          const calendar = row?.crm_upselling?.owned_by;

          if (!calendar) return "NA";

          const name = fUpperWords(calendar);

          return (
            <Tooltip
              arrow
              title={name}
              placement="bottom-start"
            >
              <Avatar
                alt={name}
                src={imgCalendars?.[calendar]?.img}
                style={{ height: "2.5rem", width: "2.5rem" }}
              />
            </Tooltip>
          );
        },
      },
      {
        field: "ltv",
        headerName: "LTV",
        width: 110,
        valueGetter: ({ row }) => euro(row?.ltv),
      },
      {
        field: "upselling_tracks",
        headerName: "Upselling Tracks Response",
        width: 150,
        valueGetter: ({ row }) => {
          const tracks = row?.upselling_tracks || [];
          if (tracks.length === 0) return "-";

          const latestTrack = tracks.reduce((latest, current) => {
            return new Date(current.created_at) > new Date(latest.created_at)
              ? current
              : latest;
          });
          const responseMap = {
            "Yes, I want to keep improving my english": "Yes",
            "No, I don't want to continue": "No",
          };

          return responseMap[latestTrack.response] || "-";
        },
      },
      {
        field: "upselling_tracks_count",
        headerName: "Upselling Tracks Response",
        width: 110,
        valueGetter: ({ row }) => row?.upselling_tracks_count,
      },
      {
        field: "feedbacks",
        headerName: "Rating",
        width: 70,
        valueGetter: ({ row }) => row?.feedbacks || "-",
      },
      {
        field: "count_feedbacks",
        headerName: "#Feedbacks",
        width: 110,
        valueGetter: ({ row }) => row?.count_feedbacks || "-",
      },
      {
        field: "attendance",
        headerName: "#Attendance",
        width: 130,
        valueGetter: ({ row }) => row?.user_events?.[0]?.attendance || "-",
      },
      {
        field: "button 4",
        headerName: "",
        align: "center",
        maxWidth: 35,
        sortable: false,
        hideable: false,
        editable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) =>
          [STATUS.ToBeConverted].includes(row?.status) ? (
            <Button
              title={
                row?.info?.offer_rejected
                  ? "Offer rejected: " + row?.info?.offer_rejected
                  : "Offer rejected"
              }
              onClick={() =>
                dialogNewStatusToBeConvertedRef?.current?.open(row)
              }
            >
              <img
                src={callIcon}
                className={cn(
                  "icon no-margin",
                  Boolean(row?.info?.offer_rejected)
                    ? "rotate-[165deg]"
                    : "rotate-[135deg]"
                )}
              />
            </Button>
          ) : (
            "-"
          ),
      },
      {
        field: "actions",
        headerName: "",
        width: 70,
        sortable: false,
        editable: false,
        hideable: false,
        filterable: false,
        disableExport: true,
        renderCell: ({ row }) => {
          const meeting_status_color = MEETING_STATUS_OPTIONS?.find(
            (f: any) => f.status === row?.crm_upselling?.meeting_status
          )?.color;

          const convert_status_color = CONVERT_STATUS_OPTIONS?.find(
            (f: any) => f.status === row?.crm_upselling?.convert_status
          )?.color;

          return (
            <EduBadge
              color="primary"
              variant="dot"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={Number(Boolean(row?.crm_upselling?.comments))}
            >
              <Dropdown text={<MoreVertIcon />}>
                <MenuList>
                  <MenuItem
                    key="Copy email"
                    title="Copy email"
                    color="primary"
                    onClick={(e: any) => copyToClipboard(row.email)}
                  >
                    <ListItemIcon>
                      <img
                        src={mailIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy email</ListItemText>
                  </MenuItem>

                  <MenuItem
                    key="WhatsApp"
                    title="Go to WhatsApp"
                    onClick={() =>
                      baseWhatsApp({
                        phone: row.phone,
                        nameUser: row.first_name,
                      })
                    }
                  >
                    <ListItemIcon>
                      <i
                        style={{ fontSize: 16 }}
                        className="fa-brands fa-whatsapp"
                      />
                    </ListItemIcon>
                    <ListItemText>WhatsApp</ListItemText>
                  </MenuItem>

                  {[
                    STATUS.Admin,
                    STATUS.MailSent,
                    STATUS.ToBeCalled,
                    STATUS.Reschedule,
                    STATUS.NoShow,
                    STATUS.NotInterested,
                  ].includes(row?.crm_upselling?.status || "New") && [
                    <MenuItem
                      key="Schedule meet"
                      title="Schedule meet"
                      onClick={() => handleSetDialogEl("firstMeeting", row)}
                    >
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Schedule meet</ListItemText>
                    </MenuItem>,
                  ]}

                  {[STATUS.UpsellingScheduled].includes(
                    row?.crm_upselling?.status
                  ) && [
                    <MenuItem
                      key="Edit meet"
                      title="Edit meet"
                      onClick={() => handleSetDialogEl("updateMeeting", row)}
                    >
                      <ListItemIcon>
                        <img
                          src={videoIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Edit meet</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Send message on WhatsApp"
                      title="Send message on WhatsApp"
                      onClick={() =>
                        useGenerateTemplateCrm(
                          "reminder_1",
                          convertRowCrm({
                            ...row,
                            version: "crm-upselling",
                          }),
                          userAdmin,
                          snackHandler,
                          undefined,
                          () =>
                            timerWhatsApp({
                              meetings: row?.crm_upselling?.meetings?.meet,
                              phone: row.phone,
                              nameUser: row.first_name,
                              nameAdmin: userAdmin.user.first_name,
                              snackHandler,
                            })
                        )
                      }
                    >
                      <ListItemIcon>
                        <TimerRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Send message on WhatsApp</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Set response user"
                      title="Set response user"
                      style={{ color: meeting_status_color || "grey" }}
                      onClick={(e: any) =>
                        handleSetAnchorEl(row, {
                          element: e.currentTarget,
                          buttons: ["present", "no reply"],
                          template: "otherStatus",
                          value: "meeting_status",
                        })
                      }
                    >
                      <ListItemIcon>
                        <Box
                          className="rounded-full transitions-colors duration-200"
                          sx={{
                            p: "0.55rem",
                            boxShadow: meeting_status_color
                              ? `0 0 5px ${meeting_status_color}`
                              : "",
                            bgcolor: meeting_status_color || "grey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Set response user</ListItemText>
                    </MenuItem>,
                  ]}

                  {[STATUS.UpsellingDone, STATUS.StandBy].includes(
                    row?.crm_upselling?.status
                  ) && (
                    <>
                      <MenuItem
                        title="Send template on Email"
                        onClick={() => handleGenerateLinkCheckout(row)}
                      >
                        <ListItemIcon>
                          <img
                            src={copyIcon}
                            className="icon no-margin"
                          />
                        </ListItemIcon>
                        <ListItemText>Copy link for checkout</ListItemText>
                      </MenuItem>

                      <MenuItem
                        title="Send template on Email"
                        onClick={(e) => {
                          setRowSelected(row);
                          handleSetAnchorEl(row, {
                            element: e.currentTarget,
                            template: "email",
                          });
                        }}
                      >
                        <ListItemIcon>
                          <EmojiEventsRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Send template on Email</ListItemText>
                      </MenuItem>
                    </>
                  )}

                  {[STATUS.Upsold, STATUS.ToBeConverted].includes(
                    row?.crm_upselling?.status
                  ) && (
                    <MenuItem
                      title="Info"
                      onClick={(e) =>
                        handleSetAnchorEl(row, {
                          element: e.currentTarget,
                          template: "info",
                        })
                      }
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Info</ListItemText>
                    </MenuItem>
                  )}

                  {[STATUS.ToBeConverted].includes(
                    row?.crm_upselling?.status
                  ) && [
                    <MenuItem
                      key="Send message on WhatsApp"
                      title="Send message on WhatsApp"
                      onClick={() =>
                        useGenerateTemplateCrm(
                          "reminder_3",
                          convertRowCrm({
                            ...row,
                            version: "crm-upselling",
                          }),
                          userAdmin,
                          snackHandler,
                          undefined,
                          () => handleSendMessageReminder(row)
                        )
                      }
                    >
                      <ListItemIcon>
                        <HardwareRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Send message on WhatsApp</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Set response user"
                      title="Set response user"
                      className="relative flex items-center"
                      onClick={(e: any) =>
                        handleSetAnchorEl(row, {
                          element: e.currentTarget,
                          template: "otherStatus",
                          buttons: ["soon buying", "no reply"],
                          value: "convert_status",
                        })
                      }
                    >
                      <ListItemIcon className="flex items-center">
                        <ForwardIcon
                          fontSize="small"
                          sx={{
                            transition: "color .2s linear",
                            position: "absolute",
                            filter: convert_status_color ? "blur(2px)" : "",
                            color: convert_status_color || "grey",
                          }}
                        />

                        <ForwardIcon
                          fontSize="small"
                          sx={{
                            transition: "color .2s linear",
                            position: "absolute",
                            color: convert_status_color || "grey",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>Set response user</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Reset discount code"
                      title="Confirm payment"
                      onClick={() => handleResetDiscountCodeUser(row.token)}
                    >
                      <ListItemIcon>
                        <RefreshRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Reset discount code</ListItemText>
                    </MenuItem>,

                    <MenuItem
                      key="Confirm payment"
                      title="Confirm payment"
                      onClick={() => handleSetDialogEl("confirmPayment", row)}
                    >
                      <ListItemIcon>
                        <PriceCheckIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Confirm payment</ListItemText>
                    </MenuItem>,
                  ]}

                  {[STATUS.Reschedule].includes(row?.crm_upselling?.status) && (
                    <MenuItem
                      title="Copy link for the platform user for rescheduled"
                      onClick={() =>
                        copyToClipboard(
                          `https://edusogno.com/form/reschedule/edusogno-inglese/${row.token}`
                        )
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={eventIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Copy link rescheduled</ListItemText>
                    </MenuItem>
                  )}

                  {[STATUS.NotInterested].includes(
                    row?.crm_upselling?.status
                  ) && (
                    <MenuItem
                      title="Set response user"
                      onClick={(e: any) =>
                        handleSetAnchorEl(row, {
                          element: e.currentTarget,
                          buttons: ENGLISH_LEVELS,
                          template: "changeEnglishLevel",
                        })
                      }
                    >
                      <ListItemIcon>{row.english_level}</ListItemIcon>
                      <ListItemText>Change english level</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    STATUS.ToBeConverted,
                    STATUS.InterestedInTheFuture,
                  ].includes(row?.status) && (
                    <MenuItem onClick={() => handleInterestedInTheFuture(row)}>
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Interested in the future</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    title="Save your comments"
                    onClick={(e) => {
                      setRowSelected(row);
                      setComments(row.crm_upselling.comments || "");
                      handleSetAnchorEl(row, {
                        element: e.currentTarget,
                        template: "comments",
                      });
                    }}
                  >
                    <ListItemIcon>
                      <MessageRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Comments
                      <Badge
                        sx={{ mx: 2 }}
                        variant={
                          row?.crm_upselling?.comments ? "dot" : "standard"
                        }
                        color="primary"
                      />
                    </ListItemText>
                  </MenuItem>

                  <MenuItem
                    color="primary"
                    title="Presence"
                    onClick={() =>
                      PresenceUserDialogRef?.current?.open(row.token)
                    }
                  >
                    <ListItemIcon>
                      <img
                        src={presenceIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>Presence</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Info"
                    color="primary"
                    onClick={() => setViewCrmDialog({ open: true, data: row })}
                  >
                    <ListItemIcon>
                      <img
                        src={viewIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>View</ListItemText>
                  </MenuItem>
                </MenuList>
              </Dropdown>
            </EduBadge>
          );
        },
      },
    ],
    [screen, rows, imgCalendars, popoverInfo, PresenceUserDialogRef]
  );

  useEffect(() => {
    if (!dataAPI) return;
    setRows(dataAPI?.crmUpselling?.data || []);
    setCrmUpselling(dataAPI?.crmUpselling || {});
    setFormMeetingDone((p: IFormMeetingDone) => ({
      ...p,
      interviewer: `${userAdmin.user.first_name} ${userAdmin.user.last_name}`,
    }));
    setNewStartingMonth(dataAPI?.newStartingMonth);
  }, [dataAPI, isLoading]);

  return (
    <BasePage title="Crm Upselling">
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        page={parseInt(page.toString()) - 1}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        components={{
          Toolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_: any, newPage: number) => setPage(newPage + 1)}
            />
          ),
        }}
        sortingMode="server"
        onPageChange={(newPage) => setPage(newPage + 1)}
        componentsProps={{
          toolbar: {
            setPage,
            KEY_QUERY,
            snackHandler,
            status: Object.values(STATUS),
            filters: filterData,
            owned_by: dataAPI?.owned_by,
            endMonth: dataAPI?.endMonth,
            setFilters: setFilters,
            newStartingMonth: newStartingMonth,
          },
          pagination: { page, setPage, pagination: crmUpselling },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <PresenceUserDialog ref={PresenceUserDialogRef} />

      {dialogsEl.firstMeeting.value ? (
        <CrmCall
          isUpselling
          open={dialogsEl.firstMeeting.value}
          data={{
            user: formatUserCrmCall,
            buttons: {
              toBeCalled: { status: STATUS.ToBeCalled },
              scheduleMeeting: { status: STATUS.UpsellingScheduled },
            },
          }}
          onClose={() =>
            setDialogsEl((p: INDialogsEl) => ({
              ...p,
              firstMeeting: { value: false },
            }))
          }
          functions={{ onUpdateRow: handleUpdateRow }}
        />
      ) : null}

      {Boolean(dialogsEl.updateMeeting.value) && (
        <MeetingComponent
          isUpselling
          open={dialogsEl.updateMeeting.value}
          data={{
            user: formatUserCrmMeeting,
            userAdmin: userAdmin,
            buttons: {
              noShow: { icon: noShowIcon, status: STATUS.NoShow },
              reschedule: {
                icon: rescheduledIcon,
                name: "Reschedule meeting",
                status: STATUS.UpsellingScheduled,
                eventMeetingType: "reschedule",
              },

              toBeCall: { icon: toBeCallIcon, status: STATUS.StandBy },
              meetingDone: {
                icon: meetingDoneIcon,
                form: formMeetingDone,
                status: STATUS.UpsellingDone,
                setForm: handleSetFormMeetingDone,
              },
            },
            versionStatus: STATUS,
          }}
          onClose={() => {
            setDialogsEl((p: INDialogsEl) => ({
              ...p,
              updateMeeting: { value: false },
            }));
            setFormMeetingDone(defaultFormMeetingDone);
          }}
          functions={{
            onUpdateRow: handleUpdateRow,
            onMeetingDone: (
              selectedEvent: any,
              eventId: number | string,
              newStatus: string
            ) =>
              setConfirmDialogData({
                open: true,
                title: (
                  <>
                    <img src={videoIcon} /> MEET
                  </>
                ),
                text: `Do you want to confirm set this meet as done for "${rowSelected?.first_name} ${rowSelected?.last_name}"?`,
                onAgree: () =>
                  handleMeetingDone(selectedEvent, eventId, newStatus),
                onDisagree: handleCloseConfirmDialog,
              }),
            onMeetingDoneOther: (newStatus: string) =>
              setConfirmDialogData({
                open: true,
                title: (
                  <>
                    <img src={videoIcon} /> MEET
                  </>
                ),
                text: `Do you want to confirm set this meet as done for "${rowSelected?.first_name} ${rowSelected?.last_name}"?`,
                onAgree: () => handleMeetingDoneOther(newStatus),
                onDisagree: handleCloseConfirmDialog,
              }),
          }}
        />
      )}

      <TemplateMail
        open={dialogsEl.templateEmail.value}
        onClose={() =>
          setDialogsEl((p: INDialogsEl) => ({
            ...p,
            templateEmail: { value: false },
          }))
        }
        onClick={() =>
          setButtonCup((p: INButtonCup) => ({ ...p, showConfirm: true }))
        }
        template={buttonCup.template}
        onConfirm={handleConfirmSendMail}
        isLoading={buttonCup.isLoadingConfirm}
        showConfirm={buttonCup.showConfirm}
      />

      {dialogsEl.confirmPayment.value ? (
        <ConfirmPaymentDialog
          isUpselling
          open={dialogsEl.confirmPayment.value}
          data={{
            user: {
              ...rowSelected,
              last_name: rowSelected.last_name,
              first_name: rowSelected.first_name,
            },
            products: products?.products,
            isLoading: loadingConfirmPayment,
          }}
          onClose={() => {
            setDialogsEl((p: INDialogsEl) => ({
              ...p,
              confirmPayment: { value: false },
            }));

            setLoadingConfirmPayment(false);
          }}
          onSubmit={handleConfirmPayment}
          defaultValues={{
            pacchetto,
            amount: String(
              (products?.products?.find((p: any) => p?.token === pacchetto)
                ?.price as number) / 100
            ),
            paidFor: products?.products?.find(
              (p: any) => p?.token === pacchetto
            )?.product_tag,
            editStartMonthAfterAttach: true,
          }}
        />
      ) : null}

      {Boolean(anchorsEl.open) && (
        <PopoverUpselling
          {...{
            theme,
            dataAPI,
            products,
            comments,
            anchorsEl,
            codeValues,
            setComments,
            rowSelected,
            popoverInfo,
            setAnchorsEl,
            notesMeetings,
            setCodeValues,
            newStartingMonths,
            DEFAULT_EXPIRE_CODE,
            setConfirmDialogData,
            onUpdateRow: handleUpdateRow,
            onGenerateEmail: handleGenerateEmail,
            onSetEnglishLevel: handleSetEnglishLevel,
            onCloseConfirmDialog: handleCloseConfirmDialog,
          }}
        />
      )}

      <AddInterestedEventDialog
        isUpselling
        ref={eventRef}
        dialogNewStatusToBeConvertedRef={dialogNewStatusToBeConvertedRef}
      />

      <DialogNewStatusToBeConverted
        isUpselling
        ref={dialogNewStatusToBeConvertedRef}
        invalidateQuery={() => queryClient.invalidateQueries(KEY_QUERY)}
        onInterestedInTheFuture={handleInterestedInTheFuture}
      />

      <ConfirmationDialog
        open={confirmDialogData.open}
        title={confirmDialogData.title}
        onClose={() => confirmDialogData?.onDisagree?.()}
        onConfirm={() => confirmDialogData?.onAgree?.()}
      >
        {confirmDialogData.text}
      </ConfirmationDialog>

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      <ViewCrmDialog
        user={viewCrmDialog?.data}
        open={Boolean(viewCrmDialog?.open)}
        onClose={() => setViewCrmDialog((p: any) => ({ ...p, open: false }))}
      />
    </BasePage>
  );
};

export default CrmUpselling;
