import useAuth from "@/hooks/useAuth";
import { IApis, IContextCrm } from "./interfaces";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  clone,
  convert,
  filterObject,
  convertToQueryUrl,
} from "@/components/useHelpers";
import useAPI from "@/api/useAPI";
import useScreen from "@/hooks/useScreen";
import useExportCSV from "@/components/useExportCSV";

export default (context: IContextCrm): IApis => {
  const { perPage } = useScreen();
  const { isAdmission, snackHandler } = useAuth();

  const get = (): UseQueryResult<any, unknown> =>
    useQuery(
      context.keyQueryCrm,
      async () => {
        const sorts = context?.filters?.sorts;
        const filter = clone(convert(context?.filters ?? {}));
        delete filter?.sorts;
        delete filter?.page;

        const params = filterObject(
          {
            sorts,
            filter,
            isAdmission,
            page: context.page,
            per_page: perPage,
          },
          [undefined, null, "{}", "[]"]
        );

        const res = await useAPI(
          "/v2/crm" + convertToQueryUrl(params),
          {},
          { type: "get" }
        );

        if (!res?.status === false) {
          snackHandler(res?.message ?? "Failed get!", "error");
          return [];
        }

        return res;
      },
      {
        enabled: perPage > 0,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
      }
    );

  const create = async (
    params: { [x: string]: any },
    callback?: (res: any) => any
  ) => {
    context.setIsLoading(true);

    try {
      const res = await useAPI("/v1/crm", params);

      context.setIsLoading(false);

      if (!res?.status === false) {
        snackHandler(res?.message ?? "Failed create!", "error");
        return;
      }

      callback?.(res);

      return res;
    } catch (error) {
      console.error("Error api - apis.create()", error);
      context.setIsLoading(false);
      return;
    }
  };

  const update = async (
    params: { [x: string]: any },
    callback?: (res: any) => any
  ) => {
    context.setIsLoading(true);

    try {
      const res = await useAPI(
        "/v1/crm/" + (context?.dialog?.row?.id ?? context.rowSelected?.id),
        params,
        { type: "put" }
      );

      context.setIsLoading(false);

      if (res?.status === false) {
        snackHandler(res?.message ?? "Failed update!", "error");
      }

      callback?.(res);

      return res;
    } catch (error) {
      console.error("Error api - apis.put()", error);
      context.setIsLoading(false);
      return;
    }
  };

  const download = async () => {
    try {
      const res = await useAPI(
        "/v2/crm" +
          convertToQueryUrl({
            isAdmission,
            page: context.page,
            export: true,
            filter: context.filters,
          }),
        {},
        { type: "get" }
      );

      if (res?.status == false) {
        snackHandler(res?.message || "Failed download!", "error");
        return;
      }

      useExportCSV(res, "Crm");
    } catch (error) {
      console.error("Error api - apis.download()", error);
      return;
    }
  };

  const getImgCalendars = () =>
    useQuery(
      ["crm-get-img-calendars"],
      async () => await useAPI("/v1/crm/get-img-calendars")
    );

  const getUtmItems = () =>
    useQuery(
      ["get-utm_source", "get-utm_campaign"],
      async () => await useAPI("/v1/crm/utm")
    );

  return {
    get,
    create,
    update,
    download,
    getUtmItems,
    getImgCalendars,
  };
};
