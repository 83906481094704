import { clone } from "@/components/useHelpers";
import {
  IExercise,
  defMultipleChoiceTrueFalseOption,
} from "./interfacesAndDefaults";
import { Box, Button, IconButton, TextField } from "@mui/material";
import {
  Add,
  Delete,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import InputTextSelect from "@/pages/Exercises/ExercisesV1/components/InputTextSelect";

export default ({
  theme,
  index,
  question,
  exercise,
  keyVersion,
  setExercise,
  indexQuestion,
}) => {
  return (
    <Box className="flex items-start p-1 hover:bg-slate-600/5 rounded">
      <Box className="w-full flex flex-col gap-4 p-3 pt-5">
        <Box className="flex items-center">
          <Box className="w-[2.9rem] text-2xl text-center">
            {index + 1}
          </Box>

          <Box className="flex-1">
          <InputTextSelect
            required
            multiline
            type="text"
            label="Question"
            value={question.question || ""}
            maxRows={4}
            setValue={(value: string) => {
              setExercise((ex: IExercise) => {
                const t = clone(ex);
                t.exercises[keyVersion][indexQuestion].question = value;
                return t;
              });
            }}
            className="w-full"
            placeholder="Ex: Question 1"
          />

          </Box>
        
        </Box>
        <Box className="ml-10">
        <TextField
          label="Title"
          multiline
          rows={1}
          variant="outlined"
          value={question.title || ""}
          onChange={(e) => {
            setExercise((ex: IExercise) => {
              const t = clone(ex);
              t.exercises[keyVersion][indexQuestion].title = e.target.value; 
              return t;
            });
          }}
          className="w-full pl-20"
          placeholder="Add a description..."
        />
        </Box>
        <TrueFalse
          theme={theme}
          question={question}
          exercise={exercise}
          keyVersion={keyVersion}
          setExercise={setExercise}
          indexQuestion={indexQuestion}
        />
      </Box>

    

      <IconButton
        title={"Delete question " + (indexQuestion + 1)}
        onClick={() => {
          setExercise((e: IExercise) => {
            const t = { ...e };
            t.exercises[keyVersion] = t.exercises[keyVersion].filter(
              (_: any, i: number) => i != indexQuestion
            );
            return t;
          });
        }}
        disabled={exercise.exercises[keyVersion].length <= 1}
      >
        <Delete />
      </IconButton>
    </Box>
  );
}

function TrueFalse({
  theme,
  question,
  exercise,
  keyVersion,
  setExercise,
  indexQuestion,
}) {
  return (
    <Box
      key={keyVersion}
      className="flex flex-col gap-2 !rounded !m-0 !border-none"
    >
      {question.options.map((option: any, iOption: number) => (
        <Box
          className="flex gap-1 items-start"
          key={keyVersion + indexQuestion + iOption}
        >
          <IconButton
            sx={{
              color:
                option.is_correct == true || option.is_correct == 1
                  ? theme?.palette?.primary?.main
                  : "#cfcfcf",
            }}
            title="Is correct"
            onClick={() => {
              setExercise((e: IExercise) => {
                const t = { ...e };
                t.exercises[keyVersion][indexQuestion].options[
                  iOption
                ].is_correct =
                  t.exercises[keyVersion][indexQuestion].options[iOption]
                    .is_correct == false ||
                  t.exercises[keyVersion][indexQuestion].options[iOption]
                    .is_correct == 0
                    ? true
                    : false;
                return t;
              });
            }}
          >
            {option.is_correct == true || option.is_correct == 1 ? (
              <CheckBox />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </IconButton>

          <InputTextSelect
            required
            type="text"
            value={option.option || ""}
            setValue={(value: string) => {
              setExercise((e: IExercise) => {
                const t = { ...e };
                t.exercises[keyVersion][indexQuestion].options[iOption].option =
                  value;
                return t;
              });
            }}
            className="w-full"
          />

          <IconButton
            title="Delete option"
            onClick={() => {
              setExercise((e: IExercise) => {
                const t = { ...e };
                const tt = t.exercises[keyVersion][
                  indexQuestion
                ].options.filter((_: any, i: number) => i != iOption);
                t.exercises[keyVersion][indexQuestion].options = tt;
                return t;
              });
            }}
            disabled={
              exercise.exercises[keyVersion][indexQuestion].options.length <= 1
            }
          >
            <Delete />
          </IconButton>
        </Box>
      ))}

      <Box className="w-full flex">
        <Button
          title="Add new option"
          variant="outlined"
          onClick={() => {
            setExercise((e: IExercise) => {
              const t = { ...e };
              t.exercises[keyVersion][indexQuestion].options.push(
                clone(defMultipleChoiceTrueFalseOption)
              );
              return t;
            });
          }}
          className="flex gap-4 !py-1"
        >
          <Add /> <span>NEW option</span>
        </Button>
      </Box>
    </Box>
  );
}
