import { Box, Button, Popover, styled } from "@mui/material";
import { useCallback, useState } from "react";

interface INvertHoriz {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right";
}

type IDropdown = {
  text: any;
  icon?: any;
  title?: string;
  active?: boolean;
  content?: any;
  variant?: "text" | "outlined" | "contained";
  onClick?: Function;
  children?: any;
  disabled?: boolean;
  className?: string;
  buttonProps?: object;
  anchorOrigin?: INvertHoriz;
  hideBgActive?: boolean;
  iconComponent?: any;
  classNameText?: string;
  transformOrigin?: INvertHoriz;
  [x: string]: any;
};

const Dropdown = ({
  icon,
  text,
  title,
  active,
  onClick,
  variant = "outlined",
  content,
  children,
  disabled = false,
  className,
  buttonProps,
  hideBgActive,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
  classNameText,
  iconComponent,
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  ...rest
}: IDropdown) => {
  const [anchorElFilter, setAnchorElFilter] =
    useState<HTMLButtonElement | null>(null);

  const openFilter = Boolean(anchorElFilter);
  const idFilter = openFilter ? "dropdown-popover" : undefined;

  const handleClickFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElFilter(event.currentTarget);
    },
    []
  );

  const handleOnClick = (e: any) => {
    handleClickFilter(e);
    onClick?.();
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  return (
    <>
      <Button
        sx={
          openFilter || active
            ? {
                ...(hideBgActive
                  ? {}
                  : { background: (theme) => theme.palette.primary.main }),
                color: "white",
                "&:hover": {
                  ...(hideBgActive
                    ? {}
                    : { background: (theme) => theme.palette.primary.main }),
                  color: "white",
                },
                ".icon": {
                  filter: "brightness(0) invert(1)",
                },
              }
            : {}
        }
        title={title}
        variant={variant ?? "outlined"}
        onClick={handleOnClick}
        disabled={disabled}
        className={className}
        data-testid="open_user_action_menu"
        aria-describedby={idFilter}
        {...buttonProps}
      >
        {iconComponent
          ? iconComponent
          : icon && (
              <img
                src={icon}
                className="icon"
              />
            )}
        <Box className={"truncate " + classNameText}>{text}</Box>
      </Button>

      <PopoverStyles
        id={idFilter}
        open={openFilter}
        anchorEl={anchorElFilter}
        onClose={handleCloseFilter}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children ?? content}
      </PopoverStyles>
    </>
  );
};

const PopoverStyles = styled(Popover)(({ theme }) => ({
  "& img, .icon": {
    filter:
      theme.palette.mode === "light" ? "unset" : "brightness(0) invert(1)",
  },
}));

export default Dropdown;
