import useAPI from "@/api/useAPI";
import useCrmApi, { NEW_STATUS_FOR_CALL_CENTER } from "@/api/useCrmApi";
import useProductApi from "@/api/useProductApi";
import callIcon from "@/assets/icons/call.svg";
import commentIcon from "@/assets/icons/comments.svg";
import deleteIcon from "@/assets/icons/delete.svg";
import discountIcon from "@/assets/icons/discount.svg";
import editIcon from "@/assets/icons/edit.svg";
import eventIcon from "@/assets/icons/event.svg";
import mailIcon from "@/assets/icons/mail.svg";
import meetingDoneIcon from "@/assets/icons/meeting-done.svg";
import noShowIcon from "@/assets/icons/no-show.svg";
import rescheduledIcon from "@/assets/icons/rescheduled.svg";
import toBeCallIcon from "@/assets/icons/to-be-call.svg";
import videoIcon from "@/assets/icons/video.svg";
import viewIcon from "@/assets/icons/view.svg";
import {
  ForwardIcon,
  HardwareRoundedIcon,
  InfoIcon,
  MessageRoundedIcon,
  PriceCheckIcon,
  SocialDistanceIcon,
  TimerRoundedIcon,
} from "@/components";
import CustomPagination from "@/components/_Common/CustomPagination";
import Dropdown from "@/components/_Common/Dropdown";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import InfoCrmDialog from "@/components/Crm/InfoCrmDialog";
import ViewCrmDialog from "@/components/Crm/ViewCrmDialog";
import useAuth from "@/hooks/useAuth";
import { IDialogType } from "@/pages/Crm/CRM";
import { INAnchorsEl } from "@/pages/Upselling/v1/Crm/interfacesAndDefaults";
import { getTableStyling } from "@/providers/Colors";
import {
  ContactSupport,
  Mail,
  Signpost,
  WavingHand,
} from "@mui/icons-material";
import DialpadIcon from "@mui/icons-material/Dialpad";
import DoneIcon from "@mui/icons-material/Done";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LinkIcon from "@mui/icons-material/Link";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoveUpOutlinedIcon from "@mui/icons-material/MoveUpOutlined";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Select,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  ChangeEvent,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import EduBadge from "../_Common/EduBadge";
import DialpadCallModal from "../Dialpad/DialpadSingleCallV2/DialpadSingleCall";
import { IDialpadCallModal } from "../Dialpad/interface";
import FlagRender from "../FlagRender";
import MyTooltip from "../MyTooltip";
import useGenerateTemplateCrm, {
  convertRowCrm,
} from "../useGenerateTemplateCrm";
import {
  convert,
  filterObject,
  fUpper,
  fUpperWords,
  jP,
  objectInvertKeyValue,
} from "../useHelpers";
import AddInterestedEventDialog, {
  IAddInterestedEventDialog as IEventDialog,
} from "./AddInterestedEventDialog";
import ChooseIniPayment from "./ChooseIniPayment";
import CommentDialog, {
  IForwardRef as ICommentDialog,
  sortDescNotesByCreatedAt,
} from "./CommentDialog";
import DialogNewStatusToBeConverted, {
  IRef as IDialogNewStatusToBeConvertedRef,
} from "./DialogNewStatusToBeConverted";
import MailDialog, {
  getPaymentLink,
  IRef as IRefMailDialog,
} from "./MailDialog";
import MeetingComponent, {
  IFormMeetingDone,
  INUserMeeting,
} from "./MeetingComponent";
import ProofRejection from "./ProofRejection";

dayjs.extend(utc);
dayjs.extend(timezone);

interface INViewCrmDialog {
  open?: boolean;
  data?: any;
}

type CurrentUserType = {
  id?: number;
  utm_source?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  status?: string;
  owned_by?: string;
  duplicate?: boolean;
  created_at?: string | Date;
  meeting_calendar?: string;
};

const defaultFormMeetingDone: IFormMeetingDone = {
  pacchetto: null,
  motivation: null,
  certification: null,
  english_lvl: null,
  payment_link: null,
  notes: null,
};

interface IProps {
  handleOpenDialogType: (id: number, type: IDialogType) => void;
  handleOpenCalendarsEvent: (thisUser: any, status?: "Da richiamare") => void;
  [x: string]: any;
}

const IndexCrmTable = ({
  id,
  page,
  users,
  sales,
  setPage,
  perPage,
  filters,
  isLoading,
  calendars,
  sortModel,
  pagination,
  handlePopover,
  handleOpenMail,
  invalidateQuery,
  handleUpdateRow,
  getReminderLink,
  getReminderLink2,
  getReminderLink3,
  getReminderLink4,
  handleOpenDialog,
  sendDiscountCode,
  onSortModelChange,
  handleOpenCrmCall,
  handleOpenDialogType,
  handlePopoverSelectMeet,
  handleOpenCalendarsEvent,
  handlePopoverConvertStatus,
  handleSendTemplatesWithWati,
  handleAlsoAddOnOtherVersion,
  handleSendEmailIncorectNumber,
  getReminderLinkAfterRescheduled,
}: IProps) => {
  const theme = useTheme();

  const { data } = useProductApi().callGetProducts();

  const eventRef = createRef<IEventDialog>();
  const modalRef = createRef<IDialpadCallModal>();
  const commentRef = createRef<ICommentDialog>();
  const refMailDialog = createRef<IRefMailDialog>();

  const dialogNewStatusToBeConvertedRef =
    createRef<IDialogNewStatusToBeConvertedRef>();

  const { userAdmin, copyToClipboard, isAdmission, activity, snackHandler } =
    useAuth();

  const {
    arrayOptions,
    callUpdateCrm,
    arrayLabelOptions,
    callMoveCrmToUpSelling,
  } = useCrmApi();

  const { data: imgCalendars, isLoading: isLoadingImgCalendars } = useQuery(
    ["crm-get-img-calendars"],
    () => useAPI("/v1/crm/get-img-calendars")
  );

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowSelected, setRowSelected] = useState<any>({});
  const [openMeeting, setOpenMeeting] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<CurrentUserType>({});
  const [viewCrmDialog, setViewCrmDialog] = useState<INViewCrmDialog>({});
  const [infoCrmDialog, setInfoCrmDialog] = useState<INViewCrmDialog>({});
  const [formMeetingDone, setFormMeetingDone] = useState<IFormMeetingDone>(
    defaultFormMeetingDone
  );

  const [anchorsEl, setAnchorsEl] = useState<INAnchorsEl>();

  const discountCodes = data?.discount_codes || [];

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [
    openConfirmMoveToUpSellingDialog,
    setOpenConfirmMoveToUpSellingDialog,
  ] = useState(false);

  const handleSetFormMeetingDone = useCallback(
    (key: string, value: string) =>
      setFormMeetingDone((p: IFormMeetingDone) => ({ ...p, [key]: value })),
    []
  );

  //Dialpad
  const [targetCaller, setTargetCaller] = useState({});

  const handleDialpadCall = (row: any) => {
    setTargetCaller(row);
    if (modalRef.current) {
      modalRef.current.openModal();
    }
  };

  const getTextWh = (user: any) => {
    if (user.status == "Whatsapp text 1") {
      return `Ciao ${user.first_name} come stai? Sono ${userAdmin?.user.first_name} da Edusogno. Ho visto che non hai fissato un incontro gratuito con me per capire come possiamo aiutarti a migliorare il tuo Inglese in poco tempo. Posso segnarti come cliente disinteressato e cancellare il tuo nome dal nostro database?`;
    } else if (calendars?.includes(user.status)) {
      return `Ciao ${user.first_name}, sono ${userAdmin?.user.first_name}. Volevo spiegarti come funziona il percorso C1 di Inglese in 3 mesi di Edusogno, vorresti qualche info a riguardo o posso segnarti come utente disinteressato?`;
    }
    return `Ciao ${user.first_name}`;
  };

  const formatUser: INUserMeeting = useMemo(() => {
    const data = {
      id: rowSelected.id,
      first_name: rowSelected.first_name,
      lang: rowSelected.lang,
      email: rowSelected.email,
      token: rowSelected.token,
      phone: rowSelected.phone,
      status: rowSelected.status,
      last_name: rowSelected.last_name,
      updates:
        typeof rowSelected.updates === "string"
          ? JSON.parse(rowSelected.updates)
          : rowSelected.updates,
      meetings:
        typeof rowSelected.meeting === "string"
          ? JSON.parse(rowSelected.meeting)
          : rowSelected.meeting,
      tutoring: rowSelected.tutoring,
    };
    return data;
  }, [rowSelected]);

  const handleUpdateRowCrmCall = useCallback(
    (item: any) => {
      const updates =
        typeof rowSelected.updates === "string"
          ? JSON.parse(rowSelected.updates)
          : rowSelected.updates;

      const newUpdate = {
        status: item.values.status,
        updates: JSON.stringify([
          ...(Array.isArray(updates) ? updates : []),
          {
            status: item.values.status,
            date: null,
            admin: userAdmin.user.first_name + " " + userAdmin.user.last_name,
          },
        ]),
      };

      callUpdateCrm.mutate({
        id: rowSelected.id,
        data: newUpdate,
        callback: () => setOpenMeeting(false),
      });
    },
    [rowSelected, userAdmin]
  );

  const handleUpdate = useCallback(
    (selectedEvent: any, eventId: number | string, newStatus: string) => {
      const newMeet = {
        ...selectedEvent,
        ...filterObject(formMeetingDone, [null]),
      };

      const meetings = formatUser?.meetings ?? { meet: [] };

      const i = meetings?.meet.findIndex((meet: any) => meet.id === eventId);

      meetings.meet[i] = newMeet;

      const status = [
        "Compra",
        "Comprerebbe",
        "Interessato",
        "Will buy",
        "Would buy",
        "Interested",
      ].includes(formMeetingDone.motivation || "")
        ? "Colloquio fatto"
        : "Rejected";

      const dataApi: any = {
        status: rowSelected.status !== "Convertito" ? status : "Convertito",
        meeting: JSON.stringify(meetings),
      };

      const {
        user: { first_name, last_name },
      } = userAdmin;

      const fullNameAdmin = first_name + " " + last_name;

      if (fullNameAdmin !== newMeet.calendar) {
        dataApi.meeting_calendar = fullNameAdmin;
        dataApi.owned_by = fullNameAdmin;
      }

      callUpdateCrm.mutate({ id: rowSelected.id, data: dataApi });
      setOpenMeeting(false);
    },
    [formMeetingDone, formatUser, rowSelected, userAdmin]
  );

  const updateMeetingDoneOther = useCallback(
    (dataApi: any) => {
      const payload = { mock_meeting: { ...dataApi } };
      callUpdateCrm.mutate({ id: rowSelected.id, data: payload });
      setOpenMeeting(false);
    },
    [formMeetingDone, formatUser, rowSelected, userAdmin]
  );

  const handleWhatsApp = (row: any) => {
    const link =
      "https://api.whatsapp.com/send?phone=" +
      row.phone +
      "&text=" +
      getTextWh(row);
    window.open(link, "_blank");
  };

  const handleSetAnchorEl = (row: any, params: INAnchorsEl): void => {
    setRowSelected(row);
    setAnchorsEl((p) => ({ ...p, open: true, ...params }));
  };

  const handleSubmitUserUpdate = ({ id, formUser }) =>
    callUpdateCrm.mutate({ id, data: formUser });

  const handleInterestedInTheFuture = (row: any) => eventRef.current?.open(row);

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => (
        <Box className="flex items-center">
          {Boolean(row?.tutoring && !isAdmission) && <span>A</span>}
          <FlagRender
            flag={
              row?.lang == "de"
                ? row?.phone?.includes("+41")
                  ? "ch"
                  : "de"
                : row?.lang
            }
          />
        </Box>
      ),
    },
    {
      field: "created_at",
      headerName: "Date & Time",
      width: 130,
      valueGetter: ({ row }) =>
        dayjs(row.created_at).tz("Europe/Rome").format("ddd, DD-M HH:mm"),
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 140,
      flex: 1,
      renderCell: ({ row }) => {
        const fullName = `${fUpper(row?.first_name || "")} ${fUpper(
          row?.last_name || ""
        )}`.trim();

        return (
          <Tooltip
            arrow
            title={<Box className="text-base">{fullName}</Box>}
            placement="bottom-start"
          >
            {row?.first_name?.duplicate ? (
              <Badge
                color="primary"
                badgeContent={
                  <PeopleOutlineRoundedIcon style={{ fontSize: 10 }} />
                }
              >
                <Box
                  color="primary"
                  className={
                    "truncate overflow-hidden max-w-[7rem] " +
                    (!row?.is_valid_name ? " text-[#ffcc00]" : "")
                  }
                >
                  {fullName}
                </Box>
              </Badge>
            ) : (
              <Box
                color="primary"
                className={
                  "truncate overflow-hidden" +
                  (!row?.is_valid_name ? " !text-[#ffcc00]" : "")
                }
              >
                {fullName}
              </Box>
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 145,
      renderCell: ({ row }) => (
        <Tooltip
          arrow
          title={<Box className="text-base">{row?.phone}</Box>}
          placement="bottom-start"
        >
          <Box
            className="truncate overflow-hidden"
            style={{
              color: Boolean(Number(row?.otp_verification_code_verified))
                ? "#28a745"
                : theme.palette.mode == "dark"
                ? "white"
                : "black",
              fontWeight: Boolean(Number(row?.otp_verification_code_verified))
                ? "bold"
                : "normal",
            }}
            onClick={() => copyToClipboard(row?.phone?.replace("+", ""))}
          >
            {row?.phone}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "utm_source",
      headerName: "UTM_Source",
      minWidth: 130,
      flex: 0.5,
      valueGetter: ({ row }) => row?.utm_source || "-",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 210,
      flex: 1,
      renderCell: ({ row }) => {
        const menuItems = useMemo(
          () =>
            [
              ...new Set([
                ...Object.values(arrayLabelOptions),
                ...(activity == "Free" || activity == "Call center"
                  ? NEW_STATUS_FOR_CALL_CENTER
                  : []),
                ...(filters?.status
                  ? [
                      filters?.status in arrayLabelOptions
                        ? arrayLabelOptions[filters?.status]
                        : filters?.status,
                    ]
                  : []),
              ]),
            ]
              .sort()
              .map((option, i) => {
                const invertKeyValue = objectInvertKeyValue(arrayLabelOptions);
                return (
                  <MenuItem
                    key={i}
                    color="primary"
                    value={
                      option in invertKeyValue ? invertKeyValue[option] : option
                    }
                  >
                    {option}
                  </MenuItem>
                );
              }),
          [arrayOptions]
        );

        return (
          <FormControl
            className="w-full"
            size="small"
          >
            <Select
              defaultValue={""}
              name="status"
              value={row?.status ?? ""}
              onChange={(event: object) => handleUpdateRow(event, row.id)}
            >
              {menuItems}
            </Select>
          </FormControl>
        );
      },
    },
    ...(Boolean(
      rows?.find(
        (row: any) =>
          row?.hasReschedule || row?.last_product_from_last_duplicate
      )
    )
      ? [
          {
            field: "hasReschedule",
            headerName: "",
            width: 15,
            hideable: false,
            sortable: false,
            editable: false,
            filterable: false,
            disableExport: true,
            renderCell: ({ row }) => {
              const hasReschedule = Boolean(row?.hasReschedule);

              let productLPFLD: any = null;
              if (row?.last_product_from_last_duplicate) {
                productLPFLD = jP(row?.last_product_from_last_duplicate);
              }

              const hasLPFLD =
                productLPFLD &&
                [
                  "Nuovo",
                  "Rischedulare",
                  "Da convertire",
                  "Colloquio fissato",
                ].includes(row?.status);

              return (
                <Box className="flex flex-col gap-1 font-bold">
                  {hasReschedule && (
                    <Avatar
                      sx={{
                        width: 30,
                        height: hasLPFLD ? 16 : 30,
                        bgcolor: theme.palette.primary.main,
                      }}
                      title="Info meetings"
                      variant="rounded"
                      onClick={() =>
                        setInfoCrmDialog({ open: true, data: row })
                      }
                      className="cursor-pointer"
                    >
                      R
                    </Avatar>
                  )}

                  {hasLPFLD && (
                    <MyTooltip
                      title={
                        <Box>
                          {productLPFLD?.isHidden &&
                            !productLPFLD?.isDeleted && <Box>Is hidden</Box>}
                          {productLPFLD?.isDeleted && <Box>Is deleted</Box>}
                          <Box>Price: {productLPFLD?.price}</Box>
                          <Box>Product: {productLPFLD?.product_tag}</Box>
                        </Box>
                      }
                    >
                      <Avatar
                        sx={{
                          width: 30,
                          height: hasReschedule ? 16 : 30,
                          bgcolor: "#e75454",
                        }}
                        variant="rounded"
                      >
                        A
                      </Avatar>
                    </MyTooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : []),
    {
      field: "meeting_date",
      headerName: "Meeting date",
      width: 130,
      renderCell: ({ row }) => {
        if (!row?.meeting) return "-";

        const meeting = convert(row?.meeting);

        const meet = (meeting?.meet ?? [])?.at(-1)?.start;
        const meetToBeCalled = (meeting?.meetToBeCalled ?? [])?.at(-1)?.start;
        let date: string | null = null;
        if (meetToBeCalled) {
          date =
            meet && dayjs(meet).isBefore(dayjs(meetToBeCalled))
              ? meetToBeCalled
              : meet || meetToBeCalled;
        } else if (meet) {
          date = meet;
        }

        if (!date) return "-";

        return date
          ? dayjs(date).tz("Europe/Rome").format("ddd, DD-M HH:mm")
          : dayjs(row?.meeting_date)
              .tz("Europe/Rome")
              .format("ddd, DD-M HH:mm");
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      minWidth: 150,
      flex: 0.6,
      editable: false,
      sortable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        let notes: Array<any> = row?.notes
          ? typeof row?.notes == "string"
            ? JSON.parse(row?.notes)
            : row?.notes
          : [];

        const meetings = row?.meeting
          ? typeof row?.meeting == "string"
            ? JSON.parse(row?.meeting)
            : row?.meeting
          : {};

        const meets =
          meetings?.meet
            ?.filter((e: any) => Boolean(e?.notes))
            ?.map((e: any) => ({
              admin: {
                name: e?.interviewer,
                lname: "",
                email: "",
              },
              created: dayjs(e?.start)
                .format("DD-MM % HH:mm")
                .replace("%", "at"),
              content: e?.notes,
              created_at: e?.start,
              isFromMeeting: "from meeting",
            })) ?? [];

        // ORDER DESC
        notes = sortDescNotesByCreatedAt([...notes, ...meets]);

        const firstNote = notes?.[0]?.content;

        return (
          <MyTooltip title={firstNote ?? "empty"}>
            <Button
              className="!p-1 !min-w-0 !m-0 !w-full !flex !justify-start"
              onClick={() => commentRef?.current?.open(row)}
            >
              <Box className="w-full truncate text-left">
                {firstNote ? (
                  firstNote
                ) : (
                  <img
                    src={commentIcon}
                    className="icon"
                  />
                )}
              </Box>
            </Button>
          </MyTooltip>
        );
      },
    },
    {
      field: "owned_by",
      headerName: "Owned by",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const calendar = (row?.owned_by || "").trim();

        if (!calendar) return "NA";

        const name = fUpperWords(calendar);

        return (
          <Tooltip
            placement="bottom-start"
            arrow
            title={<Box>{name}</Box>}
            style={{ color: "white" }}
          >
            <Avatar
              style={{ height: "2.5rem", width: "2.5rem" }}
              src={imgCalendars?.[calendar]?.img}
              alt={name}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "button 1",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [
          "Offerta rifiutata",
          "No show not interested",
          "Assente al colloquio",
        ].includes(row.status) ? (
          <ProofRejection
            token={row.token}
            rejected={row?.rejection_proof}
          />
        ) : [
            "Rejected",
            "Convertito",
            "Da convertire",
            "Offerta rifiutata",
            "Interested in the future",
          ].includes(row.status) ? (
          <Button
            title="Info meetings"
            onClick={() => setInfoCrmDialog({ open: true, data: row })}
          >
            <InfoIcon fontSize="small" />
          </Button>
        ) : ["Colloquio fissato"].includes(row?.status) ? (
          <Button
            title="Update meeting"
            onClick={() => {
              setRowSelected(row);
              setOpenMeeting(true);
            }}
          >
            <img
              src={videoIcon}
              className="icon no-margin"
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button 2",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        row?.status == "Colloquio fissato" ? (
          <>
            {!row?.sent_welcome_message ? (
              <Button
                title="Sent welcome message"
                onClick={() =>
                  useGenerateTemplateCrm(
                    "reminder_4",
                    convertRowCrm(row),
                    userAdmin,
                    snackHandler,
                    () => handleOpenDialogType(row.id, "sent-welcome-message"),
                    () =>
                      getReminderLink4(
                        row.phone,
                        row?.first_name,
                        row.id,
                        row?.lang
                      )
                  )
                }
              >
                <HandshakeIcon fontSize="small" />
              </Button>
            ) : !row?.sent_reminders?.reminder1 ? (
              <Button
                title="Timer"
                onClick={() =>
                  useGenerateTemplateCrm(
                    "reminder_1",
                    convertRowCrm(row),
                    userAdmin,
                    snackHandler,
                    () => handleOpenDialogType(row.id, "reminder1"),
                    () =>
                      getReminderLink(
                        row.phone,
                        row?.first_name,
                        row.id,
                        row?.lang
                      )
                  )
                }
              >
                <TimerRoundedIcon fontSize="small" />
              </Button>
            ) : !row?.sent_reminders?.["reminder3.1"] ? (
              <Button
                title="Question"
                onClick={() =>
                  useGenerateTemplateCrm(
                    "reminder_3.1",
                    convertRowCrm(row),
                    userAdmin,
                    snackHandler,
                    () => handleOpenDialogType(row.id, "reminder3.1")
                  )
                }
              >
                <ContactSupport fontSize="small" />
              </Button>
            ) : row?.hasReschedule == 1 ? (
              <Button
                title="Reminder after rescheduled"
                onClick={() =>
                  useGenerateTemplateCrm(
                    "reminder_after_rescheduled",
                    convertRowCrm(row),
                    userAdmin,
                    snackHandler,
                    undefined,
                    () =>
                      getReminderLinkAfterRescheduled(
                        row.phone,
                        row?.first_name,
                        row.id,
                        row?.lang
                      )
                  )
                }
              >
                <DoneIcon
                  color="primary"
                  fontSize="small"
                />
              </Button>
            ) : (
              <DoneIcon
                color="primary"
                fontSize="small"
              />
            )}
          </>
        ) : row?.status == "Colloquio fatto" ? (
          <ChooseIniPayment
            displayButton
            onClick={(iniType) =>
              refMailDialog.current?.open({ ...row, iniType })
            }
          />
        ) : row?.status == "Da convertire" ? (
          !row?.sent_reminders?.reminder3 ? (
            <Button
              title="Reminder 3"
              onClick={() =>
                useGenerateTemplateCrm(
                  "reminder_3",
                  convertRowCrm(row),
                  userAdmin,
                  snackHandler,
                  () => handleOpenDialogType(row.id, "reminder3"),
                  () =>
                    getReminderLink3(
                      row.phone,
                      row?.first_name,
                      row?.lang,
                      row?.id
                    )
                )
              }
            >
              <HardwareRoundedIcon fontSize="small" />
            </Button>
          ) : (
            <DoneIcon
              color="primary"
              fontSize="small"
            />
          )
        ) : row?.status == "Numero non valido" ? (
          <Button
            title="Send email incorect number"
            onClick={() => handleSendEmailIncorectNumber(row)}
          >
            <Mail fontSize="small" />
          </Button>
        ) : row?.status == "Convertito" && !row?.sent_reminders?.reminder2 ? (
          <Button
            title="Reminder 2"
            onClick={() =>
              getReminderLink2(row.phone, row?.first_name, row, row?.lang)
            }
          >
            <SocialDistanceIcon fontSize="small" />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "button 3",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        ["Nuovo", "Da richiamare"].includes(
          row?.status?.replace(/\d+/g, "").trim()
        ) && ["Crina", "Oriana"].includes(userAdmin.user.first_name) ? (
          <Button onClick={() => handleSendTemplatesWithWati(row)}>
            <WavingHand fontSize="small" />
          </Button>
        ) : (
          <Button onClick={() => handleWhatsApp(row)}>
            <i
              style={{ fontSize: 16 }}
              className="fa-brands fa-whatsapp"
            />
          </Button>
        ),
    },
    {
      field: "dialpad",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button onClick={() => handleDialpadCall(row)}>
          <DialpadIcon fontSize="small"></DialpadIcon>
        </Button>
      ),
    },
    {
      field: "button 4",
      headerName: "",
      align: "center",
      maxWidth: 35,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) =>
        [
          "Nuovo",
          "Non risposto",
          "Rischedulare",
          "Da richiamare",
          "Whatsapp text",
          "Colloquio fissato",
          "Messaggio WhatsApp",
          "Waiting for feedback",
          "Assente al colloquio",
          "Assente senza avvisare",
          ...NEW_STATUS_FOR_CALL_CENTER.map((e) =>
            e?.replace(/\d+/g, "").trim()
          ),
        ].includes(row?.status?.replace(/\d+/g, "").trim()) ? (
          <Button onClick={() => handleOpenCalendarsEvent(row)}>
            <img
              src={callIcon}
              className="icon no-margin"
            />
          </Button>
        ) : ["Da convertire"].includes(
            row?.status?.replace(/\d+/g, "").trim()
          ) ? (
          <Button
            title={
              row?.info?.offer_rejected
                ? "Offer rejected: " + row?.info?.offer_rejected
                : "Offer rejected"
            }
            onClick={() => dialogNewStatusToBeConvertedRef?.current?.open(row)}
          >
            <img
              src={callIcon}
              className={cn(
                "icon no-margin",
                Boolean(row?.info?.offer_rejected)
                  ? "rotate-[165deg]"
                  : "rotate-[135deg]"
              )}
            />
          </Button>
        ) : (
          "-"
        ),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      width: 75,
      renderCell: ({ row }) => {
        let colorMS = "grey";
        switch (row?.meeting_status) {
          case "ci sarà":
            colorMS = "#58ff2f";
            break;
          case "non risponde":
            colorMS = "#FFD700";
            break;
          case "other":
            colorMS = "#f22d7b";
            break;
          default:
            colorMS = "grey";
            break;
        }

        let colorCS = "grey";
        switch (row?.convert_status) {
          case "soon buying":
            colorCS = "#58ff2f";
            break;
          case "no reply":
            colorCS = "#FFD700";
            break;
          case "other":
            colorCS = "#f22d7b";
            break;
          default:
            colorCS = "grey";
            break;
        }

        const notes: number =
          (row?.notes
            ? typeof row?.notes == "string"
              ? JSON.parse(row?.notes)
              : row?.notes
            : []
          )?.length ?? 0;

        const meetings: number =
          (row?.meeting
            ? typeof row?.meeting == "string"
              ? JSON.parse(row?.meeting)
              : row?.meeting
            : {}
          )?.meet?.filter((m: any) => m?.notes)?.length ?? 0;

        const countComments = meetings + notes;

        return (
          <EduBadge
            badgeContent={
              row?.status == "Colloquio fissato" ? (
                <Box
                  sx={{
                    p: "0.4rem",
                    bgcolor: colorMS,
                    boxShadow: colorMS !== "grey" ? `0 0 5px ${colorMS}` : "",
                  }}
                  className="rounded-full transitions-colors duration-200"
                />
              ) : row.status == "Da convertire" ? (
                <ForwardIcon style={{ color: colorCS }} />
              ) : null
            }
          >
            <Dropdown
              text={<MoreVertIcon />}
              content={
                <MenuList>
                  <MenuItem
                    color="primary"
                    onClick={() => copyToClipboard(row.email)}
                  >
                    <ListItemIcon>
                      <img
                        src={mailIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Copy email</ListItemText>
                  </MenuItem>

                  <MenuItem
                    title="Info"
                    color="primary"
                    onClick={() => setViewCrmDialog({ open: true, data: row })}
                  >
                    <ListItemIcon>
                      <img
                        src={viewIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>View</ListItemText>
                  </MenuItem>

                  {Boolean(row?.meeting) && (
                    <MenuItem
                      title="Info meetings"
                      color="primary"
                      onClick={() =>
                        setInfoCrmDialog({ open: true, data: row })
                      }
                    >
                      <ListItemIcon>
                        <InfoIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Info meetings</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem onClick={() => handleWhatsApp(row)}>
                    <ListItemIcon>
                      <i
                        className="fa-brands fa-whatsapp"
                        style={{ fontSize: 16 }}
                      />
                    </ListItemIcon>
                    <ListItemText>Whatsapp</ListItemText>
                  </MenuItem>

                  {["Nuovo", "Da richiamare"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem onClick={() => handleSendTemplatesWithWati(row)}>
                      <ListItemIcon>
                        <WavingHand fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Welcome message</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Da richiamare",
                    "Assente al colloquio",
                    "Assente senza avvisare",
                    "Non risposto",
                    "Rischedulare",
                    ...NEW_STATUS_FOR_CALL_CENTER,
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <MenuItem onClick={() => handleOpenCrmCall(row)}>
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Create meeting</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Da richiamare",
                    "Whatsapp text 1",
                    "Whatsapp text 2",
                    "Colloquio fissato",
                    "Messaggio WhatsApp",
                    ...NEW_STATUS_FOR_CALL_CENTER,
                  ].includes(row?.status) && (
                    <MenuItem
                      onClick={() =>
                        handleOpenCalendarsEvent(row, "Da richiamare")
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>To be called</ListItemText>
                    </MenuItem>
                  )}

                  {["Colloquio fissato"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <Box>
                      <MenuItem
                        onClick={() => {
                          setRowSelected(row);
                          setOpenMeeting(true);
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={videoIcon}
                            className="icon no-margin"
                          />
                        </ListItemIcon>
                        <ListItemText>Update meeting</ListItemText>
                      </MenuItem>

                      {!row?.sent_reminders?.reminder1 && (
                        <MenuItem
                          title="Timer"
                          onClick={() => {
                            useGenerateTemplateCrm(
                              "reminder_1",
                              convertRowCrm(row),
                              userAdmin,
                              snackHandler,
                              () => handleOpenDialogType(row.id, "reminder1"),
                              () =>
                                getReminderLink(
                                  row.phone,
                                  row?.first_name,
                                  row.id,
                                  row.lang
                                )
                            );
                          }}
                        >
                          <ListItemIcon>
                            <TimerRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Reminder</ListItemText>
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={(e) =>
                          handlePopoverSelectMeet(e, row.id, row.meeting_status)
                        }
                      >
                        <ListItemIcon style={{ color: colorMS }}>
                          <Box
                            className="rounded-full transitions-colors duration-200"
                            sx={{
                              p: "0.55rem",
                              boxShadow:
                                colorMS !== "grey" ? `0 0 5px ${colorMS}` : "",
                              bgcolor: colorMS,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>Meeting Status</ListItemText>
                      </MenuItem>
                    </Box>
                  )}

                  {["Colloquio fatto"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <ChooseIniPayment
                      onClick={(iniType) =>
                        refMailDialog.current?.open({ ...row, iniType })
                      }
                    />
                  )}

                  {["Convertito"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) &&
                    Boolean(!row?.sent_reminders?.reminder2) && (
                      <MenuItem
                        title="Reminder 2"
                        onClick={() =>
                          getReminderLink2(
                            row.phone,
                            row?.first_name,
                            row,
                            row?.lang
                          )
                        }
                      >
                        <ListItemIcon>
                          <SocialDistanceIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reminder</ListItemText>
                      </MenuItem>
                    )}

                  {["Da convertire"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <Box>
                      {!row?.sent_reminders?.reminder3 && (
                        <MenuItem
                          title="Reminder 3"
                          onClick={() =>
                            useGenerateTemplateCrm(
                              "reminder_3",
                              convertRowCrm(row),
                              userAdmin,
                              snackHandler,
                              () => handleOpenDialogType(row.id, "reminder3"),
                              () =>
                                getReminderLink3(
                                  row.phone,
                                  row?.first_name,
                                  row?.lang,
                                  row?.id
                                )
                            )
                          }
                        >
                          <ListItemIcon>
                            <HardwareRoundedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Reminder</ListItemText>
                        </MenuItem>
                      )}

                      <MenuItem
                        aria-describedby={id}
                        onClick={(e) =>
                          handlePopover(e, row.id, row.comments, "comment")
                        }
                      >
                        <ListItemIcon>
                          {row.comments ? (
                            <Badge
                              variant="dot"
                              color="warning"
                              sx={{ p: 0.2 }}
                            >
                              <MessageRoundedIcon fontSize="small" />
                            </Badge>
                          ) : (
                            <MessageRoundedIcon fontSize="small" />
                          )}
                        </ListItemIcon>
                        <ListItemText>Comment</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => copyToClipboard(getPaymentLink(row))}
                      >
                        <ListItemIcon>
                          <LinkIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Landing checkout link</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => handlePopoverConvertStatus(e, row.id)}
                      >
                        <ListItemIcon style={{ color: colorCS }}>
                          <ForwardIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Convertion status</ListItemText>
                      </MenuItem>

                      {/* <MenuItem
                        onClick={() =>
                          callUpdateCrm.mutate({
                            id: row.id,
                            data: { time_link_gen: "now" },
                          })
                        }
                      >
                        <ListItemIcon>
                          <RefreshRoundedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Restart timer</ListItemText>
                      </MenuItem> */}

                      <MenuItem
                        onClick={() => handleOpenDialog(row, "confirm-payment")}
                      >
                        <ListItemIcon>
                          <PriceCheckIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Confirm payment</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          setRowSelected(row);
                          handleSetAnchorEl(row, {
                            element: e.currentTarget,
                            template: "email",
                          });
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={discountIcon}
                            className="icon no-margin"
                          />
                        </ListItemIcon>
                        <ListItemText>Activate discount code</ListItemText>
                      </MenuItem>
                    </Box>
                  )}

                  {["Da convertire", "Interested in the future"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem onClick={() => handleInterestedInTheFuture(row)}>
                      <ListItemIcon>
                        <img
                          src={callIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Interested in the future</ListItemText>
                    </MenuItem>
                  )}

                  {["Rejected"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem onClick={() => handleOpenMail(row)}>
                      <ListItemIcon>
                        <ThumbDownRoundedIcon sx={{ fontSize: "1.15rem" }} />
                      </ListItemIcon>
                      <ListItemText>Send mail</ListItemText>
                    </MenuItem>
                  )}

                  {[
                    "Nuovo",
                    "Rischedulare",
                    "Da richiamare",
                    "Colloquio fissato",
                    "Waiting for feedback",
                    "Assente al colloquio",
                    "Assente senza avvisare",
                    "Whatsapp text",
                    "Messaggio WhatsApp",
                  ].includes(row?.status?.replace(/\d+/g, "").trim()) && (
                    <MenuItem
                      title="Copy link reschedule user from platform"
                      onClick={(e: any) =>
                        copyToClipboard(
                          `https://edusogno.com/form/reschedule/edusogno-inglese/${row.token}`
                        )
                      }
                    >
                      <ListItemIcon>
                        <img
                          src={eventIcon}
                          className="icon no-margin"
                        />
                      </ListItemIcon>
                      <ListItemText>Reschedule link</ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem
                    title="Comments"
                    color="primary"
                    onClick={() => commentRef.current?.open(row)}
                  >
                    <ListItemIcon>
                      <img
                        src={commentIcon}
                        className="icon"
                      />
                    </ListItemIcon>
                    <ListItemText>
                      Comments
                      {Boolean(countComments) && (
                        <Badge
                          sx={{ mx: 2 }}
                          color="warning"
                          badgeContent={countComments}
                        />
                      )}
                    </ListItemText>
                  </MenuItem>

                  {Boolean(row.user) && (
                    <MenuItem
                      title="Info"
                      color="primary"
                      onClick={() => {
                        setCurrentUser(row);
                        setOpenConfirmMoveToUpSellingDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <MoveUpOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Move to UpSelling</ListItemText>
                    </MenuItem>
                  )}

                  {!["Convertito"].includes(
                    row?.status?.replace(/\d+/g, "").trim()
                  ) && (
                    <MenuItem
                      onClick={() => handleOpenDialog(row, "move-to-admission")}
                    >
                      <ListItemIcon>
                        <LowPriorityIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        Move to{" "}
                        {Number(row?.tutoring) ? "English" : "Admission"}
                      </ListItemText>
                    </MenuItem>
                  )}

                  <MenuItem onClick={() => handleAlsoAddOnOtherVersion(row)}>
                    <ListItemIcon>
                      <Signpost />
                    </ListItemIcon>
                    <ListItemText>
                      Also add on{" "}
                      {Number(row?.tutoring) ? "English" : "Admission"}
                    </ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleOpenDialog(row, "user-information")}
                  >
                    <ListItemIcon>
                      <img
                        src={editIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setCurrentUser(row);
                      setOpenConfirmDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={deleteIcon}
                        className="icon no-margin"
                      />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </EduBadge>
        );
      },
    },
  ];

  useEffect(() => {
    if (!users) return;
    setRows(users);
  }, [users, isLoadingImgCalendars, imgCalendars, page, pagination]);

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortingMode="server"
        componentsProps={{ pagination: { page, setPage, pagination } }}
        getRowClassName={({ row }) =>
          row?.tutoring && !isAdmission
            ? theme.palette.mode == "dark"
              ? "bg-[#e0f7fa]/[.15]"
              : "bg-[#e0f7fa]"
            : ""
        }
        sortModel={sortModel}
        components={{
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              hideTotal
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
      />

      <MailDialog ref={refMailDialog} />

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title={
          <>
            <img src={deleteIcon} /> DELETE CONTACT
          </>
        }
        onConfirm={() => {
          if (!currentUser.id) return;
          callUpdateCrm.mutate({
            id: currentUser.id,
            data: { deleted_at: "now" },
          });
          setOpenConfirmDialog(false);
        }}
      >
        Are you sure to remove it?
      </ConfirmationDialog>

      <ConfirmationDialog
        open={openConfirmMoveToUpSellingDialog}
        onClose={() => setOpenConfirmMoveToUpSellingDialog(false)}
        title={
          <>
            <MoveUpOutlinedIcon
              fontSize="small"
              className="mr-2"
            />{" "}
            Move to Upselling
          </>
        }
        onConfirm={() => {
          if (!currentUser.id) return;
          callMoveCrmToUpSelling.mutate({
            id: currentUser.id,
          });
          setOpenConfirmMoveToUpSellingDialog(false);
        }}
      >
        Are you sure to move it to upselling?
      </ConfirmationDialog>

      <ViewCrmDialog
        user={viewCrmDialog?.data}
        open={Boolean(viewCrmDialog?.open)}
        onClose={() => setViewCrmDialog((p: any) => ({ ...p, open: false }))}
      />

      <InfoCrmDialog
        user={infoCrmDialog?.data}
        open={Boolean(infoCrmDialog?.open)}
        onClose={() => setInfoCrmDialog((p: any) => ({ ...p, open: false }))}
      />

      {openMeeting && (
        <MeetingComponent
          useDataGetToogle
          open={openMeeting}
          onClose={() => (
            setOpenMeeting(false),
            setFormMeetingDone(defaultFormMeetingDone),
            setRowSelected({})
          )}
          data={{
            user: formatUser,
            userAdmin: userAdmin,
            buttons: {
              noShow: {
                icon: noShowIcon,
                name: "No show",
                status: "Assente al colloquio",
              },
              reschedule: {
                icon: rescheduledIcon,
                name: "Reschedule",
                status: "Colloquio fissato",
              },
              toBeCall: {
                icon: toBeCallIcon,
                name: "To be called",
                status: "Da richiamare",
              },
              meetingDone: {
                icon: meetingDoneIcon,
                name: "Meeting done",
                status: "Colloquio fatto",
                form: formMeetingDone,
                setForm: handleSetFormMeetingDone,
              },
            },
          }}
          functions={{
            onUpdateRow: handleUpdateRowCrmCall,
            onMeetingDone: handleUpdate,
            onMeetingDoneOther: updateMeetingDoneOther,
            onSendFormUser: handleSubmitUserUpdate,
          }}
        />
      )}

      <CommentDialog ref={commentRef} />
      <AddInterestedEventDialog
        ref={eventRef}
        dialogNewStatusToBeConvertedRef={dialogNewStatusToBeConvertedRef}
      />

      <DialpadCallModal
        ref={modalRef}
        row={targetCaller}
      />

      <Popover
        open={Boolean(anchorsEl?.open)}
        onClose={() => setAnchorsEl((p) => ({ ...p, open: false }))}
        anchorEl={anchorsEl?.element}
        anchorOrigin={{ vertical: "bottom", horizontal: 75 }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box className="bg-edu-800 flex flex-col gap-4 p-3 w-72">
          <TextField
            select
            fullWidth
            label="Discount code"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              sendDiscountCode(rowSelected?.id, e.target.value)
            }
          >
            <MenuItem value="">- Remove -</MenuItem>

            {discountCodes?.map((c: any, i: number) => (
              <MenuItem
                key={c.id + i}
                value={c?.code}
              >
                <Box className="w-full flex justify-between gap-2">
                  <Box>{c?.code}</Box>
                  <Box>
                    {c?.amount}
                    {c?.is_percentage ? "%" : "€"}
                  </Box>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Popover>

      <DialogNewStatusToBeConverted
        ref={dialogNewStatusToBeConvertedRef}
        invalidateQuery={invalidateQuery}
        onInterestedInTheFuture={handleInterestedInTheFuture}
      />
    </>
  );
};

export default IndexCrmTable;
