//@ts-nocheck
import { useState, useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const sleep = (delay = 0) => new Promise((res) => setTimeout(res, delay));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoCompleteMaterials = ({
  data,
  setValue,
  multiple,
  label,
  value,
  closeSelect,
  optionsLabel,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const loading = open && !options?.length;

  const handleChange = (_, newValue) => setValue(newValue);

  useEffect(() => {
    let active = true;
    if (!loading) return undefined;

    (async () => {
      // await sleep(1e3); // For demo purposes.
      if (active) setOptions([...data]);
    })();

    return () => (active = false);
  }, [loading]);

  useEffect(() => {
    if (!open) setOptions([]);
  }, [open]);

  return (
    <Autocomplete
      id={label}
      open={open}
      value={value}
      color="primary"
      onOpen={() => setOpen(true)}
      options={options}
      loading={loading}
      onClose={() => setOpen(false)}
      multiple={multiple}
      onChange={handleChange}
      disableCloseOnSelect={closeSelect}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) =>
        `${option.name} - ${option.lesson || "No lesson"}`
      }
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            (option.lesson &&
              option.lesson.toLowerCase().includes(inputValue.toLowerCase()))
        )
      }
      renderOption={(props, option, { selected }) => {
        const { name, lesson, language, category, english_level } = option;
        const formatArray = (arr) => (arr?.length ? `[${arr.join(", ")}]` : "");

        return (
          <li
            {...props}
            key={props.key + props["data-option-index"]}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${name} - ${lesson} - ${formatArray(language)} - ${formatArray(
              category
            )} - ${formatArray(english_level)}`.trim()}
          </li>
        );
      }}
      ChipProps={{ color: "primary", variant: "outlined" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label + " *"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="primary"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteMaterials;
